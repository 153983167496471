import { createRouter, createWebHistory } from 'vue-router';
import { GET_USER_TOKEN_EXP, GET_USER_ROLE } from '@/store/types';
import { validateToken } from '@/network/interceptors/validateToken';
import {
  // auth
  Login,
  ResetStructure,
  ResetPassword,
  NewPassword,

  // brokers/dashboard
  DashboardTemplate,
  DashboardContainer,
  GlobalStock,

  // brokers/movements/requests/incorporations/policy-holders
  IncorporationList,
  IndividualIncorporationForm,
  IndividualIncorporationDetail,
  MassiveIncorporationForm,
  MassiveIncorporationDetail,

  // brokers/movements/requests/incorporations/dependants
  DependantsIncorporationList,
  IndividualDependantsIncorporationForm,
  IndividualDependantsIncorporationDetail,

  // brokers/movements/requests/exclusions/policy-holders
  ExclusionList,
  IndividualExclusionForm,
  IndividualExclusionDetail,
  MassiveExclusionForm,
  MassiveExclusionDetail,

  // brokers/movements/requests/exclusions/dependants
  DependantsExclusionList,
  IndividualDependantsExclusionForm,
  IndividualDependantsExclusionDetail,

  // brokers/movements/requests/modifications
  ModificationList,
  IndividualModificationForm,
  IndividualModificationDetail,
  MassiveModificationForm,
  MassiveModificationDetail,

  // brokers/movements/requests/transfers
  TransferList,
  IndividualTransferForm,
  IndividualTransferDetail,

  // brokers/movements/cases
  LackDataList,
  LackDataDetail,
  RejectionList,
  RejectionDetail,
  OutOfTimeList,
  OutOfTimeDetail,

  // brokers/collection-and-billing/processes
  ProcessList,
  CollectionGroupForm,
  CollectionGroupDetail,
  CollectionDetail,

  // brokers/collection-and-billing/reprocesses
  InconsistenciesList,
  InconsistenciesDetail,
  PayrollModificationList,
  PayrollModificationDetail,
  InvoiceCancellationList,
  InvoiceCancellationDetail,

  // brokers/collection-and-billing/requests
  CreditNoteList,
  CreditNoteDetail,
  DebitNoteList,
  DebitNoteDetail,
  RetroactiveAdjustmentList,
  RetroactiveAdjustmentDetail,

  // brokers/customers/holdings
  // brokers/customers/subholdings
  // brokers/customers/companies
  CustomerList,
  CompanyDetail,
  GroupList,
  PolicyTabsContainer,
  MovementsPolicyList,
  CollectionPolicyConfigList,
  CollectionPolicyConfigForm,
  PolicyDetail,
  StockList,
  StockEmployeeDetail,
  NotificationList,
  ReceptionList,
  BrokerList,
  UserList,
  EmailList,

  // brokers/insurance-companies/
  InsuranceCompanyList,
  InsuranceCompanyDetail,

  // human-resources/employees
  EmployeeList,

  // error
  NotFoundError,
  MaintenanceError,

  // collaborators/forms/dependants-incorporation
  DependentsIncorporationForm,
  DependentsIncorporationFormError,
  DependentsIncorporationFormExpired,
  DependentsIncorporationFormStep1,
  DependentsIncorporationFormStep2,
  DependentsIncorporationFormSuccess,

  // collaborators/forms/template-incorporation
  TemplateIncorporationAuth,
  TemplateIncorporationForm,
  TemplateIncorporationFormStep1,
  TemplateIncorporationFormStep2,
  TemplateIncorporationFormStep3,
  TemplateIncorporationFormResume,
  TemplateIncorporationFormSuccess,

  // collaborators/forms/digital-incorporation
  DigitalIncorporationForm,
  DigitalIncorporationFormExpired,
  DigitalIncorporationFormPickUp,
  DigitalIncorporationFormInvalid,

  // collaborators/forms/lack-data
  LackDataForm,
  LackDataFormSuccess,

  // collaborators/forms/file-rejection
  FileRejectionForm,
  FileRejectionFormSuccess,
  FileRejectionFormError
} from '@/views';
import scrollToTopPage from '@/utils/scrollToTopScreen';
import store from '@/store';

const defaultTitle = 'bewell+ | Tus colaboradores en primer lugar';
const routes = [
  // * ERROR 404
  {
    path: '/:pathMatch(.*)*',
    component: NotFoundError,
    name: 'Missing',
    meta: {
      requires_auth: false
    }
  },
  {
    path: '/mantenimiento',
    component: MaintenanceError,
    name: 'Mantenimiento',
    meta: {
      requires_auth: false
    }
  },
  {
    path: '/formulario-digital-expirado/:token',
    component: DigitalIncorporationFormExpired,
    name: 'DigitalFormExpiredToken',
    meta: {
      requires_auth: false
    }
  },
  {
    path: '/formulario-digital-aun-vigente',
    component: DigitalIncorporationFormPickUp,
    name: 'DigitalFormTokenStillWorking',
    meta: {
      requires_auth: false
    }
  },
  {
    path: '/formulario-digital-token-invalido',
    component: DigitalIncorporationFormInvalid,
    name: 'DigitalFormInvalidToken',
    meta: {
      requires_auth: false
    }
  },
  {
    path: '/formulario-incorporacion-cargas/:token',
    component: DependentsIncorporationForm,
    name: 'DependentsIncorporationForm',
    meta: {
      requires_auth: false
    }
  },
  {
    path: '/formulario-incorporacion-cargas/:token/error',
    component: DependentsIncorporationFormError,
    name: 'DependentsIncorporationFormError',
    meta: {
      requires_auth: false
    }
  },
  {
    path: '/formulario-incorporacion-cargas/:token/expirado',
    component: DependentsIncorporationFormExpired,
    name: 'DependentsIncorporationFormExpired',
    meta: {
      requires_auth: false
    }
  },
  {
    path: '/formulario-incorporacion-cargas/:token/paso-1',
    component: DependentsIncorporationFormStep1,
    name: 'DependentsIncorporationFormStep1',
    meta: {
      requires_auth: false
    }
  },
  {
    path: '/formulario-incorporacion-cargas/:token/paso-2',
    component: DependentsIncorporationFormStep2,
    name: 'DependentsIncorporationFormStep2',
    meta: {
      requires_auth: false
    }
  },
  {
    path: '/formulario-incorporacion-cargas/:token/finalizado',
    component: DependentsIncorporationFormSuccess,
    name: 'DependentsIncorporationFormSuccess',
    meta: {
      requires_auth: false
    }
  },
  // * Rutas principales
  {
    path: '/',
    meta: { title: defaultTitle },
    name: 'PrincipalLayout',
    component: DashboardTemplate,
    children: [
      {
        path: '',
        name: 'Dashboard',
        component: DashboardContainer,
        meta: {
          requires_auth: true,
          desable_automatic_scrolling: true
        }
      },
      {
        path: '/globalstock',
        name: 'GlobalStock',
        component: GlobalStock,
        meta: {
          requires_auth: true,
          visible_for_role: ['admin', 'superadmin', 'rrhh']
        }
      },
      {
        path: '/empleados',
        name: 'Employees',
        component: EmployeeList,
        meta: {
          requires_auth: true,
          visible_for_role: ['rrhh']
        }
      },
      // * Altas
      {
        path: '/altas',
        name: 'Altas',
        component: IncorporationList,
        children: [
          {
            path: ':id/tipo/individual',
            name: 'AltasDetalle',
            component: IndividualIncorporationDetail,
            meta: {
              requires_auth: true,
              visible_for_role: ['admin', 'superadmin', 'rrhh']
            }
          },
          {
            path: ':id/tipo/masivo',
            name: 'AltaMasivaDetail',
            component: MassiveIncorporationDetail,
            meta: {
              requires_auth: true,
              visible_for_role: ['admin', 'superadmin', 'rrhh']
            }
          },
          {
            path: 'tipo/formulario-individual',
            name: 'AltaIndividual',
            component: IndividualIncorporationForm,
            meta: {
              requires_auth: true,
              visible_for_role: ['admin', 'superadmin']
            }
          },
          {
            path: 'tipo/formulario-masivo',
            name: 'AltaMasiva',
            component: MassiveIncorporationForm,
            meta: {
              requires_auth: true,
              visible_for_role: ['admin', 'superadmin']
            }
          }
        ],
        meta: {
          requires_auth: true,
          visible_for_role: ['admin', 'superadmin']
        }
      },
      {
        path: '/altas-cargas',
        name: 'AltasCargas',
        component: DependantsIncorporationList,
        children: [
          {
            path: ':id',
            name: 'AltaCargasDetalle',
            component: IndividualDependantsIncorporationDetail,
            meta: {
              requires_auth: true,
              visible_for_role: ['admin', 'superadmin', 'rrhh']
            }
          },
          {
            path: 'formulario-individual',
            name: 'AltaCargasIndividual',
            component: IndividualDependantsIncorporationForm,
            meta: {
              requires_auth: true,
              visible_for_role: ['admin', 'superadmin']
            }
          }
        ],
        meta: {
          requires_auth: true,
          visible_for_role: ['admin', 'superadmin']
        }
      },
      // * Bajas
      {
        path: '/bajas',
        name: 'Bajas',
        component: ExclusionList,
        meta: {
          requires_auth: true,
          visible_for_role: ['admin', 'superadmin']
        },
        children: [
          {
            path: 'tipo/formulario-individual',
            name: 'BajaIndividual',
            component: IndividualExclusionForm,
            meta: {
              requires_auth: true,
              visible_for_role: ['admin', 'superadmin']
            }
          },
          {
            path: ':id/tipo/individual',
            name: 'BajaDetail',
            component: IndividualExclusionDetail,
            meta: {
              requires_auth: true,
              visible_for_role: ['admin', 'superadmin', 'rrhh']
            }
          },
          {
            path: ':id/tipo/masivo',
            name: 'BajaMasivaDetail',
            component: MassiveExclusionDetail,
            meta: {
              requires_auth: true,
              visible_for_role: ['admin', 'superadmin', 'rrhh']
            }
          },
          {
            path: 'tipo/formulario-masivo',
            name: 'BajaMasiva',
            component: MassiveExclusionForm,
            meta: {
              requires_auth: true,
              visible_for_role: ['admin', 'superadmin']
            }
          }
        ]
      },
      {
        path: '/bajas-cargas',
        name: 'BajasCargas',
        component: DependantsExclusionList,
        meta: {
          requires_auth: true,
          visible_for_role: ['admin', 'superadmin']
        },
        children: [
          {
            path: 'formulario-individual',
            name: 'BajaCargasIndividual',
            component: IndividualDependantsExclusionForm,
            meta: {
              requires_auth: true,
              visible_for_role: ['admin', 'superadmin']
            }
          },
          {
            path: ':id',
            name: 'BajaCargasDetalle',
            component: IndividualDependantsExclusionDetail,
            meta: {
              requires_auth: true,
              visible_for_role: ['admin', 'superadmin', 'rrhh']
            }
          }
        ]
      },
      // * Modificaciones
      {
        path: '/modificaciones',
        name: 'Modificaciones',
        component: ModificationList,
        meta: {
          requires_auth: true,
          visible_for_role: ['admin', 'superadmin']
        },
        children: [
          {
            path: 'tipo/formulario-individual',
            name: 'ModificacionIndividual',
            component: IndividualModificationForm,
            meta: {
              requires_auth: true,
              visible_for_role: ['admin', 'superadmin']
            }
          },
          {
            path: 'tipo/formulario-masivo',
            name: 'ModificacionMasiva',
            component: MassiveModificationForm,
            meta: {
              requires_auth: true,
              visible_for_role: ['admin', 'superadmin']
            }
          },
          {
            path: ':id/tipo/individual',
            name: 'DetalleModificaciones',
            component: IndividualModificationDetail,
            meta: {
              requires_auth: true,
              visible_for_role: ['admin', 'superadmin', 'rrhh']
            }
          },
          {
            path: ':id/tipo/masivo',
            name: 'DetalleModificacionMasiva',
            component: MassiveModificationDetail,
            meta: {
              requires_auth: true,
              visible_for_role: ['admin', 'superadmin', 'rrhh']
            }
          }
        ]
      },

      // * Rechazo
      {
        path: '/rechazo',
        name: 'Rechazo',
        component: RejectionList,
        meta: {
          requires_auth: true,
          visible_for_role: ['admin', 'superadmin']
        },
        children: [
          {
            path: ':id',
            name: 'DetalleRechazo',
            component: RejectionDetail,
            meta: {
              requires_auth: true,
              visible_for_role: ['admin', 'superadmin', 'rrhh']
            }
          }
        ]
      },
      // * FueraTiempo
      {
        path: '/fuera-de-tiempo',
        name: 'FueraTiempo',
        component: OutOfTimeList,
        meta: {
          requires_auth: true,
          visible_for_role: ['admin', 'superadmin']
        },
        children: [
          {
            path: ':id/tipo/formulario-incorporacion',
            name: 'DetalleFueraTiempo',
            component: OutOfTimeDetail,
            meta: {
              requires_auth: true,
              visible_for_role: ['admin', 'superadmin', 'rrhh']
            }
          }
        ]
      },

      // * Antecedentes
      {
        path: '/antecedentes',
        name: 'Antecedentes',
        component: LackDataList,
        meta: {
          requires_auth: true,
          visible_for_role: ['admin', 'superadmin']
        },
        children: [
          {
            path: ':id',
            name: 'DetalleCaso',
            component: LackDataDetail,
            meta: {
              requires_auth: true,
              visible_for_role: ['admin', 'superadmin', 'rrhh']
            }
          }
        ]
      },
      // * Cliente
      {
        path: '/clientes',
        name: 'Cliente',
        component: CustomerList,
        meta: {
          requires_auth: true,
          visible_for_role: ['admin', 'superadmin']
        },
        children: [
          {
            path: 'empresa/:id',
            name: 'Empresa',
            component: CompanyDetail,
            meta: {
              requires_auth: true,
              visible_for_role: ['admin', 'superadmin']
            },
            children: [
              {
                path: 'grupos',
                name: 'Grupos',
                component: GroupList,
                meta: {
                  requires_auth: true,
                  visible_for_role: ['admin', 'superadmin'],
                  transition: false
                }
              },
              {
                path: 'poliza',
                name: 'Poliza',
                component: PolicyTabsContainer,
                meta: {
                  requires_auth: true,
                  visible_for_role: ['admin', 'superadmin'],
                  transition: false,
                  desable_automatic_scrolling: true
                },
                children: [
                  {
                    path: ':policyId',
                    name: 'PolicyDetail',
                    component: PolicyDetail,
                    meta: {
                      requires_auth: true,
                      visible_for_role: ['admin', 'superadmin']
                    }
                  },
                  {
                    path: 'movimientos',
                    name: 'Movimientos',
                    component: MovementsPolicyList,
                    meta: {
                      requires_auth: true,
                      visible_for_role: ['admin', 'superadmin']
                    }
                  },
                  {
                    path: 'cobranza&facturacion',
                    name: 'cobranzaFacturacionTabs',
                    component: CollectionPolicyConfigList,
                    meta: {
                      requires_auth: true,
                      visible_for_role: ['admin', 'superadmin']
                    },
                    children: [
                      {
                        path: 'formulario-configuracion',
                        name: 'ConfigPoliza',
                        component: CollectionPolicyConfigForm,
                        meta: {
                          requires_auth: true,
                          visible_for_role: ['admin', 'superadmin']
                        },
                        beforeEnter: (to, from) => {
                          if (from.name === undefined) {
                            router.back();
                          }
                        }
                      }
                    ]
                  }
                ]
              },
              {
                path: 'stock',
                name: 'Stock',
                component: StockList,
                children: [
                  {
                    path: 'colaborador/:colaborador',
                    name: 'Colaborador',
                    component: StockEmployeeDetail
                  }
                ],
                meta: {
                  requires_auth: true,
                  visible_for_role: ['admin', 'superadmin'],
                  transition: false
                }
              },
              {
                path: 'usuarios',
                name: 'Usuarios',
                component: UserList,
                meta: {
                  requires_auth: true,
                  visible_for_role: ['admin', 'superadmin'],
                  transition: false
                }
              },
              {
                path: 'recepcion',
                name: 'Recepcion',
                component: ReceptionList,
                meta: {
                  requires_auth: true,
                  visible_for_role: ['admin', 'superadmin'],
                  transition: false
                }
              },
              {
                path: 'notificaciones',
                name: 'Notificaciones',
                component: NotificationList,
                meta: {
                  requires_auth: true,
                  visible_for_role: ['admin', 'superadmin'],
                  transition: false
                }
              },
              {
                path: 'brokers',
                name: 'Brokers',
                component: BrokerList,
                meta: {
                  requires_auth: true,
                  visible_for_role: ['admin', 'superadmin'],
                  transition: false
                }
              },
              {
                path: 'emails',
                name: 'Emails',
                component: EmailList,
                meta: {
                  requires_auth: true,
                  visible_for_role: ['admin', 'superadmin'],
                  transition: false
                }
              }
            ]
          }
        ]
      },
      // * Traspasos
      {
        path: '/traspasos',
        name: 'Transfers',
        component: TransferList,
        meta: {
          requires_auth: true,
          visible_for_role: ['admin', 'superadmin']
        },
        children: [
          {
            path: ':id',
            name: 'TransferDetail',
            component: IndividualTransferDetail,
            meta: {
              requires_auth: true,
              visible_for_role: ['admin', 'superadmin', 'rrhh']
            }
          },
          {
            path: 'creacion',
            name: 'TransferCreation',
            component: IndividualTransferForm,
            meta: {
              requires_auth: true,
              visible_for_role: ['admin', 'superadmin']
            }
          }
        ]
      },
      // * Aseguradoras
      {
        path: '/aseguradora',
        name: 'Aseguradoras',
        component: InsuranceCompanyList,
        meta: {
          requires_auth: true,
          visible_for_role: ['admin', 'superadmin']
        },
        children: [
          {
            path: ':id',
            name: 'AseguradorasDetails',
            component: InsuranceCompanyDetail,
            meta: {
              requires_auth: true,
              visible_for_role: ['admin', 'superadmin']
            }
          }
        ]
      },
      // * Cobranza y Facturacion

      // PROCESOS
      {
        path: '/cobranza-facturacion',
        name: 'CobranzaFacturacion',
        component: ProcessList,
        meta: {
          requires_auth: true,
          visible_for_role: ['admin', 'superadmin']
        },
        children: [
          {
            path: ':id',
            name: 'DetalleCobranzaFacturacion',
            component: CollectionGroupDetail,
            meta: {
              requires_auth: true,
              visible_for_role: ['admin', 'superadmin']
            }
          },
          {
            path: 'ticket-cobranza/:id',
            name: 'detalleTicketCobranza',
            component: CollectionDetail,
            meta: {
              requires_auth: true,
              visible_for_role: ['admin', 'superadmin', 'rrhh']
            }
          },
          {
            path: 'creacion',
            name: 'BillingFormCration',
            component: CollectionGroupForm,
            meta: {
              requires_auth: true,
              visible_for_role: ['admin', 'superadmin']
            }
          }
        ]
      },
      {
        path: '/inconsistencia',
        name: 'Inconsistencia',
        component: InconsistenciesList,
        meta: {
          requires_auth: true,
          visible_for_role: ['admin', 'superadmin']
        },
        children: [
          {
            path: ':id',
            name: 'InconsistencyDetail',
            component: InconsistenciesDetail,
            meta: {
              requires_auth: true,
              visible_for_role: ['admin', 'superadmin']
            }
          }
        ]
      },
      {
        path: '/cambio&adicional',
        name: 'cambioAdicional',
        component: PayrollModificationList,
        meta: {
          requires_auth: true,
          visible_for_role: ['admin', 'superadmin']
        },
        children: [
          {
            path: ':id',
            name: 'ChangeAndOrAditionalDetail',
            component: PayrollModificationDetail,
            meta: {
              requires_auth: true,
              visible_for_role: ['admin', 'superadmin']
            }
          }
        ]
      },
      {
        path: '/anulacion-de-facturas',
        name: 'Anulacion',
        component: InvoiceCancellationList,
        meta: {
          requires_auth: true,
          visible_for_role: ['admin', 'superadmin']
        },
        children: [
          {
            path: ':id',
            name: 'AnnulmentDetail',
            component: InvoiceCancellationDetail,
            meta: {
              requires_auth: true,
              visible_for_role: ['admin', 'superadmin']
            }
          }
        ]
      },
      {
        path: '/nota-de-credito',
        name: 'CreditNote',
        component: CreditNoteList,
        meta: {
          requires_auth: true,
          visible_for_role: ['admin', 'superadmin']
        },
        children: [
          {
            path: ':id',
            name: 'CreditNoteDetail',
            component: CreditNoteDetail,
            meta: {
              requires_auth: true,
              visible_for_role: ['admin', 'superadmin']
            }
          }
        ]
      },
      {
        path: '/nota-de-debito',
        name: 'DebitNote',
        component: DebitNoteList,
        meta: {
          requires_auth: true,
          visible_for_role: ['admin', 'superadmin']
        },
        children: [
          {
            path: ':id',
            name: 'DebitNoteDetail',
            component: DebitNoteDetail,
            meta: {
              requires_auth: true,
              visible_for_role: ['admin', 'superadmin']
            }
          }
        ]
      },
      {
        path: '/ajuste-retroactivo',
        name: 'RetroActiveAdjustment',
        component: RetroactiveAdjustmentList,
        meta: {
          requires_auth: true,
          visible_for_role: ['admin', 'superadmin']
        },
        children: [
          {
            path: ':id',
            name: 'RetroActiveAdjustmentDetail',
            component: RetroactiveAdjustmentDetail,
            meta: {
              requires_auth: true,
              visible_for_role: ['admin', 'superadmin']
            }
          }
        ]
      }
    ]
  },
  // * Carga de antecedentes
  {
    path: '/carga_de_antecedentes/:case_id/:token',
    name: 'CargaDeAntecedentes',
    component: LackDataForm,
    meta: { title: defaultTitle }
  },
  {
    path: '/cargas_antecedentes_listo',
    name: 'CargaAntecedentesListo',
    component: LackDataFormSuccess,
    meta: {
      requires_auth: false,
      title: defaultTitle
    }
  },

  // * Resolicitud de documento
  {
    path: '/resolicitud_documento/:id/:token',
    name: 'ResolicitudDocumento',
    component: FileRejectionForm
  },
  {
    path: '/resolicitud_documento_listo',
    name: 'ResolicitudDocumentoListo',
    component: FileRejectionFormSuccess
  },
  {
    path: '/resolicitud_documento_error',
    name: 'ResolicitudDocumentoError',
    component: FileRejectionFormError
  },
  // * Inicio de sesion
  {
    path: '/login',
    name: 'Login',
    component: Login,
    meta: {
      requires_auth: false,
      title: defaultTitle
    }
  },
  // * Reseteo de contrasena
  {
    path: '/restablecer',
    component: ResetStructure,
    children: [
      {
        path: '',
        name: 'ResetPassword',
        component: ResetPassword,
        meta: {
          requires_auth: false,
          title: defaultTitle
        }
      },
      {
        path: 'nueva_contrasena/:id/:token',
        name: 'NewPassword',
        component: NewPassword,
        meta: {
          requires_auth: false,
          title: defaultTitle
        }
      }
    ]
  },
  // * Insured
  {
    path: '/insuredform/:token',
    name: 'insuredform',
    component: TemplateIncorporationForm,
    meta: {
      requires_auth: false,
      title: defaultTitle
    }
  },
  {
    path: '/form/insured/welcome/:token',
    name: 'insuredform1',
    component: TemplateIncorporationFormStep1,
    beforeEnter: (to, from) => {
      if (from.name === undefined) {
        router.push({ path: `/insuredform/${to.params.token}` });
      }
    },
    meta: {
      requires_auth: false,
      title: defaultTitle
    }
  },
  {
    path: '/form/insured/user-verification/:token',
    name: 'insuredform2',
    component: TemplateIncorporationFormStep2,
    beforeEnter: (to, from) => {
      if (from.name === undefined) {
        router.push({ path: `/insuredform/${to.params.token}` });
      }
    },
    meta: {
      requires_auth: false,
      title: defaultTitle
    }
  },
  {
    path: '/forms/insured/user-loads/:token',
    name: 'insuredform3',
    component: TemplateIncorporationFormStep3,
    beforeEnter: (to, from) => {
      if (from.name === undefined) {
        router.push({ path: `/insuredform/${to.params.token}` });
      }
    },
    meta: {
      requires_auth: false,
      title: defaultTitle
    }
  },
  {
    path: '/forms/insured/consent/:token',
    name: 'Auth',
    component: TemplateIncorporationAuth,
    beforeEnter: (to, from) => {
      if (from.name === undefined) {
        router.push({ path: `/insuredform/${to.params.token}` });
      }
    },
    meta: {
      requires_auth: false,
      title: defaultTitle
    }
  },
  {
    path: '/forms/insured/summary/:token',
    name: 'Resume',
    component: TemplateIncorporationFormResume,
    beforeEnter: (to, from) => {
      if (from.name === undefined) {
        router.push({ path: `/insuredform/${to.params.token}` });
      }
    },
    meta: {
      requires_auth: false,
      title: defaultTitle
    }
  },
  {
    path: '/forms/insured/success/:token',
    name: 'ready',
    component: TemplateIncorporationFormSuccess,
    beforeEnter: (to, from) => {
      if (from.name === undefined) {
        router.push({ path: `/insuredform/${to.params.token}` });
      }
    },
    meta: {
      requires_auth: false,
      title: defaultTitle
    }
  },
  // * Incorporacion digital form
  {
    path: '/formulario-digital-incorporacion/:token',
    name: 'DigitalIncorporationForm',
    component: DigitalIncorporationForm,
    meta: {
      requires_auth: false
    }
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

const userRoleValidation = (roles) => {
  if (roles === undefined || roles.length === 0) {
    return true;
  }

  const role = store.getters[GET_USER_ROLE];
  return roles.includes(role);
};

router.beforeEach((to, from, next) => {
  document.title = to.meta.title || defaultTitle;
  next();
});

router.beforeEach(async (to, from, next) => {
  if (to.meta.desable_automatic_scrolling != true) {
    scrollToTopPage();
  }

  // * Valido que la ruta requiera de autorizacion
  const auth_required = to.meta.requires_auth;
  const { path } = to;

  // * Obtengo el rol de usuario que tiene que tener esta ruta
  const user_role = to.meta.visible_for_role;

  if (auth_required) {
    // Como necesito estar logeado, primero pregunto si existe un usuario vuex validando el tiempo de
    // expiracion de su token obtenido, esto significa que ya estoy autenticado y no necesito hacer llamado al
    // endpoint de validacion del token
    if (Date.now() >= store.getters[GET_USER_TOKEN_EXP] * 1000) {
      const resp_validation = await validateToken();

      if (resp_validation === true) {
        // Si es que esta ruta no es valida para el rol que tengo, le doy a next 404
        const valid_user = userRoleValidation(user_role);
        if (valid_user === false) {
          next({ name: 'Missing' });
        }

        next();
      } else {
        next({
          path: '/login'
        });
      }
    } else {
      // Si es que esta ruta no es valida para el rol que tengo, le doy a next 404
      const valid_user = userRoleValidation(user_role);
      if (valid_user === false) {
        next({ name: 'Missing' });
      }
      next();
    }
  } else {
    if (path === '/login' && (await validateToken())) {
      next({
        path: '/'
      });
    } else {
      next();
    }
  }
});

export default router;
