<template>
  <a-row justify="center" align="bottom" style="margin: 0; padding: 0; min-height: 100svh">
    <a-col :xs="0" :lg="12" class="bg-gray-2">
      <div style="
          display: flex;
          flex-direction: column;
          min-height: 90svh;
          justify-content: space-between !important;
        ">
        <a-row style="display: flex; justify-content: center">
          <a-typography-title class="text-center text-blue-10">Experiencia digital <br />
            en
            <a-typography-text class="text-color-secondary">salud y beneficios</a-typography-text>
          </a-typography-title>
        </a-row>
        <a-row style="display: flex; justify-content: center">
          <img style="height: 31px; width: 141px" src="@/assets/images/new-identity/logoBewell.svg" alt="" />
        </a-row>
        <a-row style="display: flex; justify-content: center">
          <a-col :lg="24" :xl="16">
            <img class="img-fluid" src="@/assets/images/new-identity/login-image.png" alt="" />
          </a-col>
        </a-row>
      </div>
    </a-col>
    <a-col :xs="24" :lg="12" class="bg-gray-1">
      <a-row align="middle" justify="center" style="min-height: 100svh">
        <a-col class="mt-5" :xs="18" :md="16" :xl="12">
          <div style="margin-bottom: auto !important">
            <a-typography-title :level="2" class="ff-urbanist title-underline text-blue-8">Ingresar</a-typography-title>

            <a-form name="login-form" layout="vertical" ref="form_ref" :model="form_state" :rules="rules"
              @finish="formLogin">
              <!-- Email -->
              <a-form-item has-feedback label="Correo Electrónico" autocomplete="off" name="email">
                <a-input v-model:value="form_state.email" class="ant_input" placeholder="Correo Electrónico" />
              </a-form-item>

              <!-- Password -->
              <a-form-item has-feedback label="Contraseña" class="ant_input_password" name="password">
                <a-input-password v-model:value="form_state.password" class="ant_input" placeholder="Contraseña" />
              </a-form-item>

              <!-- Mensaje de error -->
              <p v-if="login_error_message.length > 0" class="text-error">
                {{ login_error_message }}
              </p>
              <router-link class="text-error" :to="{ name: 'ResetPassword' }" v-if="show_resetpass_link">
                <u>¿Olvidaste tu contraseña?</u>
              </router-link>

              <!-- Remember credentials -->
              <a-form-item name="remember_credentials">
                <a-checkbox name="type" v-model:checked="form_state.remember_credentials">Recordarme</a-checkbox>
              </a-form-item>

              <a-form-item>
                <a-button class="btn-client" size="large" html-type="submit" :loading="loading" block>
                  Enviar
                </a-button>
              </a-form-item>
            </a-form>

            <router-link class="justify-content-center d-flex fw-regular text-color-secondary" to="/restablecer">
              ¿Olvidaste tu contraseña?</router-link>
          </div>

          <a-typography-paragraph class="fs-xs text-center my-5">
            <a-typography-text strong>© {{ current_year }}, bewell+.</a-typography-text>
            Todos los derechos reservados
          </a-typography-paragraph>
        </a-col>
      </a-row>
    </a-col>
  </a-row>
</template>

<script>
import { reactive, ref } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import { LOG_IN } from '../../store/types';
import validateEmail from '@/utils/emailValidator';

export default {
  name: 'Login',
  components: {},
  setup() {
    // * Router
    const router = useRouter();

    // * Store
    const store = useStore();

    // * Reglas para el fomulario
    // Validacion de email
    const checkEmail = async (rule, value) => {
      if (!value) {
        return Promise.reject('Ingrese correo electrónico porfavor');
      }
      if (validateEmail(value)) {
        return Promise.resolve();
      }
      return Promise.reject('Correo inválido');
    };

    // Validacion de contraseña
    const checkPassword = async (rule, value) => {
      if (!value) {
        const error_message = 'Ingrese contraseña porfavor';
        return Promise.reject(error_message);
      }
      return Promise.resolve();
    };

    // * Formulario
    // referencia del formulario
    const form_ref = ref('');

    // Carga
    const loading = ref(false);

    // Data del formulario
    const form_state = reactive({
      email: '',
      password: '',
      remember_credentials: false
    });

    // Reglas
    const rules = {
      email: [
        {
          validator: checkEmail,
          trigger: 'change'
        }
      ],
      password: [
        {
          validator: checkPassword,
          trigger: 'change'
        }
      ]
    };

    // * Inicio de sesion
    const login_error_message = ref('');
    const show_resetpass_link = ref(false);

    const formLogin = async () => {
      login_error_message.value = '';
      show_resetpass_link.value = false;

      loading.value = true;
      try {
        const resp = await form_ref.value.validate();

        const login_resp = await store.dispatch(LOG_IN, resp);

        if (login_resp.status === 201) {
          router.push({ name: 'Dashboard' });
        }
      } catch (error) {
        if (error.response == undefined) {
          login_error_message.value =
            'Ocurrio un error inesperado, vuelve a intentarlo mas tarde.';
        } else {
          login_error_message.value =
            'Las credenciales ingresadas son incorrectas.';
          show_resetpass_link.value = true;
        }
      }
      loading.value = false;
    };

    // * Año en el que nos encontramos
    const current_year = new Date().getFullYear();

    return {
      form_ref,
      form_state,
      rules,
      loading,
      current_year,
      login_error_message,
      show_resetpass_link,
      formLogin
    };
  }
};
</script>
