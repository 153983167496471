import {
  employeeCompanies,
  employeeInsurance,
  policityInsuranceCompany,
  ticketBajaMasiva,
  getBajaMasivaTickets,
  TicketqueryParamsBaja,
  cancelBajaMasiva,
  successBajaMasiva,
  getBajaMasivaForm,
  postTicketBajaIndividual,
  finishTicketBaja,
  cancelTicketBaja,
  updateEndDateBeforeFinishMassiveExclusion,
  updateEndDateBeforeFinishExclusion
} from '../../../network';

import {
  SET_COMPANY_BY_RUT,
  FETCH_COMPANY_BY_RUT,
  GET_COMPANY_BY_RUT,
  SET_INSURANCE_COMPANY_BY_RUT,
  FETCH_INSURANCECOMPANY_BY_RUT,
  SET_POLICY_INSURANCE_COMPANY,
  FETCH_POLICY_INSURANCE_COMPANY,
  POST_TICKET_BAJA_MASIVA,
  GET_BAJA_MASIVA,
  SET_BAJA_MASIVA,
  FETCH_BAJA_MASIVA,
  SET_BAJA_INDIVIDUAL,
  FETCH_BAJA_INDIVIDUAL,
  GET_BAJA_INDIVIDUAL,
  CANCEL_BAJA_MASIVA,
  SUCCESS_BAJA_MASIVA,
  FETCH_BM_URL,
  POST_BAJA_INDIVIDUAL,
  PATCH_FINISH_TICKET_BAJA,
  POST_CANCEL_TICKET_BAJA,
  UPDATE_END_DATE_BEFORE_FINISH_EXCLUSION,
  UPDATE_END_DATE_BEFORE_FINISH_MASSIVE_EXCLUSION
} from './types';

const store = {};

const getDefaultState = () => ({
  companyByRut: [],
  insuranceCompanyByRut: [],
  policyInsuranceCompany: [],
  companies: [],
  bajaMasiva: [],
  bajaIndividual: [],
  url: ''
});

store.state = getDefaultState();

store.getters = {
  [GET_COMPANY_BY_RUT]: (state) => state.companyByRut,
  [GET_BAJA_MASIVA]: (state) => state.bajaMasiva,
  [GET_BAJA_INDIVIDUAL]: (state) => state.bajaIndividual
};

// * Mutations
store.mutations = {
  [SET_COMPANY_BY_RUT](state, payload) {
    state.companyByRut = payload;
  },
  [SET_INSURANCE_COMPANY_BY_RUT](state, payload) {
    state.insuranceCompanyByRut = payload;
  },
  [SET_POLICY_INSURANCE_COMPANY](state, payload) {
    state.policyInsuranceCompany = payload;
  },
  [SET_BAJA_INDIVIDUAL](state, payload) {
    state.bajaIndividual = payload.map((a) => {
      return {
        id: a.id,
        key: a.id,
        holding: a.company?.holding
          ? a.company?.holding.businessName
          : a.company.subHolding?.holding?.businessName,
        subholding: a
          ? a.company.subHolding
            ? a.company.subHolding.name
            : ''
          : '',
        companyName: a.company.businessName,
        companyRut: a.company.rut,
        employeeName: `${a.employee.firstName} ${a.employee.firstSurname}`,
        employeeRut: a.employee.rut,
        ticketState: a.states.sort((a, b) => {
          return b.id - a.id;
        })[0].state.id,
        createdAt: a.createdAt,
        detail: 'Ver más',
        states: a.states
      };
    });
  },
  [SET_BAJA_MASIVA](state, payload) {
    state.bajaMasiva = payload.map((a) => {
      return {
        id: a.id,
        key: a.id,
        holding: a.holding ? a.holding : '',
        subholding: a.subHolding ? a.subHolding : '',
        companyName: a.businessName,
        companyRut: a.companyRut,
        count: a.count,
        createdAt: a.createdAt,
        detail: 'Ver más'
      };
    });
  }
};

// * Actions
store.actions = {
  [FETCH_BM_URL]() {
    return getBajaMasivaForm();
  },
  [FETCH_COMPANY_BY_RUT]({ commit }, payload) {
    return new Promise((resolve, reject) => {
      employeeCompanies(payload).then(
        (resp) => {
          commit(SET_COMPANY_BY_RUT, resp);
          resolve(resp);
        },
        (err) => {
          reject(err);
        }
      );
    });
  },
  [FETCH_INSURANCECOMPANY_BY_RUT]({ commit }, { payload, companyId }) {
    return new Promise((resolve, reject) => {
      employeeInsurance(payload, companyId).then(
        (resp) => {
          commit(SET_INSURANCE_COMPANY_BY_RUT, resp.data);
          resolve(resp);
        },
        (err) => {
          reject(err);
        }
      );
    });
  },
  [FETCH_POLICY_INSURANCE_COMPANY]({ commit }, { company, insurance, rut }) {
    return new Promise((resolve, reject) => {
      policityInsuranceCompany(company, insurance, rut).then(
        (resp) => {
          commit(SET_POLICY_INSURANCE_COMPANY, resp.data);
          resolve(resp);
        },
        (err) => {
          reject(err);
        }
      );
    });
  },
  async [POST_TICKET_BAJA_MASIVA](_, payload) {
    const formData = new FormData();
    formData.append('insuranceCompanyId', payload.insurance);
    formData.append('companyId', payload.company);
    formData.append('brokerId', payload.brokerId);
    formData.append('message', payload.message);
    formData.append('file', payload.fileList[0]);
    payload.optionalFiles.forEach((f) => {
      formData.append('optionalFiles', f);
    });
    payload.policyId.forEach((p) => {
      formData.append('policies[]', p);
    });
    let error = '';
    const response = await ticketBajaMasiva(formData).catch(
      (err) => (error = err)
    );
    if (response && (response.status === 200 || response.status === 201)) {
      return response.data;
    }
    throw error;
  },
  async [FETCH_BAJA_MASIVA]({ commit }) {
    let error = '';
    const response = await getBajaMasivaTickets().catch((err) => (error = err));
    if (response && response.status === 200) {
      commit(SET_BAJA_MASIVA, response.data);
    } else {
      throw error;
    }
  },
  async [FETCH_BAJA_INDIVIDUAL]({ commit }) {
    return new Promise((resolve, reject) => {
      TicketqueryParamsBaja().then(
        (resp) => {
          commit(SET_BAJA_INDIVIDUAL, resp.data);
          resolve(resp);
        },
        (err) => {
          reject(err);
        }
      );
    });
  },
  [POST_BAJA_INDIVIDUAL](_, payload) {
    return new Promise((resolve, reject) => {
      postTicketBajaIndividual(payload).then(
        (resp) => {
          resolve(resp);
        },
        (err) => {
          reject(err);
        }
      );
    });
  },
  async [CANCEL_BAJA_MASIVA](_, { payload, idAlta }) {
    const formData = new FormData();
    formData.append('message', payload.message);
    formData.append('file', payload.fileList[0]);
    let error = '';
    const response = await cancelBajaMasiva(idAlta, formData).catch(
      (err) => (error = err)
    );
    if (response && (response.status === 200 || response.status === 201)) {
      return response.data;
    }
    throw error;
  },

  async [SUCCESS_BAJA_MASIVA](_, { idAlta, payload }) {
    let error = '';
    const response = await successBajaMasiva(idAlta, payload).catch(
      (err) => (error = err)
    );
    if (response && (response.status === 201 || response.status === 200)) {
      return response;
    }
    throw error;
  },
  [PATCH_FINISH_TICKET_BAJA](_, payload) {
    return new Promise((resolve, reject) => {
      finishTicketBaja(payload)
        .then((resp) => {
          resolve(resp.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  [POST_CANCEL_TICKET_BAJA](_, payload) {
    return new Promise((resolve, reject) => {
      cancelTicketBaja(payload)
        .then((resp) => {
          resolve(resp.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  async [UPDATE_END_DATE_BEFORE_FINISH_EXCLUSION](_, { id, endDate }) {
    const response = await updateEndDateBeforeFinishExclusion(id, endDate);
    return response.data;
  },
  async [UPDATE_END_DATE_BEFORE_FINISH_MASSIVE_EXCLUSION](
    _,
    { ticketId, endDate }
  ) {
    const response = await updateEndDateBeforeFinishMassiveExclusion(
      ticketId,
      endDate
    );
    return response.data;
  }
};

export default store;
