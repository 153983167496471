export const SET_COMPANY_BY_RUT = 'bajas/SET_COMPANY_BY_RUT';
export const SET_INSURANCE_COMPANY_BY_RUT =
  'bajas/SET_INSURANCE_COMPANY_BY_RUT';
export const SET_POLICY_INSURANCE_COMPANY =
  'bajas/SET_POLICY_INSURANCE_COMPANY';
export const SET_BAJA_MASIVA = 'bajas/SET_BAJA_MASIVA';
export const SET_BAJA_INDIVIDUAL = 'bajas/SET_BAJA_INDIVIDUAL';

export const GET_COMPANY_BY_RUT = 'bajas/GET_COMPANY_BY_RUT';
export const GET_BAJA_MASIVA = 'bajas/GET_BAJA_MASIVA';
export const GET_BAJA_INDIVIDUAL = 'bajas/GET_BAJA_INDIVIDUAL';

export const FETCH_COMPANY_BY_RUT = 'bajas/FETCH_COMPANY_BY_RUT';
export const FETCH_INSURANCECOMPANY_BY_RUT =
  'bajas/FETCH_INSURANCECOMPANY_BY_RUT';
export const FETCH_POLICY_INSURANCE_COMPANY =
  'bajas/FETCH_POLICY_INSURANCE_COMPANY';
export const FETCH_BAJA_MASIVA = 'bajas/FETCH_BAJA_MASIVA';
export const FETCH_BAJA_INDIVIDUAL = 'bajas/FETCH_BAJA_INDIVIDUAL';
export const FETCH_BM_URL = 'bajas/FETCH_BM_URL';

export const POST_TICKET_BAJA_MASIVA = 'bajas/POST_TICKET_BAJA_MASIVA';
export const POST_BAJA_INDIVIDUAL = 'bajas/POST_BAJA_INDIVIDUAL';
export const POST_CANCEL_TICKET_BAJA = 'bajas/POST_CANCEL_TICKET_BAJA';
export const PATCH_FINISH_TICKET_BAJA = 'ticket/PATCH_FINISH_TICKET_BAJA';
export const CANCEL_BAJA_MASIVA = 'bajas/CANCEL_BAJA_MASIVA';
export const SUCCESS_BAJA_MASIVA = 'bajas/SUCCESS_BAJA_MASIVA';
export const UPDATE_END_DATE_BEFORE_FINISH_EXCLUSION =
  'bajas/UPDATE_END_DATE_BEFORE_FINISH_EXCLUSION';

export const UPDATE_END_DATE_BEFORE_FINISH_MASSIVE_EXCLUSION =
  'bajas/UPDATE_END_DATE_BEFORE_FINISH_MASSIVE_EXCLUSION';
