<template>
  <a-layout-sider
    width="269"
    :style="{
      height: '100vh',
      background: '#fdfeff',
      position: 'sticky',
      display: 'initial',
      left: 0,
      top: 0,
      bottom: 0
    }"
    breakpoint="lg"
    collapsed-width="0"
    :zeroWidthTriggerStyle="{ background: '#2521E5' }"
  >
    <a-row type="flex" justify="space-around" align="middle">
      <a-col>
        <div @click="toHome" class="logo-redirection">
          <img
            style="height: 35px"
            class="my-5"
            src="@/assets/images/new-identity/logoBewell.svg"
            alt="Logo principal"
          />
        </div>
      </a-col>
    </a-row>

    <a-menu
      style="width: 256px; overflow-x: hidden; max-height: 80vh"
      mode="inline"
      :style="{ borderRight: 0 }"
    >
      <template v-for="(menuItem, menuIndex) in menu">
        <a-menu-item
          v-if="!menuItem.childrens"
          :key="`menu-${menuIndex}`"
          @click="redirectToPage(menuItem.link)"
        >
          <i
            v-if="typeof menuItem.icon === 'string'"
            class="icon-spacing"
            :class="[menuItem.icon]"
          ></i>
          <component
            v-else
            :is="menuItem.icon"
            class="icon-spacing"
          ></component>
          {{ menuItem.title }}
        </a-menu-item>

        <a-sub-menu v-if="menuItem.childrens" :key="`menu-${menuIndex}`">
          <template #icon>
            <i
              v-if="typeof menuItem.icon === 'string'"
              class="icon-spacing"
              :class="[menuItem.icon]"
            ></i>
            <component
              v-else
              :is="menuItem.icon"
              class="icon-spacing"
            ></component>
          </template>
          <template #title>{{ menuItem.title }}</template>
          <a-menu-item-group
            v-for="(children, childrenIndex) in menuItem.childrens"
            :key="`submenu2-${menuIndex}-${childrenIndex}`"
            :title="children.title"
          >
            <template
              v-for="(subChildren, subChildrenIndex) in children.childrens"
            >
              <a-sub-menu
                v-if="subChildren.childrens"
                :key="`submenu3-${menuIndex}-${childrenIndex}-${subChildrenIndex}`"
              >
                <template #icon>
                  <i
                    v-if="typeof subChildren.icon === 'string'"
                    class="icon-spacing"
                    :class="[subChildren.icon]"
                  ></i>
                  <component
                    v-else
                    :is="subChildren.icon"
                    class="icon-spacing"
                  ></component>
                </template>
                <template #title>
                  {{ subChildren.title }}
                </template>
                <a-menu-item
                  v-for="(
                    sub2Children, sub2ChildrenIndex
                  ) in subChildren.childrens"
                  :key="`submenu4-${menuIndex}-${childrenIndex}-${subChildrenIndex}-${sub2ChildrenIndex}`"
                  @click="redirectToPage(sub2Children.link)"
                >
                  <i
                    v-if="typeof sub2Children.icon === 'string'"
                    class="icon-spacing"
                    :class="[sub2Children.icon]"
                  ></i>
                  <component
                    v-else
                    :is="sub2Children.icon"
                    class="icon-spacing"
                  ></component>
                  {{ sub2Children.title }}
                </a-menu-item>
              </a-sub-menu>
              <a-menu-item
                v-if="!subChildren.childrens"
                @click="redirectToPage(subChildren.link)"
                :key="`submenu3-${menuIndex}-${childrenIndex}-${subChildrenIndex}`"
              >
                <i
                  v-if="typeof subChildren.icon === 'string'"
                  class="icon-spacing"
                  :class="[subChildren.icon]"
                ></i>
                <component
                  v-else
                  :is="subChildren.icon"
                  class="icon-spacing"
                ></component>
                {{ subChildren.title }}
              </a-menu-item>
            </template>
          </a-menu-item-group>
        </a-sub-menu>
      </template>
    </a-menu>
  </a-layout-sider>
</template>

<script>
import { useRoute, useRouter } from 'vue-router';
import { useStore } from 'vuex';

import {
  DiffOutlined,
  ExceptionOutlined,
  FileAddOutlined,
  FileExcelOutlined,
  FileOutlined,
  RetweetOutlined,
  SnippetsOutlined,
  HomeOutlined,
  DashboardOutlined,
  UserOutlined,
  TeamOutlined,
  UploadOutlined,
  DownloadOutlined,
  SwapOutlined,
  UserSwitchOutlined,
  ClockCircleOutlined,
  StopOutlined,
  MoneyCollectOutlined,
  ShopOutlined,
  BankOutlined
} from '@ant-design/icons-vue';

import { GET_USER_ROLE } from '../../store/types';

export default {
  name: 'Sidebar',
  setup() {
    // * VUEX
    const store = useStore();
    const userRole = store?.getters[GET_USER_ROLE];

    // * Redireccion a la pantalla principal
    const router = useRouter();
    const toHome = () => {
      router.push({ name: 'Dashboard' });
    };

    // * Links
    let menu = [
      {
        title: 'Inicio',
        icon: HomeOutlined,
        link: 'Dashboard'
      },
      {
        title: 'Movimientos',
        icon: DashboardOutlined,
        childrens: [
          {
            title: 'Solicitudes',
            childrens: [
              {
                title: 'Altas',
                icon: UploadOutlined,
                childrens: [
                  {
                    title: 'Titulares',
                    icon: UserOutlined,
                    link: 'Altas'
                  },
                  {
                    title: 'Cargas',
                    icon: TeamOutlined,
                    link: 'AltasCargas'
                  }
                ]
              },
              {
                title: 'Bajas',
                icon: DownloadOutlined,
                childrens: [
                  {
                    title: 'Titulares',
                    icon: UserOutlined,
                    link: 'Bajas'
                  },
                  {
                    title: 'Cargas',
                    icon: TeamOutlined,
                    link: 'BajasCargas'
                  }
                ]
              },
              {
                title: 'Modificación',
                icon: SwapOutlined,
                link: 'Modificaciones'
              },
              {
                title: 'Traspasos',
                icon: UserSwitchOutlined,
                link: 'Transfers'
              }
            ]
          },
          {
            title: 'Casos',
            childrens: [
              {
                title: 'Fuera de tiempo',
                icon: ClockCircleOutlined,
                link: 'FueraTiempo'
              },
              {
                title: 'Rechazo',
                icon: StopOutlined,
                link: 'Rechazo'
              },
              {
                title: 'Antecedentes',
                icon: FileAddOutlined,
                link: 'Antecedentes'
              }
            ]
          }
        ]
      },
      {
        title: 'Cobranza y fact.',
        icon: MoneyCollectOutlined,
        childrens: [
          {
            title: 'Procesos',
            childrens: [
              {
                title: 'Cobranza y fact.',
                icon: SnippetsOutlined,
                link: 'CobranzaFacturacion'
              }
            ]
          },
          {
            title: 'Reprocesos',
            childrens: [
              {
                title: 'Inconsistencia',
                icon: ExceptionOutlined,
                link: 'Inconsistencia'
              },
              {
                title: 'Cambio y/o adicional',
                icon: DiffOutlined,
                link: 'cambioAdicional'
              },
              {
                title: 'Anulación de factura',
                icon: FileExcelOutlined,
                link: 'Anulacion'
              }
            ]
          },
          {
            title: 'Solicitudes',
            childrens: [
              {
                title: 'Nota de crédito',
                icon: FileAddOutlined,
                link: 'CreditNote'
              },
              {
                title: 'Nota de débito',
                icon: FileOutlined,
                link: 'DebitNote'
              },
              {
                title: 'Ajuste retroactivo',
                icon: RetweetOutlined,
                link: 'RetroActiveAdjustment'
              }
            ]
          }
        ]
      },
      {
        title: 'Clientes',
        icon: ShopOutlined,
        link: 'Cliente'
      },
      {
        title: 'Aseguradoras',
        icon: BankOutlined,
        link: 'Aseguradoras'
      }
    ];

    // En caso de que el usuario sea RRHH, tengo que cambiarle los links que se ven
    if (userRole === 'rrhh') {
      menu = [
        {
          title: 'Inicio',
          icon: HomeOutlined,
          link: 'Dashboard'
        },
        {
          title: 'Movimientos',
          icon: DashboardOutlined,
          link: 'GlobalStock'
        },
        {
          title: 'Empleados',
          icon: UserOutlined,
          link: 'Employees'
        }
      ];
    }

    // * Cuando cargo la pagina, veo cual ruta, asi marco ya predeterminado el objeto sidebar
    const route = useRoute();

    const redirectToPage = (to) => {
      if (to != route.name) {
        router.push({ name: to });
      }
    };

    return {
      menu,
      toHome,
      redirectToPage
    };
  }
};
</script>

<style>
.logo-redirection {
  cursor: pointer;
}

li.ant-menu-submenu.ant-menu-submenu-inline
  div.ant-menu-submenu-title
  span.ant-menu-title-content {
  margin-left: 4px;
}

.icon-spacing {
  width: 40px;
}
</style>
