<template>
  <div>
    <PageHeader :breadcrumRoutes="routes_pageheader" title="Empleados" />

    <a-card>
      <a-row align="middle" justify="space-between">
        <a-typography-title :level="4" class="text-gray-8 mb-0">
          Lista de empleados
        </a-typography-title>

        <a-button type="primary" @click="showDownloadStockModal">
          Descargar
        </a-button>
        <a-modal
          v-model:visible="downloadStockModal"
          title="Descargar stock de empleados"
          @ok="handleDownloadStock"
        >
          <MainFilter :multipleCompanies="false" />
        </a-modal>
      </a-row>

      <EmployeesTable class="mt-3" />
    </a-card>
  </div>
</template>

<script setup>
import PageHeader from '@/components/PageHeader.vue';
import EmployeesTable from '@/components/rrhh/EmployeesTable.vue';
import MainFilter from '@/components/dashboard/SelectFilter.vue';
import {
  SELECTED_COMPANY,
  DOWNLOAD_EXCEL_STOCK,
  GET_LINK
} from '@/store/types';
import { useStore } from 'vuex';
import { ref } from 'vue';
import notification from '@/utils/notifications';

const store = useStore();

const routes_pageheader = [
  {
    breadcrumbName: 'Empleados'
  }
];

const downloadStockModal = ref(false);
const showDownloadStockModal = () => {
  downloadStockModal.value = true;
};

const handleDownloadStock = () => {
  const [selectedCompany] = store.getters[SELECTED_COMPANY];
  if (!selectedCompany) {
    notification('error', 'Error', 'Debe seleccionar una compañía.');
    return;
  }
  store.dispatch(DOWNLOAD_EXCEL_STOCK, selectedCompany);
  const downloadStockUrl = store.getters[GET_LINK];
  const link = document.createElement('a');
  link.href = downloadStockUrl;
  link.click();
};
</script>
