<template>
  <div
    style="
      display: flex;
      flex-direction: row;
      gap: 16px;
      flex-wrap: wrap;
      flex-grow: 1;
    "
  >
    <a-select
      v-model:value="selectHolding"
      style="flex-grow: 1; min-width: 200px"
      :options="filter"
      placeholder="Holding"
      :maxTagCount="2"
      :maxTagTextLength="5"
      size="default"
      optionFilterProp="label"
      mode="multiple"
    >
      <template #dropdownRender="{ menuNode: menu }">
        <v-nodes :vnodes="menu" />
        <div
          style="
            border-top: 1px solid #f0f0f0;
            display: flex;
            justify-content: space-between;
          "
          class="p-2"
          @mousedown="(e) => e.preventDefault()"
        >
          <a-button
            type="primary"
            size="small"
            style="width: 90px; margin-right: 8px"
            @click="selectAll('holding')"
          >
            Todo
          </a-button>

          <a-button
            size="small"
            style="width: 90px"
            @click="clearFilters('holding')"
          >
            Limpiar
          </a-button>
        </div>
      </template>
    </a-select>
    <a-select
      v-model:value="selectSubholding"
      style="flex-grow: 1; min-width: 200px"
      :options="subHoldingFilter"
      :maxTagCount="2"
      :maxTagTextLength="5"
      size="default"
      placeholder="Subholding"
      mode="multiple"
      :disabled="subHoldingFilter.length == 0"
      optionFilterProp="label"
    >
      <template #dropdownRender="{ menuNode: menu }">
        <v-nodes :vnodes="menu" />
        <div
          style="
            border-top: 1px solid #f0f0f0;
            display: flex;
            justify-content: space-between;
          "
          class="p-2"
          @mousedown="(e) => e.preventDefault()"
        >
          <a-button
            type="primary"
            size="small"
            style="width: 90px; margin-right: 8px"
            @click="selectAll('subholding')"
          >
            Todo
          </a-button>

          <a-button
            size="small"
            style="width: 90px"
            @click="clearFilters('subholding')"
          >
            Limpiar
          </a-button>
        </div>
      </template>
    </a-select>
    <a-select
      v-model:value="selectCompany"
      style="flex-grow: 1; min-width: 200px"
      :options="company"
      :maxTagCount="2"
      :maxTagTextLength="5"
      size="default"
      placeholder="Empresas"
      :mode="multipleCompanies ? 'multiple' : undefined"
      optionFilterProp="label"
    >
      <template #dropdownRender="{ menuNode: menu }">
        <v-nodes :vnodes="menu" />
        <div
          style="
            border-top: 1px solid #f0f0f0;
            display: flex;
            justify-content: space-between;
          "
          class="p-2"
          @mousedown="(e) => e.preventDefault()"
        >
          <a-button
            v-if="multipleCompanies"
            type="primary"
            size="small"
            style="width: 90px; margin-right: 8px"
            @click="selectAll('company')"
          >
            Todo
          </a-button>

          <a-button
            size="small"
            style="width: 90px"
            @click="clearFilters('company')"
          >
            Limpiar
          </a-button>
        </div>
      </template>
    </a-select>
  </div>
</template>

<script>
import { computed, onMounted } from 'vue';
import { useStore } from 'vuex';
import {
  FILTER_ALL,
  FETCH_SUBHOLDING,
  SUBHOLDINGS,
  SELECT_FILTER,
  SELECTS,
  ACTION_SELECTED_SUBHOLDING,
  SELECTED_SUBHOLDING,
  FETCH_COMP,
  ALL_COMPANIES,
  SELECTED_COMPANY,
  ACTION_SELECTED_COMPANY,
  GET_COMP
} from '../../store/types';

export default {
  name: 'SelectFilter',
  props: {
    multipleCompanies: {
      default: true
    }
  },
  components: {
    VNodes: (_, { attrs }) => {
      return attrs.vnodes;
    }
  },
  setup(props) {
    const store = useStore();

    onMounted(() => {
      store.dispatch(FETCH_SUBHOLDING);
      store.dispatch(FETCH_COMP);
    });

    const filter = computed(() => store.getters[FILTER_ALL]);
    const subHoldingFilter = computed(() => store.getters[SUBHOLDINGS]);
    const company = computed(() => store.getters[ALL_COMPANIES]);
    const allCompanies = computed(() => store.getters[GET_COMP]);

    const selectHolding = computed({
      get() {
        return store.getters[SELECT_FILTER];
      },
      set(value) {
        store.dispatch(SELECTS, value);
      }
    });

    const selectSubholding = computed({
      get() {
        return store.getters[SELECTED_SUBHOLDING];
      },
      set(value) {
        store.dispatch(ACTION_SELECTED_SUBHOLDING, value);
      }
    });

    const selectCompany = computed({
      get() {
        if (!props.multipleCompanies) {
          return store.getters[SELECTED_COMPANY].slice(0) || undefined;
        }
        return store.getters[SELECTED_COMPANY];
      },
      set(value) {
        store.dispatch(
          ACTION_SELECTED_COMPANY,
          props.multipleCompanies ? value : [value]
        );
      }
    });

    const clearFilters = (selector_type) => {
      switch (selector_type) {
        case 'company':
          selectCompany.value = [];
          break;
        case 'holding':
          selectHolding.value = [];
          break;
        default:
          selectSubholding.value = [];
          break;
      }
    };

    const selectAll = (selector_type) => {
      switch (selector_type) {
        case 'company':
          selectCompany.value = company.value.map((obj) => obj.value);
          break;
        case 'holding':
          selectHolding.value = filter.value.map((obj) => obj.value);
          break;
        default:
          selectSubholding.value = subHoldingFilter.value.map(
            (obj) => obj.value
          );
          break;
      }
    };

    return {
      filter,
      company,
      subHoldingFilter,
      selectSubholding,
      selectHolding,
      selectCompany,
      allCompanies,
      clearFilters,
      selectAll
    };
  }
};
</script>

<style></style>
