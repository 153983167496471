import { dashboardGraphicCompanyType } from '../../../network';
import {
  FETCH_GRAPH_DASHBOARD,
  SET_GRAPH_DASHBOARD,
  GET_GRAPH_DASHBOARD
} from './types';

const store = {};

const getDefaultState = () => ({
  dashboard_graph: []
});

store.state = getDefaultState();

store.getters = {
  [GET_GRAPH_DASHBOARD]: (state) => state.dashboard_graph
};

store.mutations = {
  [SET_GRAPH_DASHBOARD](state, payload) {
    state.dashboard_graph = payload;
  }
};

store.actions = {
  async [FETCH_GRAPH_DASHBOARD]({ commit }, payload) {
    return new Promise((resolve, reject) => {
      dashboardGraphicCompanyType(payload)
        .then((resp) => {
          commit(SET_GRAPH_DASHBOARD, resp.data);
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }
};

export default store;
