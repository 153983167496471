<template>
  <a-table
    rowKey="id"
    :columns="[
      {
        title: 'RUT',
        dataIndex: 'rut',
        key: 'rut'
      },
      {
        title: 'Nombre',
        dataIndex: 'fullname',
        key: 'fullname',
        slot: { customRender: 'fullname' }
      },
      {
        title: 'Acción',
        key: 'action',
        slots: { customRender: 'action' }
      }
    ]"
    :data-source="dependants"
    :showHeader="false"
    :pagination="false"
    :loading="loading"
  >
    <template #title>Cargas</template>
    <template
      v-if="
        user_role != 'rrhh' &&
        !ticketLoading &&
        ![TICKET.STATES.CANCELED, TICKET.STATES.EXECUTED].includes(
          ticket.state?.id
        )
      "
      #action="{ record }"
    >
      <a-tooltip placement="topLeft" title="Eliminar">
        <a-popconfirm
          title="¿Estás seguro de eliminar esta carga?"
          ok-text="Sí"
          cancel-text="No"
          @confirm="() => deleteDependant(record.id, record.ticketId)"
        >
          <a-button type="link">
            <delete-outlined class="red-icon" />
          </a-button>
        </a-popconfirm>
      </a-tooltip>
    </template>
  </a-table>
</template>

<script setup>
import {
  GET_TICKET_DEPENDANTS_INCORPORATIONS,
  GET_TICKET,
  DELETE_TICKET_DEPENDANT_INCORPORATION,
  GET_PROFILE_BY_ID
} from '@/store/types';
import TICKET from '@/constants/ticket';
import { computed } from 'vue';
import { useStore } from 'vuex';
import { DeleteOutlined } from '@ant-design/icons-vue';
import notification from '@/utils/notifications';
const store = useStore();

const ticket = computed(() => store.getters[GET_TICKET].data);
const ticketLoading = computed(() => store.getters[GET_TICKET].loading);
const dependants = computed(
  () => store.getters[GET_TICKET_DEPENDANTS_INCORPORATIONS].data
);
const loading = computed(
  () => store.getters[GET_TICKET_DEPENDANTS_INCORPORATIONS].loading
);
const user_role = computed(
  () => store.getters[GET_PROFILE_BY_ID]?.data?.body?.role?.tag || 'rrhh'
);

const deleteDependant = async (id, ticketId) => {
  try {
    await store.dispatch(DELETE_TICKET_DEPENDANT_INCORPORATION, {
      id,
      ticketId
    });
  } catch (error) {
    console.error('Error deleting dependant', error);
    console.error('Params', id);
    notification(
      'error',
      'Error',
      'No se pudo eliminar la carga. Intente de nuevo.'
    );
  }
};
</script>
