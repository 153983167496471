<template>
  <div>
    <a-skeleton
      :active="loading_component"
      :loading="loading_component"
      :paragraph="{ rows: 20 }"
    >
      <a-row :gutter="24">
        <a-col class="mb-4" :xs="24" :sm="12" :xl="8">
          <a-card :bodyStyle="'padding:18px 21px 14px 21px;'">
            <a-typography-title class="mb-0 text-gray-9">
              {{ resume_data?.requests_made ? resume_data?.requests_made : 0 }}
            </a-typography-title>
            <a-typography-title class="my-0 text-gray-8" :level="5">
              Solicitudes Cursadas
            </a-typography-title>
            <a-typography-text class="mb-3 small text-gray-8">
              Solicitudes cursadas por la Compañía de Seguro.
            </a-typography-text>
            <a-button class="mt-3" type="primary" block>
              <router-link :to="`globalstock?company=${companies_ids}`">
                Ver todas
              </router-link>
            </a-button>
          </a-card>
        </a-col>

        <a-col class="mb-4" :xs="24" :sm="12" :xl="8">
          <a-card :bodyStyle="'padding:18px 21px;'">
            <a-row>
              <a-col>
                <a-typography-text class="text-gray-8 fw-semibold">
                  Altas
                </a-typography-text>
              </a-col>
            </a-row>
            <a-row>
              <a-col>
                <a-typography-title
                  class="ff-urbanist text-gray-9 mb-0 display-3"
                >
                  {{ resume_data.altas.total ? resume_data.altas.total : 0 }}
                  <a-typography-text class="fs-xl text-gray-8">
                    Solicitudes
                  </a-typography-text>
                </a-typography-title>
              </a-col>
            </a-row>
            <a-divider class="my-3" />
            <a-row type="flex" justify="space-between" align="middle">
              <a-col style="">
                <a-typography-text class="text-gray-8 fs-xl mb-0" :level="4">
                  {{
                    resume_data.altas.received ? resume_data.altas.received : 0
                  }} </a-typography-text
                ><br />
                <a-tag color="green">Ejecutadas</a-tag>
              </a-col>
              <a-divider type="vertical" style="height: 3.9em" class="mx-0" />
              <a-col>
                <a-typography-text class="text-gray-8 fs-xl mb-0" :level="4">
                  {{
                    resume_data.altas.pendings ? resume_data.altas.pendings : 0
                  }} </a-typography-text
                ><br />
                <a-tag color="orange">En revisión</a-tag>
              </a-col>
            </a-row>
          </a-card>
        </a-col>

        <a-col class="mb-4" :xs="24" :sm="12" :xl="8">
          <a-card :bodyStyle="'padding:18px 21px;'">
            <a-row>
              <a-col>
                <a-typography-text class="text-gray-8 fw-semibold">
                  Alta Cargas
                </a-typography-text>
              </a-col>
            </a-row>
            <a-row>
              <a-col>
                <a-typography-title
                  class="ff-urbanist text-gray-9 mb-0 display-3"
                >
                  {{
                    resume_data.altaCargas.total
                      ? resume_data.altaCargas.total
                      : 0
                  }}
                  <a-typography-text class="fs-xl text-gray-8">
                    Solicitudes
                  </a-typography-text>
                </a-typography-title>
              </a-col>
            </a-row>
            <a-divider class="my-3" />
            <a-row type="flex" justify="space-between" align="middle">
              <a-col style="">
                <a-typography-text class="text-gray-8 fs-xl mb-0" :level="4">
                  {{
                    resume_data.altaCargas.received
                      ? resume_data.altaCargas.received
                      : 0
                  }} </a-typography-text
                ><br />
                <a-tag color="green">Ejecutadas</a-tag>
              </a-col>
              <a-divider type="vertical" style="height: 3.9em" class="mx-0" />
              <a-col>
                <a-typography-text class="text-gray-8 fs-xl mb-0" :level="4">
                  {{
                    resume_data.altaCargas.pendings
                      ? resume_data.altaCargas.pendings
                      : 0
                  }} </a-typography-text
                ><br />
                <a-tag color="orange">En revisión</a-tag>
              </a-col>
            </a-row>
          </a-card>
        </a-col>

        <a-col class="mb-4" :xs="24" :sm="12" :xl="8">
          <a-card :bodyStyle="'padding:18px 21px;'">
            <a-row>
              <a-col>
                <a-typography-text class="text-gray-8 fw-semibold">
                  Bajas
                </a-typography-text>
              </a-col>
            </a-row>
            <a-row>
              <a-col>
                <a-typography-title
                  class="ff-urbanist text-gray-9 mb-0 display-3"
                >
                  {{ resume_data.bajas.total ? resume_data.bajas.total : 0 }}
                  <a-typography-text class="fs-xl text-gray-8">
                    Solicitudes
                  </a-typography-text>
                </a-typography-title>
              </a-col>
            </a-row>
            <a-divider class="my-3" />
            <a-row type="flex" justify="space-between">
              <a-col style="">
                <a-typography-title
                  class="text-gray-8 fw-regular mb-0"
                  :level="4"
                >
                  {{
                    resume_data.bajas.received ? resume_data.bajas.received : 0
                  }}
                </a-typography-title>
                <a-tag color="green">Ejecutadas</a-tag>
              </a-col>
              <a-divider type="vertical" style="height: 3.9em" class="mx-0" />
              <a-col>
                <a-typography-title
                  class="text-gray-8 fw-regular mb-0"
                  :level="4"
                >
                  {{
                    resume_data.bajas.pendings ? resume_data.bajas.pendings : 0
                  }}
                </a-typography-title>
                <a-tag color="orange">En revisión</a-tag>
              </a-col>
            </a-row>
          </a-card>
        </a-col>

        <a-col class="mb-4" :xs="24" :sm="12" :xl="8">
          <a-card :bodyStyle="'padding:18px 21px;'">
            <a-row>
              <a-col>
                <a-typography-text class="text-gray-8 fw-semibold">
                  Baja Cargas
                </a-typography-text>
              </a-col>
            </a-row>
            <a-row>
              <a-col>
                <a-typography-title
                  class="ff-urbanist text-gray-9 mb-0 display-3"
                >
                  {{
                    resume_data.bajaCargas.total
                      ? resume_data.bajaCargas.total
                      : 0
                  }}
                  <a-typography-text class="fs-xl text-gray-8">
                    Solicitudes
                  </a-typography-text>
                </a-typography-title>
              </a-col>
            </a-row>
            <a-divider class="my-3" />
            <a-row type="flex" justify="space-between">
              <a-col style="">
                <a-typography-title
                  class="text-gray-8 fw-regular mb-0"
                  :level="4"
                >
                  {{
                    resume_data.bajaCargas.received
                      ? resume_data.bajaCargas.received
                      : 0
                  }}
                </a-typography-title>
                <a-tag color="green">Ejecutadas</a-tag>
              </a-col>
              <a-divider type="vertical" style="height: 3.9em" class="mx-0" />
              <a-col>
                <a-typography-title
                  class="text-gray-8 fw-regular mb-0"
                  :level="4"
                >
                  {{
                    resume_data.bajaCargas.pendings
                      ? resume_data.bajaCargas.pendings
                      : 0
                  }}
                </a-typography-title>
                <a-tag color="orange">En revisión</a-tag>
              </a-col>
            </a-row>
          </a-card>
        </a-col>

        <a-col class="mb-4" :xs="24" :sm="12" :xl="8">
          <a-card :bodyStyle="'padding:18px 21px;'">
            <a-row>
              <a-col>
                <a-typography-text class="text-gray-8 fw-semibold"
                  >Modificaciones</a-typography-text
                >
              </a-col>
            </a-row>
            <a-row>
              <a-col>
                <a-typography-title
                  class="ff-urbanist text-gray-9 mb-0 display-3"
                >
                  {{
                    resume_data.modificaciones.total
                      ? resume_data.modificaciones.total
                      : 0
                  }}
                  <a-typography-text class="fs-xl text-gray-8">
                    Solicitudes
                  </a-typography-text>
                </a-typography-title>
              </a-col>
            </a-row>
            <a-divider class="my-3" />
            <a-row type="flex" justify="space-between">
              <a-col style="">
                <a-typography-title
                  class="text-gray-8 fw-regular mb-0"
                  :level="4"
                >
                  {{
                    resume_data.modificaciones.received
                      ? resume_data.modificaciones.received
                      : 0
                  }}
                </a-typography-title>
                <a-tag color="green">Ejecutadas</a-tag>
              </a-col>
              <a-divider type="vertical" style="height: 3.9em" class="mx-0" />
              <a-col>
                <a-typography-title
                  class="text-gray-8 fw-regular mb-0"
                  :level="4"
                >
                  {{
                    resume_data.modificaciones.pendings
                      ? resume_data.modificaciones.pendings
                      : 0
                  }}
                </a-typography-title>
                <a-tag color="orange">En revisión</a-tag>
              </a-col>
            </a-row>
          </a-card>
        </a-col>
      </a-row>

      <a-row>
        <a-col :lg="24">
          <a-row>
            <a-col :lg="24">
              <a-card>
                <a-row type="flex" justify="space-between" align="middle">
                  <a-col class="mb-4" :lg="8">
                    <a-typography-title
                      class="text-gray-8 ff-urbanist"
                      :level="3"
                      >Casos</a-typography-title
                    >
                    <a-typography-text class="mb-4 text-gray-8"
                      >Cantidad total de casos por rechazos, falta de
                      antecedentes y fuera de tiempo.</a-typography-text
                    >
                  </a-col>
                  <a-col :xs="24" :lg="16">
                    <a-row type="flex" justify="end" :gutter="8">
                      <a-col>
                        <a-card
                          :style="'min-width:192px;'"
                          :bodyStyle="'padding:18px 21px;'"
                        >
                          <a-row type="flex" align="top">
                            <a-col>
                              <a-typography-text
                                class="text-gray-8 fw-semibold"
                              >
                                Rechazos
                              </a-typography-text>
                            </a-col>
                          </a-row>
                          <a-row>
                            <a-col>
                              <a-typography-title
                                class="ff-urbanist text-gray-9 mb-0"
                              >
                                {{
                                  pending_cases.rechazo
                                    ? pending_cases.rechazo
                                    : 0
                                }}
                                <a-typography-text class="fs-xl text-gray-8">
                                  Casos
                                </a-typography-text>
                              </a-typography-title>
                            </a-col>
                          </a-row>
                        </a-card>
                      </a-col>
                      <a-col>
                        <a-card
                          :style="'min-width:192px;'"
                          :bodyStyle="'padding:18px 21px;'"
                        >
                          <a-row type="flex" align="top">
                            <a-col>
                              <a-typography-text
                                class="text-gray-8 fw-semibold"
                              >
                                Antecedentes
                              </a-typography-text>
                            </a-col>
                          </a-row>
                          <a-row>
                            <a-col>
                              <a-typography-title
                                class="ff-urbanist text-gray-9 mb-0"
                              >
                                {{
                                  pending_cases.antecedentes
                                    ? pending_cases.antecedentes
                                    : 0
                                }}
                                <a-typography-text class="fs-xl text-gray-8">
                                  Casos
                                </a-typography-text>
                              </a-typography-title>
                            </a-col>
                          </a-row>
                        </a-card>
                      </a-col>
                      <a-col>
                        <a-card
                          :style="'min-width:192px;'"
                          :bodyStyle="'padding:18px 21px;'"
                        >
                          <a-row type="flex" align="top">
                            <a-col>
                              <a-typography-text
                                class="text-gray-8 fw-semibold"
                              >
                                Fuera de tiempo
                              </a-typography-text>
                            </a-col>
                          </a-row>
                          <a-row>
                            <a-col>
                              <a-typography-title
                                class="ff-urbanist text-gray-9 mb-0"
                              >
                                {{
                                  pending_cases.outTime
                                    ? pending_cases.outTime
                                    : 0
                                }}
                                <a-typography-text class="fs-xl text-gray-8">
                                  Casos
                                </a-typography-text>
                              </a-typography-title>
                            </a-col>
                          </a-row>
                        </a-card>
                      </a-col>
                    </a-row>
                  </a-col>
                </a-row>
              </a-card>
            </a-col>
          </a-row>

          <a-row>
            <a-col :lg="24">
              <a-row
                class="mt-3"
                type="flex"
                justify="space-between"
                align="bottom"
              >
                <a-col class="mb-4">
                  <a-typography-title class="text-gray-8 mb-0" :level="3">
                    Solicitudes por mes
                  </a-typography-title>
                  <a-typography-text class="mb-4 text-gray-8">
                    Número de solcitudes cursadas cada mes.
                  </a-typography-text>
                </a-col>
                <a-col>
                  <a-radio-group
                    v-model:value="request_type"
                    size="small"
                    style="max-width: 320px !important"
                    @change="handleChange"
                  >
                    <a-radio-button :value="1">Altas</a-radio-button>
                    <a-radio-button :value="2">Bajas</a-radio-button>
                    <a-radio-button :value="3">Modificaciones </a-radio-button>
                  </a-radio-group>
                </a-col>
              </a-row>

              <div>
                <apexchart
                  ref="chart_ref"
                  type="area"
                  height="250"
                  :options="chart_options"
                  :series="series"
                >
                </apexchart>
              </div>
            </a-col>
          </a-row>
        </a-col>
      </a-row>
    </a-skeleton>
  </div>
</template>

<script setup>
// Importes
import { useStore } from 'vuex';
import { computed, onMounted, ref, watch } from 'vue';
import notification from '../../../../utils/notifications';
import {
  GET_ALL_DATA_DASHBOARD,
  SELECTED_COMPANY,
  ALL_COMPANIES,
  SELECT_FILTER,
  FETCH_GRAPH_DASHBOARD,
  GET_GRAPH_DASHBOARD
} from '@/store/types';

// Variables
const store = useStore();
const loading_component = ref(true);
const request_type = ref(1);
const companies_ids = computed(() => store.getters[SELECTED_COMPANY].join(','));
const chart_ref = ref(null);

const companies_selected = computed(() => {
  const selected_holding = store.getters[SELECT_FILTER].map((value) =>
    parseInt(value)
  );
  const selected_comp = store.getters[SELECTED_COMPANY].map((value) =>
    parseInt(value)
  );
  const all_companies = store.getters[ALL_COMPANIES].map(({ value }) =>
    parseInt(value)
  );

  if (selected_holding.length > 0) {
    if (selected_comp.length > 0) {
      return selected_comp;
    }
    return all_companies;
  }

  if (selected_comp.length > 0 && selected_comp.length < all_companies.length) {
    return selected_comp;
  }

  return [];
});

const resume_data = computed(() => {
  const { estadoTotal, alta, altaCargas, baja, bajaCargas, modificacion } =
    store.getters[GET_ALL_DATA_DASHBOARD];
  return {
    requests_made:
      estadoTotal?.createds + estadoTotal?.pendings + estadoTotal?.executeds,
    altas: {
      received: alta?.executeds,
      pendings: alta?.pendings + alta?.createds,
      total: alta?.executeds + alta?.pendings + alta?.createds
    },
    altaCargas: {
      received: altaCargas?.executeds,
      pendings: altaCargas?.pendings + altaCargas?.createds,
      total: altaCargas?.executeds + altaCargas?.pendings + altaCargas?.createds
    },
    bajas: {
      received: baja?.executeds,
      pendings: baja?.pendings + baja?.createds,
      total: baja?.executeds + baja?.pendings + baja?.createds
    },
    bajaCargas: {
      received: bajaCargas?.executeds,
      pendings: bajaCargas?.pendings + bajaCargas?.createds,
      total: bajaCargas?.executeds + bajaCargas?.pendings + bajaCargas?.createds
    },
    modificaciones: {
      received: modificacion?.executeds,
      pendings: modificacion?.pendings + modificacion?.createds,
      total:
        modificacion?.executeds +
        modificacion?.pendings +
        modificacion?.createds
    }
  };
});

const series = ref([
  {
    name: 'Solicitudes',
    data: []
  }
]);

const chart_options = ref({
  legend: {
    show: false
  },
  chart: {
    toolbar: {
      show: false
    },
    width: '100%',
    height: '250',
    type: 'area'
  },
  dataLabels: {
    enabled: false
  },
  stroke: {
    curve: 'smooth',
    width: 0.8
  },
  xaxis: {
    type: 'category',
    labels: {
      style: {
        fontFamily: 'Inter',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '12px',
        lineHeight: '20px'
      }
    }
  },
  yaxis: [
    {
      labels: {
        formatter(val) {
          return val.toFixed(0);
        }
      }
    }
  ]
});

watch(
  () => store.getters[GET_GRAPH_DASHBOARD],
  (data) => {
    series.value[0].data = data.counts;
    chart_options.value.xaxis.categories = data.months;
  }
);

const pending_cases = computed(() => {
  const { cases } = store.getters[GET_ALL_DATA_DASHBOARD];
  return {
    antecedentes: cases === undefined ? 0 : cases.antecedentes.pendings,
    rechazo: cases === undefined ? 0 : cases?.rechazo?.pendings,
    outTime: cases === undefined ? 0 : cases?.outTime?.pendings
  };
});

watch(companies_selected, () => {
  getGraphDashboardData(request_type.value);
});

const getGraphDashboardData = async (type = 1) => {
  try {
    const obj = { type };

    if (companies_selected.value.length > 0) {
      obj.company = companies_selected.value.join(',');
    }

    let params = '';
    Object.keys(obj).forEach((key, index) => {
      if (index === 0) {
        params += `?${key}=${obj[key]}`;
      } else {
        params += `&${key}=${obj[key]}`;
      }
    });

    await store.dispatch(FETCH_GRAPH_DASHBOARD, params);
  } catch (error) {
    notification('error', 'Error', error.response.data.message);
  }
  loading_component.value = false;
};

const handleChange = (value) => {
  getGraphDashboardData(parseInt(value.target.value));
};

onMounted(() => {
  getGraphDashboardData();
});
</script>
