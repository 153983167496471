<template>
  <div>
    <page-header
      :breadcrumRoutes="routes"
      title="Formulario alta de cargas individual"
    />

    <a-form
      name="form"
      layout="vertical"
      ref="form_ref"
      :model="form_data"
      :rules="form_rules"
    >
      <a-row type="flex" class="mb-2">
        <a-col flex="auto">
          <a-card>
            <a-row>
              <a-form-item
                label="Envío de información a"
                required
                name="typeform"
              >
                <a-radio-group
                  name="radioGroup"
                  v-model:value="form_data.typeform"
                >
                  <a-radio value="1">Colaborador</a-radio>
                  <a-radio value="2">Compañía</a-radio>
                </a-radio-group>
              </a-form-item>
            </a-row>
          </a-card>
        </a-col>
        <a-col flex="10px"></a-col>
        <a-col flex="auto">
          <a-card>
            <a-row>
              <a-form-item
                label="Inicio de vigencia"
                required
                name="validity_date"
              >
                <a-date-picker
                  placeholder="DD-MM-AAAA"
                  v-model:value="form_data.validity_date"
                  :format="format_date"
                />
              </a-form-item>
            </a-row>
          </a-card>
        </a-col>
      </a-row>

      <a-card>
        <a-row>
          <a-col>
            <a-typography-title class="text-gray-8" :level="4">
              Datos titular
            </a-typography-title>
          </a-col>
        </a-row>
        <a-row :gutter="16" type="flex">
          <a-col :span="8">
            <a-form-item ref="rut" label="RUT" name="rut" has-feedback>
              <a-input
                placeholder="RUT"
                maxLength="12"
                v-model:value="form_data.rut"
                @keyup="formatRuts"
                :loading="loadingCompanies"
              />
            </a-form-item>
          </a-col>
        </a-row>
      </a-card>

      <a-card class="mt-3">
        <a-row>
          <a-col>
            <a-typography-title class="text-gray-8" :level="4">
              Datos corporativos
            </a-typography-title>
          </a-col>
        </a-row>
        <a-row :gutter="16" type="flex">
          <a-col :span="8">
            <!-- Empresa -->
            <a-form-item
              ref="company"
              label="Empresa"
              name="company"
              has-feedback
            >
              <a-select
                :disabled="inputs_lock_state[0].disabled"
                placeholder="company"
                show-search
                optionFilterProp="label"
                v-model:value="form_data.company"
                :options="companies"
              >
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <!-- Grupo -->
            <a-form-item ref="group" label="Grupo" name="group" has-feedback>
              <a-select
                :disabled="inputs_lock_state[1].disabled"
                :loading="inputs_lock_state[1].loading"
                placeholder="group"
                v-model:value="form_data.group"
                :options="group_companies"
              >
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <!-- Aseguradora -->
            <a-form-item
              ref="insurance"
              label="Aseguradora"
              name="insurance"
              has-feedback
            >
              <a-select
                :disabled="inputs_lock_state[2].disabled"
                :loading="inputs_lock_state[2].loading"
                placeholder="insurance"
                v-model:value="form_data.insurance"
                :options="insurance_companies"
              >
              </a-select>
            </a-form-item>
          </a-col>
        </a-row>
      </a-card>

      <a-card class="mt-3">
        <a-typography-title class="text-gray-8" :level="4">
          Pólizas y coberturas
        </a-typography-title>
        <a-row :gutter="16">
          <a-col :span="8">
            <!-- Numero de poliza -->
            <a-form-item
              ref="policies"
              label="Pólizas"
              name="policies"
              has-feedback
            >
              <a-select
                :disabled="inputs_lock_state[3].disabled"
                :loading="inputs_lock_state[3].loading"
                v-model:value="form_data.policies"
                :options="companyPolicies"
                mode="multiple"
                allowClear
              >
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <template
              v-if="form_data.typeform == 1 && form_data.policies.length > 0"
            >
              <a-form-item
                name="documents"
                :label="
                  insurance_companies.find(
                    (x) => x.value == form_data.insurance
                  )?.label
                "
              >
                <!-- Documentos -->
                <a-checkbox-group v-model:value="form_data.documents">
                  <div v-for="d in insurance_documents" :key="d.value">
                    <a-checkbox :value="d.value">
                      {{ d.label }}
                    </a-checkbox>
                  </div>
                </a-checkbox-group>
              </a-form-item>
            </template>
            <template
              v-if="
                form_data.typeform == 2 &&
                form_data.insurance !== null &&
                form_data.insurance !== ''
              "
            >
              <a-row :gutter="[24, 8]" type="flex">
                <a-col>
                  <!-- Subida de archivo -->
                  <a-form-item
                    name="file_list"
                    :label="
                      insurance_companies.find(
                        (x) => x.value == form_data.insurance
                      )?.label
                    "
                    has-feedback
                  >
                    <a-upload
                      :file-list="form_data.file_list"
                      :multiple="true"
                      :remove="handleRemove"
                      :before-upload="beforeUpload"
                      accept=".pdf,.jpeg,.png,.jpg"
                    >
                      <a-button>
                        <upload-outlined></upload-outlined>
                        Subir archivo
                      </a-button>
                    </a-upload>
                  </a-form-item>
                </a-col>
              </a-row>
            </template>
          </a-col>
          <a-col :span="8">
            <template
              v-if="
                valid_rut &&
                form_data.rut !== '' &&
                employee_id !== '' &&
                form_data.company !== '' &&
                form_data.insurance !== ''
              "
            >
              <a-button
                class="button_with_icon"
                @click="openPolicyCoverage"
                id="coverages_and_policies"
              >
                Pólizas y coberturas
                <a-tag
                  color="geekblue"
                  v-if="current_coverages.length > 0"
                  class="ml-2 mr-0"
                  >{{ current_coverages.length }}</a-tag
                >
              </a-button>
              <policy-coverages
                ref="policy_coverages_modal_ref"
                v-model="policy_status"
                :loading="loading_policy_coverages"
                :currentCoverages="current_coverages"
                :notCurrentCoverages="not_current_coverages"
              />
            </template>
          </a-col>
        </a-row>
      </a-card>

      <Transition>
        <a-card class="mt-2" v-if="form_data.typeform == 1">
          <a-row :gutter="32">
            <a-col :span="12">
              <a-typography-title class="text-gray-8" :level="4">
                Mensaje a colaborador
              </a-typography-title>
              <a-form-item
                ref="message"
                label="Escribe mensaje para colaborador"
                name="message"
                has-feedback
              >
                <a-textarea
                  v-model:value="form_data.message"
                  placeholder="Escribe aquí..."
                  :rows="8"
                  :maxlength="3000"
                />
              </a-form-item>
            </a-col>
            <a-col :span="12">
              <a-row :gutter="[24, 8]" type="flex">
                <a-form-item name="optional_files" label="Archivos opcionales">
                  <a-upload
                    :file-list="form_data.optional_files"
                    :multiple="true"
                    :remove="handleRemoveOptional"
                    :before-upload="beforeUploadOptional"
                    accept=".pdf,.doc,.docx,.xls,.xlsx,.ppt,.pptx"
                  >
                    <a-button>
                      <upload-outlined></upload-outlined>
                      Subir Archivo
                    </a-button>
                  </a-upload>
                </a-form-item>
              </a-row>
            </a-col>
          </a-row>
        </a-card>
      </Transition>

      <a-card v-if="form_data.typeform == 2" class="mt-3">
        <a-typography-title class="text-gray-8" :level="4">
          Cargas
        </a-typography-title>
        <div>
          <a-empty
            v-if="form_data.dependents.length === 0"
            description="No has agregado cargas"
          />
          <template
            v-for="(dependent, dependentIndex) in form_data.dependents"
            :key="dependentIndex"
          >
            <a-row type="flex" justify="space-between" align="middle">
              <a-col>
                <a-typography-title :level="5" style="margin: 0"
                  >Carga {{ dependentIndex + 1 }}</a-typography-title
                >
              </a-col>
              <a-col>
                <a-button
                  danger
                  size="small"
                  @click="() => handleDeleteDependent(dependentIndex)"
                  >Eliminar</a-button
                >
              </a-col>
            </a-row>
            <a-row type="flex" :gutter="16" style="margin-top: 16px">
              <a-col :span="12">
                <a-form-item
                  :name="['dependents', dependentIndex, 'rut']"
                  label="Rut"
                  :rules="[
                    { validator: rutValidator },
                    { required: true, message: 'El rut es obligatorio.' }
                  ]"
                >
                  <a-input
                    :value="dependent.rut"
                    @change="
                      (e) =>
                        (dependent.rut = rutHelper.formatRut(e.target.value))
                    "
                  />
                </a-form-item>
              </a-col>
              <a-col :span="12">
                <a-form-item
                  :name="['dependents', dependentIndex, 'dateOfBirth']"
                  label="Fecha de nacimiento"
                  :rules="{
                    required: true,
                    validator: checkDate,
                    trigger: 'change'
                  }"
                >
                  <a-input v-model:value="dependent.dateOfBirth" />
                </a-form-item>
              </a-col>
            </a-row>
            <a-row type="flex" :gutter="16">
              <a-col :span="12">
                <a-form-item
                  :name="['dependents', dependentIndex, 'firstName']"
                  label="Primer nombre"
                  :rules="{
                    required: true,
                    message: 'El primer nombre es obligatorio.'
                  }"
                >
                  <a-input v-model:value="dependent.firstName" />
                </a-form-item>
              </a-col>
              <a-col :span="12">
                <a-form-item
                  :name="['dependents', dependentIndex, 'secondName']"
                  label="Segundo nombre"
                >
                  <a-input v-model:value="dependent.secondName" />
                </a-form-item>
              </a-col>
            </a-row>
            <a-row type="flex" :gutter="16">
              <a-col :span="12">
                <a-form-item
                  :name="['dependents', dependentIndex, 'firstSurname']"
                  label="Apellido paterno"
                  :rules="{
                    required: true,
                    message: 'El primer apellido es obligatorio.'
                  }"
                >
                  <a-input v-model:value="dependent.firstSurname" />
                </a-form-item>
              </a-col>
              <a-col :span="12">
                <a-form-item
                  :name="['dependents', dependentIndex, 'secondSurname']"
                  label="Apellido materno"
                >
                  <a-input v-model:value="dependent.secondSurname" />
                </a-form-item>
              </a-col>
              <a-col :span="12">
                <a-form-item
                  :name="['dependents', dependentIndex, 'relationship']"
                  label="Relacion"
                  :rules="{
                    required: true,
                    message: 'La relacion es obligatoria.'
                  }"
                >
                  <a-select
                    v-model:value="dependent.relationship"
                    :options="relationOptions"
                    placeholder="Ingrese relación"
                  >
                  </a-select>
                </a-form-item>
              </a-col>
            </a-row>
          </template>
        </div>
        <a-row type="flex" justify="start">
          <a-button @click="handleAddDependent">Agregar carga</a-button>
        </a-row>
      </a-card>

      <a-form-item class="mt-2">
        <a-row type="flex" class="justify-content-end">
          <a-col>
            <a-button
              size="large"
              class="px-4"
              type="primary"
              @click="onSubmitForm"
              block
              >Siguiente
            </a-button>
          </a-col>
        </a-row>
      </a-form-item>
    </a-form>

    <!-- Modal mostrando informacion de lo que escribio el usuario -->
    <a-modal
      v-model:visible="resume_modal.visibility"
      title="Resumen de alta de cargas individual"
      @cancel="closeResumeModal"
      :closable="!resume_modal.boton_loading"
      :maskClosable="false"
    >
      <template #footer>
        <a-row type="flex" justify="end">
          <a-col>
            <a-space>
              <a-button
                @click="closeResumeModal"
                :disabled="resume_modal.boton_loading"
              >
                Cancelar
              </a-button>
              <a-button
                key="submit"
                type="primary"
                @click="createAlta"
                :loading="resume_modal.boton_loading"
              >
                Enviar
              </a-button>
            </a-space>
          </a-col>
        </a-row>
      </template>

      <a-typography-title class="text-gray-8 fw-regular mb-4" :level="5">
        Revisa que los datos ingresados esten correctos.
      </a-typography-title>
      <a-descriptions class="mb-4" bordered>
        <a-descriptions-item label="Inicio de vigencia" :span="3">
          {{ resume_modal.data.date }}
        </a-descriptions-item>
      </a-descriptions>
      <a-descriptions class="mb-4" title="Datos corporativos" bordered>
        <a-descriptions-item label="Aseguradora" :span="3">
          {{ resume_modal.data.insurer }}
        </a-descriptions-item>
        <a-descriptions-item label="Empresa" :span="3">
          {{ resume_modal.data.company }}
        </a-descriptions-item>
        <a-descriptions-item label="Grupo" :span="3">
          {{ resume_modal.data.group }}
        </a-descriptions-item>
        <a-descriptions-item label="Pólizas" :span="3">
          {{ resume_modal.data.policies }}
        </a-descriptions-item>
        <a-descriptions-item
          label="Formularios"
          :span="3"
          v-if="
            form_data.typeform == 1 && resume_modal.data.documents.length > 0
          "
        >
          <ul style="margin: 0; padding: 0 0 0 8px">
            <li
              v-for="(d, index_d) in resume_modal.data.documents"
              :key="index_d"
            >
              {{ d }}
            </li>
          </ul>
        </a-descriptions-item>
        <a-descriptions-item
          label="Formularios"
          :span="3"
          v-else-if="resume_modal.data.files.length > 0"
        >
          <ul style="margin: 0; padding: 0 0 0 8px">
            <li v-for="(d, index_f) in resume_modal.data.files" :key="index_f">
              {{ d }}
            </li>
          </ul>
        </a-descriptions-item>
      </a-descriptions>
      <a-descriptions title="Datos colaborador" class="mb-4" bordered>
        <a-descriptions-item label="RUT" :span="3">
          {{ resume_modal.data.rut }}
        </a-descriptions-item>
      </a-descriptions>
      <a-descriptions
        :title="`Carga ${dependentIndex + 1}`"
        class="mb-4"
        v-for="(dependent, dependentIndex) in resume_modal.data.dependents"
        :key="dependent.rut"
        bordered
      >
        <a-descriptions-item label="RUT" :span="3">
          {{ dependent.rut }}
        </a-descriptions-item>
        <a-descriptions-item label="Nombre" :span="3">
          {{ dependent.firstName }} {{ dependent.firstSurname }}
        </a-descriptions-item>
      </a-descriptions>
    </a-modal>

    <!-- Modal sucess -->
    <template v-if="show_sucess">
      <a-modal
        v-model:visible="show_sucess"
        centered
        footer=""
        :maskClosable="false"
      >
        <a-result
          centered
          status="success"
          title="Ticket creado"
          sub-title="Tu ticket de alta de cargas individual ha sido creado con éxito."
        >
          <template #extra>
            <router-link
              class="justify-content-center d-flex"
              :to="{ name: 'AltaCargasDetalle', params: { id: ticket_id } }"
            >
              <a-button key="console" type="primary">Ver ticket</a-button>
            </router-link>
          </template>
        </a-result>
      </a-modal>
    </template>
    <template v-if="showError">
      <a-modal
        v-model:visible="showError"
        centered
        footer=""
        :maskClosable="false"
      >
        <a-result
          centered
          status="error"
          title="Error"
          sub-title="No se ha podido generar el ticket. Asegúrate de que exista un Ticket de Alta generado previamente."
        >
          <template #extra>
            <a-button type="primary" @click="() => handleOk()">Cerrar</a-button>
          </template>
        </a-result>
      </a-modal>
    </template>
  </div>
</template>

<script>
import { computed, reactive, ref, watch } from 'vue';
import { message } from 'ant-design-vue';
import { UploadOutlined } from '@ant-design/icons-vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import moment from 'moment';
import {
  FETCH_GROUP_COMPANIES,
  FETCH_INSURANCE_COMPANIES,
  FETCH_INSURANCE_COMPANIES_DOCUMENTS,
  FETCH_EMPLOYEE_DATA_BY_RUT,
  FETCH_CURRENT_EMPLOYEE_COVERAGE,
  GET_GROUP_COMPANIES,
  GET_INSURANCE_COMPANIES,
  GET_BROKER_ID,
  GET_INSURANCE_COMPANIES_DOCUMENTS,
  GET_EMPLOYEE_DATA,
  POST_EMPLOYEE_DEPENDENTS_INCORPORATION,
  POST_INSURANCE_DEPENDENTS_INCORPORATION,
  ACTION_CURRENT_EMPLOYEE_COVERAGE,
  FETCH_COMPANIES_FOR_DEPENDENTS_INCORPORATION_FORM,
  FETCH_POLICIES_FOR_DEPENDENTS_INCORPORATION_FORM
} from '@/store/types';
import parseDate from '@/utils/dateFormatter';
import PageHeader from '@/components/PageHeader.vue';
import rutHelper from '@/utils/Ruts';
import notification from '@/utils/notifications';
import Rut from '@/utils/Ruts';
import EmployeePolicyAndCoverages from '@/components/altas/EmployeePolicyAndCoverages.vue';

const routes = [
  {
    path: '/altas-cargas',
    breadcrumbName: 'Altas cargas'
  },
  {
    breadcrumbName: 'Formulario individual'
  }
];

export default {
  name: 'AltaCargasIndividual',
  components: {
    'upload-outlined': UploadOutlined,
    'page-header': PageHeader,
    'policy-coverages': EmployeePolicyAndCoverages
  },
  setup() {
    const router = useRouter();
    // * Form
    // Referencia al formulario
    const form_ref = ref();

    // Id final cuando se cree el ticket
    const ticket_id = ref('');

    // Formato de fecha
    const format_date = 'DD-MM-YYYY';

    const handleAddDependent = () => {
      form_data.dependents.push({
        rut: '',
        firstName: '',
        secondName: '',
        firstSurname: '',
        secondSurname: '',
        dateOfBirth: null
      });
    };

    const handleDeleteDependent = (dependentIndex) => {
      form_data.dependents = form_data.dependents.filter(
        (_, idx) => idx !== dependentIndex
      );
    };

    const rutValidator = (_, value) => {
      const isValid = rutHelper.validateRut(value);
      if (value && !isValid) return Promise.reject('RUT inválido.');

      if (form_data.dependents.filter((d) => d.rut === value).length > 1)
        return Promise.reject('RUT repetido.');

      return Promise.resolve();
    };

    // Data que se guarda del formulario
    const form_data = reactive({
      typeform: '1',
      validity_date: '',
      rut: '',
      company: '',
      group: '',
      insurance: '',
      message:
        'Para que tus cargas puedan recibir los beneficios de tu empresa es necesario que completes los siguientes pasos:',
      policies: [],
      documents: [],
      file_list: [],
      optional_files: [],
      dependents: []
    });
    const formatRuts = () => {
      form_data.rut = rutHelper.formatRut(form_data.rut);
    };
    const showSucess = ref(false);
    const options1 = ref([
      {
        value: 'lucy',
        label: 'Lucy'
      }
    ]);

    const employee_id = ref('');

    const inputs_lock_state = reactive([
      {
        id: 1,
        disabled: false,
        loading: false,
        name: 'company'
      },
      {
        id: 2,
        disabled: true,
        loading: false,
        name: 'group'
      },
      {
        id: 3,
        disabled: true,
        loading: false,
        name: 'insurance'
      },
      {
        id: 4,
        disabled: true,
        loading: false,
        name: 'policy'
      }
    ]);

    const inputStatesHeriarchy = (id) => {
      /* funcion para activar y desactivar inputs dependiendo de la jerarquia */
      const index = inputs_lock_state.findIndex((obj) => obj.id === id);
      let last_id;

      if (index >= 0) {
        inputs_lock_state[index].disabled = false;

        if (inputs_lock_state[index + 1] != undefined) {
          inputs_lock_state[index + 1].disabled = false;
          last_id = inputs_lock_state[index + 1].id;
        }
      }

      for (let index = last_id; index < inputs_lock_state.length; index++) {
        inputs_lock_state[index].disabled = true;
      }
    };

    // Validador de rut bien escrito
    const checkRut = async (_, value) => {
      if (!value) {
        return Promise.reject('Ingrese RUT');
      }
      if (!rutHelper.validateRut(value)) {
        return Promise.reject('RUT no válido');
      }
      if (!employee_id.value && !loadingCompanies.value) {
        return Promise.reject('No se encontró al titular');
      }
      return Promise.resolve();
    };

    // Reglas del formulario
    const form_rules = {
      validity_date: [
        {
          required: true,
          message: 'Seleccione una fecha',
          trigger: 'change',
          type: 'object'
        }
      ],
      rut: [
        {
          required: true,
          validator: checkRut,
          trigger: 'change'
        }
      ],
      company: [
        {
          required: true,
          message: 'Seleccione una empresa',
          trigger: 'change',
          type: 'string'
        }
      ],
      group: [
        {
          required: true,
          message: 'Seleccione un grupo',
          trigger: 'change',
          type: 'string'
        }
      ],
      insurance: [
        {
          required: true,
          message: 'Seleccione una aseguradora',
          trigger: 'change',
          type: 'string'
        }
      ],
      message: [
        {
          required: true,
          message: 'Escriba un mensaje',
          trigger: 'change',
          type: 'string'
        }
      ],
      policies: [
        {
          type: 'array',
          required: true,
          message: 'Seleccione una póliza',
          trigger: 'change'
        }
      ],
      documents: [
        {
          type: 'array',
          required: true,
          message: 'Seleccione un documento',
          trigger: 'change'
        }
      ],
      file_list: [
        {
          type: 'array',
          required: true,
          message: 'Agregue un documento',
          trigger: 'change'
        }
      ]
    };

    // cuando se seleccione 'Envio de informacion a', dependiendo de que se escoge, dejamos el otro valor en vacio
    watch(
      () => form_data.typeform,
      (newVal) => {
        // Si selecciono Colaborador, dejo vacio los archivos que se suben
        if (newVal === '1') {
          form_data.file_list = [];
        } else {
          // De lo contrario los documentos selecciionables como checkbox los dejo vacio
          form_data.documents = [];
        }
      }
    );

    // Presionar el boton de siguiente
    const onSubmitForm = async () => {
      try {
        const resp = await form_ref.value.validate();
        openResumeModal(resp);
      } catch (error) {
        console.log('error', error);
        form_ref.value.scrollToField(error.errorFields[0].name[0]);
        notification('error', 'Error', 'Hay errores en el formulario.');
      }
    };

    // Crear ticket de alta a compañia
    const createAlta = async () => {
      resume_modal.boton_loading = true;

      try {
        const formData = new FormData();
        formData.append('companyId', form_data.company);
        formData.append('brokerId', store.getters[GET_BROKER_ID]);
        formData.append('insuranceCompanyId', form_data.insurance);
        formData.append('startDate', moment(form_data.validity_date).format());
        formData.append('groupCompanyId', form_data.group);
        formData.append('rut', rutHelper.normalizeRut(form_data.rut));
        form_data.documents.forEach((e, i) => {
          formData.append(`filesInsurance[${i}]`, e);
        });
        form_data.policies.forEach((e, i) => {
          formData.append(`policies[${i}]`, e);
        });

        if (form_data.typeform == 2) {
          form_data.file_list.forEach((file) => {
            formData.append('file', file);
          });
          form_data.dependents.forEach((d, i) => {
            const dateOfBirth = moment(
              d.dateOfBirth.replace(/[-*]/g, '/'),
              'DD/MM/YYYY'
            );
            formData.append(
              `dependents[${i}]`,
              JSON.stringify({
                ...d,
                rut: rutHelper.normalizeRut(d.rut),
                dateOfBirth
              })
            );
          });

          const resp = await store.dispatch(
            POST_INSURANCE_DEPENDENTS_INCORPORATION,
            formData
          );
          ticket_id.value = resp.id;
          show_sucess.value = true;
        } else {
          formData.append('message', form_data.message);
          form_data.optional_files.forEach((e) => {
            formData.append('file', e);
          });

          const resp = await store.dispatch(
            POST_EMPLOYEE_DEPENDENTS_INCORPORATION,
            formData
          );
          ticket_id.value = resp.id;
          showSucess.value = true;
          show_sucess.value = true;
        }
        form_ref.value.resetFields();
      } catch (error) {
        showSucess.value = false;
        notification('error', 'Error', error.response.data.message);
      }

      resume_modal.boton_loading = false;
      closeResumeModal();
    };

    // * Subida de archivos
    const file_list = ref([]);
    const uploading = ref(false);

    // Eliminar un archivo de la lista de archivos
    const handleRemove = (file) => {
      const index = form_data.file_list.indexOf(file);
      const newFileList = form_data.file_list.slice();
      newFileList.splice(index, 1);
      form_data.file_list = newFileList;
    };

    // Eliminar un archivo para la subida de archivos opcionales
    const handleRemoveOptional = (file) => {
      const index = form_data.optional_files.indexOf(file);
      const newFileList = form_data.optional_files.slice();
      newFileList.splice(index, 1);
      form_data.optional_files = newFileList;
    };

    // Agregar un archivo opcional al input
    const beforeUploadOptional = (file) => {
      if (form_data.optional_files.length < 5) {
        const fileSize = file.size / 1024 / 1024;
        if (fileSize > 5) {
          message.error('El archivo excede el peso máximo 5MB');
        } else {
          form_data.optional_files = [...form_data.optional_files, file];
        }
      } else {
        message.error('Máximo archivos a subir es 5');
      }
      return false;
    };

    // Metodo que se llama para subir al archivo, sirve para validar el peso del archivo
    const beforeUpload = (file) => {
      const fileSize = file.size / 1024 / 1024;
      if (fileSize > 5) {
        message.error('El archivo excede el peso máximo 5MB');
      } else {
        form_data.file_list = [...form_data.file_list, file];
      }
      return false;
    };

    // Subida de archivos al backend
    const handleUpload = async (id) => {
      const formData = new FormData();
      file_list.value.forEach((file) => {
        formData.append('file', file);
      });
      formData.append('ticketId', id);
    };

    // * Obtencion de data principal
    // Vuex
    const store = useStore();

    const companies = ref([]);
    const loadingCompanies = ref(false);
    const getCompanies = async (rut) => {
      loadingCompanies.value = true;
      try {
        const response = await store.dispatch(
          FETCH_COMPANIES_FOR_DEPENDENTS_INCORPORATION_FORM,
          rutHelper.normalizeRut(rut)
        );
        companies.value = response.map((c) => ({
          label: c.businessName,
          value: String(c.id)
        }));
      } catch (error) {
        notification('error', 'Error', error.response.data.message);
      }
      loadingCompanies.value = false;
    };

    const getGroupCompanies = async (id, rut) => {
      inputs_lock_state[1].disabled = true;
      inputs_lock_state[1].loading = true;
      try {
        await store.dispatch(
          FETCH_GROUP_COMPANIES,
          `?company=${id}&rut=${rut}&policyStatus=Vigente`
        );
      } catch (error) {
        notification('error', 'Error', error.response.data.message);
      }
      inputs_lock_state[1].loading = false;
      inputStatesHeriarchy(1);
    };

    // Obteniendo Aseguradoras
    const getInsuranceCompanies = async (id_company, id_group) => {
      inputs_lock_state[2].disabled = true;
      inputs_lock_state[2].loading = true;
      try {
        await store.dispatch(FETCH_INSURANCE_COMPANIES, {
          id_company,
          id_group
        });
      } catch (error) {
        notification('error', 'Error', error.response.data.message);
      }
      inputs_lock_state[2].loading = false;
      inputStatesHeriarchy(2);
    };

    // Obteniendo documentos por compa;ia aseguradora
    const getInsuraceDocuments = async (id_insurance) => {
      inputs_lock_state[3].disabled = true;
      inputs_lock_state[3].loading = true;
      try {
        await store.dispatch(FETCH_INSURANCE_COMPANIES_DOCUMENTS, id_insurance);
      } catch (error) {
        notification('error', 'Error', error.response.data.message);
      }
      inputs_lock_state[3].loading = false;
      inputStatesHeriarchy(3);
    };

    const companyPolicies = ref([]);
    const getPolicies = async (rut, id_company, id_insurance, id_group) => {
      try {
        const response = await store.dispatch(
          FETCH_POLICIES_FOR_DEPENDENTS_INCORPORATION_FORM,
          {
            rut: rutHelper.normalizeRut(rut),
            companyId: Number(id_company),
            insuranceCompanyId: Number(id_insurance),
            groupCompanyId: Number(id_group)
          }
        );
        companyPolicies.value = response.map((p) => ({
          label: `${
            insurance_companies.value.find(
              (x) => x.value == form_data.insurance
            )?.label
          } - ${p.numberPolicy}`,
          value: String(p.id)
        }));
      } catch (error) {
        notification('error', 'Error', error.response.data.message);
      }
    };

    const getEmployeeDataByRut = async (rut) => {
      /*
      Este tiene mas logica, al momento de obtener los datos de la persona por rut
      voy a rellenar inmediatamente los selectores que tienen la data
      */
      store
        .dispatch(FETCH_EMPLOYEE_DATA_BY_RUT, rutHelper.normalizeRut(rut))
        .then(() => {
          const { id } = store.getters[GET_EMPLOYEE_DATA];
          employee_id.value = id;
        })
        .finally(() => {
          form_ref.value.validate(['rut']);
        });
    };

    // * Reactividad de seleccion
    // Si selecciono companias
    watch(
      () => [form_data.company, form_data.rut],
      async ([newId, newRut]) => {
        form_data.group = ''; // Selector de group a vacio

        // Obtengo los grupos compa;ias
        if (Rut.validateRut(newRut) && newId != '') {
          await getGroupCompanies(newId, newRut);
        }
      }
    );

    // Si selecciono grupo companias
    watch(
      () => form_data.group,
      async (newId) => {
        form_data.insurance = ''; // Selector de Aseguradora

        // Obtengo la lista de aseguradoras
        if (newId != '') {
          await getInsuranceCompanies(form_data.company, newId);
        }
      }
    );

    // Si selecciono una compañia de seguros
    watch(
      () => form_data.insurance,
      async (newId) => {
        form_data.policies = []; // Selector de polizas la dejo en vacio

        if (newId != '') {
          await getInsuraceDocuments(newId);
        }
      }
    );

    // Para la seleccion de polizas, necesito que el usuario, tenga rellenado en el formulario
    // # Rut
    // # Compañia
    // # Aseguradora

    const debounce = (cb, delay = 1000) => {
      let timeout;

      return (...args) => {
        clearTimeout(timeout);
        timeout = setTimeout(() => {
          cb(...args);
        }, delay);
      };
    };

    const updateDebouceRut = debounce(async (text) => {
      await getEmployeeDataByRut(text);
      await getCompanies(text);
    }, 500);

    const valid_rut = computed(() => rutHelper.validateRut(form_data.rut));

    watch(
      () => form_data.rut,
      (newRut) => {
        if (newRut != '' && Rut.validateRut(newRut)) {
          updateDebouceRut(newRut);
        }
      }
    );

    watch(
      () => [
        form_data.rut,
        form_data.company,
        form_data.insurance,
        form_data.group
      ],
      async ([newRut, newCompany, newInsurance, newGroup]) => {
        if (
          Rut.validateRut(newRut) &&
          newCompany != '' &&
          newInsurance != '' &&
          newGroup != ''
        ) {
          await getPolicies(newRut, newCompany, newInsurance, newGroup);
        }
      }
    );

    // Lista de puntos a tener en cuenta cuando comencemos a llamar al endpoint de coberturas
    // [ ] Que exista un empleado encontrado en el sistema (employee_id.value)
    // [ ] Que el rut sea valido tambien
    // [ ] que el id de company exista
    // [ ] que el id de aseguradora exista
    /* 
    Entonces al momento de cualquier de estas opciones exista o cambie, se tiene que llamar al endpoint para obtener las coberturas
    pero tiene que hacerse 2 veces, tanto coberturas vigentes como no vigentes y guardar la data por separado
    por que asi voy a poder poner la cantidad de vigentes en el boton
    */

    const current_coverages = ref([]);
    const not_current_coverages = ref([]);

    const getCoveragesRefactored = (data) => {
      if (data.length > 0) {
        let indexCounter = 0;
        return data.flatMap((policy, index) =>
          policy.coverages.map((coverage) => {
            const new_coverage = {
              id: `# ${index + 1}`,
              key: indexCounter,
              policy_number: policy.numberPolicy,
              coverage: coverage.name
            };
            indexCounter += 1;
            return new_coverage;
          })
        );
      }
      return data;
    };

    watch(
      () => [
        employee_id.value,
        form_data.rut,
        form_data.company,
        form_data.insurance,
        policy_status.value
      ],
      async ([newEmployeeId, newRut, newCompany, newInsurance]) => {
        if (
          newEmployeeId != '' &&
          newEmployeeId != undefined &&
          Rut.validateRut(newRut) &&
          newCompany != '' &&
          newInsurance != ''
        ) {
          loading_policy_coverages.value = true;
          const current = await getCurrentEmployeeCoverages({
            id: newEmployeeId,
            companyId: newCompany,
            insuranceCompanyId: newInsurance,
            policyStatus: 'Vigente',
            coverageStatus: 'ACTIVE'
          });
          current_coverages.value = getCoveragesRefactored(current);

          const not_current = await getCurrentEmployeeCoverages({
            id: newEmployeeId,
            companyId: newCompany,
            insuranceCompanyId: newInsurance,
            policyStatus: 'No Vigente',
            coverageStatus: 'INACTIVE'
          });

          not_current_coverages.value = getCoveragesRefactored(not_current);
          loading_policy_coverages.value = false;
        } else {
          store.dispatch(ACTION_CURRENT_EMPLOYEE_COVERAGE, []);
          current_coverages.value = [];
          not_current_coverages.value = [];
        }
      }
    );

    const loading_policy_coverages = ref(false);
    const getCurrentEmployeeCoverages = async (payload) => {
      try {
        const resp = await store.dispatch(
          FETCH_CURRENT_EMPLOYEE_COVERAGE,
          payload
        );
        return resp.data;
      } catch (error) {
        notification('error', 'Error', error.response.data.message);
        loading_policy_coverages.value = false;
      }
    };

    const policy_status = ref('vigente');
    const policy_columns = [
      {
        title: 'Id Poliza',
        dataIndex: 'id'
      },
      {
        title: 'N° Póliza',
        dataIndex: 'policy_number'
      },
      {
        title: 'Cobertura',
        dataIndex: 'coverage'
      }
    ];

    const group_companies = computed(() => {
      return store.getters[GET_GROUP_COMPANIES].map((obj) => {
        return {
          label: obj.name,
          value: obj.id.toString()
        };
      });
    });

    const insurance_companies = computed(() => {
      return store.getters[GET_INSURANCE_COMPANIES].map((obj) => {
        return {
          label: obj.businessName,
          value: obj.id.toString(),
          formType: obj.insuranceCompanyForm
            ? obj.insuranceCompanyForm.type
            : 'PDF_FORM'
        };
      });
    });

    const insurance_documents = computed(() => {
      return store.getters[GET_INSURANCE_COMPANIES_DOCUMENTS].map((x) => {
        return {
          label: x.name,
          value: x.id
        };
      });
    });

    // * Modal de resumen
    const resume_modal = reactive({
      visibility: false,
      boton_loading: false,
      data: {
        date: '',
        insurer: '',
        company: '',
        group: '',
        policies: '',
        files: [],
        documents: [],
        rut: '',
        name: '',
        last: '',
        email: '',
        dependents: []
      }
    });

    const openResumeModal = (data) => {
      // Obtengo la data y la trasnformo para enviarla al backend

      resume_modal.data.date = parseDate(data.validity_date).substring(0, 10);
      resume_modal.data.insurer = insurance_companies.value.find(
        (x) => x.value == data.insurance
      ).label;
      resume_modal.data.company = companies.value.find(
        (x) => x.value == data.company
      ).label;
      resume_modal.data.group = group_companies.value.find(
        (x) => x.value == data.group
      ).label;
      resume_modal.data.policies =
        companyPolicies.value == undefined || companyPolicies.value == []
          ? ''
          : companyPolicies.value
              .map((x) => {
                return data.policies.includes(x.value) ? x.label : '';
              })
              .filter((el) => el !== '')
              .join(', ');

      if (form_data.typeform == 1) {
        if (data.documents !== undefined) {
          resume_modal.data.documents = insurance_documents.value
            .map((x) => {
              return data.documents.includes(x.value) ? x.label : '';
            })
            .filter((el) => el !== '');
        } else {
          resume_modal.data.documents = [];
        }
      } else {
        resume_modal.data.files =
          data.file_list == undefined || data.file_list == []
            ? ''
            : data.file_list.map((x) => {
                return x.name;
              });
        const dependents = {};
        Object.keys(data).forEach((key) => {
          if (key.includes('dependents')) {
            const index = key.split(',')[1];
            const attr = key.split(',')[2];
            if (!dependents[index]) {
              dependents[index] = {};
            }
            dependents[index][attr] = data[key];
          }
        });
        resume_modal.data.dependents = Object.values(dependents);
      }

      resume_modal.data.rut = rutHelper.formatRut(data.rut);

      // Abro el modal
      resume_modal.visibility = true;
    };

    const closeResumeModal = () => {
      resume_modal.visibility = false;
    };

    // * Modal Coberturas y polizas
    const policy_coverages_modal_ref = ref();
    const openPolicyCoverage = () => {
      policy_coverages_modal_ref.value.openModal();
    };

    // * Modal success
    const show_sucess = ref(false);

    const showError = ref(false);

    const verTicket = () => {
      router.push({
        name: 'AltaCargasDetalle',
        params: { id: ticket_id.value }
      });
    };
    const handleOk = () => {
      show_sucess.value = false;
      showError.value = false;
    };

    const relationOptions = [
      {
        label: 'Cónyuge',
        value: 'SPOUSE'
      },
      {
        label: 'Conviviente',
        value: 'COHABITANT'
      },
      {
        label: 'Hijo(a)',
        value: 'CHILD'
      },
      {
        label: 'Padre/Madre',
        value: 'PARENT'
      },
      {
        label: 'Nieto(a)',
        value: 'GRANDCHILD'
      },
      {
        label: 'Hermano(a)',
        value: 'SIBLING'
      }
    ];

    const checkDate = async (rule, value) => {
      if (!value) {
        return Promise.reject('La fecha de nacimiento es obligatoria.');
      }
      const date = moment(value.replace(/[-*]/g, '/'), 'DD/MM/YYYY', true);
      if (!date.isValid()) {
        return Promise.reject(
          'Fecha inválida. Se espera el formato DD/MM/YYYY o DD-MM-YYYY.'
        );
      }
      return Promise.resolve();
    };

    return {
      // Variables
      handleOk,
      showError,
      verTicket,
      form_ref,
      showSucess,
      form_data,
      form_rules,
      options1,
      uploading,
      file_list,
      companies,
      group_companies,
      inputs_lock_state,
      insurance_companies,
      companyPolicies,
      resume_modal,
      format_date,
      show_sucess,
      ticket_id,
      insurance_documents,
      routes,
      policy_status,
      policy_columns,
      loading_policy_coverages,
      policy_coverages_modal_ref,
      employee_id,
      // Functions
      onSubmitForm,
      handleRemove,
      beforeUpload,
      handleUpload,
      parseDate,
      createAlta,
      closeResumeModal,
      formatRuts,
      handleRemoveOptional,
      beforeUploadOptional,
      getCurrentEmployeeCoverages,
      openPolicyCoverage,
      valid_rut,
      current_coverages,
      not_current_coverages,
      handleAddDependent,
      handleDeleteDependent,
      rutValidator,
      rutHelper,
      loadingCompanies,
      relationOptions,
      checkDate
    };
  }
};
</script>
