import config from '../config';

const appConfig = config();
const apiTicket = appConfig.ticket_prefix;
const { v1 } = appConfig.apiVersion;

const network = (client) => ({
  getDependants: ({ params: { id } }) =>
    client.get(`${apiTicket}${v1}/ticket/${id}/dependants`),
  // --> Crear un ticket de alta individual a compañia
  createAltaIndividualTicketToCompany: (payload) =>
    client.post(`${apiTicket}${v1}/ticket/alta-to-company`, payload),
  // --> Obtener informacion de un ticket de transferencia
  getTransferInformation: (payload) =>
    client.get(`${apiTicket}${v1}/ticket/${payload}/transfer-policies`),
  // --> Obtener los grupos de un ticket de transferencia
  getTransferGroups: (payload) =>
    client.get(`${apiTicket}${v1}/ticket/${payload}/transfer-groups`),
  // --> Crear un ticket de alta individual a colaborador
  createAltaIndividualTicketToCollaborator: (payload) =>
    client.post(`${apiTicket}${v1}/ticket/alta`, payload),
  // --> Obtener el texto de consentimiento de un ticket de alta creado
  getConsentText: (payload) =>
    client.get(`${apiTicket}${v1}/ticket/get-consent-text/${payload}`),
  // TODO: Ver donde se usa este
  patchConsentText: (payload) =>
    client.patch(`${apiTicket}${v1}/ticket/employee-consent/${payload}`),
  // TODO: Ver donde se usa este
  cancelTicket: (payload) =>
    client.post(`${apiTicket}${v1}/ticket/cancelTicket/${payload}`),
  // --> Finalizar ticket de baja
  finishTicketBaja: (payload) =>
    client.patch(`${apiTicket}${v1}/ticket/baja/${payload}/finish`),
  // -->
  commentId: (payload) =>
    client.get(`${apiTicket}${v1}/ticket/${payload}/comments`),
  // --> Obtener si este ticket depende de la renta de el empleado
  ticketIncome: (payload) =>
    client.get(`${apiTicket}${v1}/ticket/${payload}/income`),

  getCompanyList: () =>
    client.get(`${apiTicket}${v1}/form/registration/individual/companies`), // Obtener lista de companias
  getGroupCompanyList: (payload) =>
    client.get(
      `${apiTicket}${v1}/form/registration/individual/groups${payload}`
    ), // Obtener lista de companias
  getinsuranceCompanyList: (id_company, id_group) =>
    client.get(
      `${apiTicket}${v1}/form/registration/individual/insurance-companies?company=${id_company}&groups=${id_group}`
    ), // Obtener lista de las aseguradoras
  getinsuranceCompanyPolicyList: (rut, id_company, id_insurance, id_group) =>
    client.get(
      `${apiTicket}${v1}/form/registration/individual/policies?rut=${rut}&company=${id_company}&insurancecompany=${id_insurance}&groups=${id_group}`
    ), // Obtener lista de las polizas
  getinsuranceCompanyDocumentsList: (payload) =>
    client.get(
      `${apiTicket}${v1}/form/registration/individual/insurance-files?insurancecompany=${payload}`
    ), // Obtener lista de las documentos
  getEmployeeDataByRut: (payload) =>
    client.get(`${apiTicket}${v1}/employee/rut/${payload}`), // Obtener informacion de un empleado por rut

  // api Ticket

  customIdentity: (payload) =>
    client.get(`${apiTicket}${v1}/fileTicket/customer-identity/${payload}`),
  fileTicketHistory: (payload) =>
    client.get(`${apiTicket}${v1}/fileTicket/${payload}/history`),
  createCustomerIdentity: (payload) =>
    client.post(`${apiTicket}${v1}/fileTicket/customer-identity/`, payload),

  resendEmail: (ticketId, email) => {
    if (email) {
      return client.post(
        `${apiTicket}${v1}/ticket/${ticketId}/resend-form-incorporation`,
        email
      );
    }
    return client.post(
      `${apiTicket}${v1}/ticket/${ticketId}/resend-form-incorporation`
    );
  },
  commentTicketId: (payload) =>
    client.get(`${apiTicket}${v1}/comment/by_ticket_id/${payload}`),

  masiveCommentsById: (payload) =>
    client.get(`${apiTicket}${v1}/ticket-groups/${payload}/comments/`),
  createCommentMasiveTicket: (payload) =>
    client.post(`${apiTicket}${v1}/ticket-groups/comments/`, payload),

  dataTicket: (payload, token) =>
    client(`${apiTicket}${v1}/ticket/${payload}/verify/${token}`),
  apiTicket: () => client.get(`${apiTicket}${v1}`),
  getTicketId: (payload) => client.get(`${apiTicket}${v1}/ticket/${payload}`),
  editTicketDetails: (id, payload) =>
    client.patch(`${apiTicket}${v1}/ticket/${id}`, payload),
  employeTicket: (payload) =>
    client.get(`${apiTicket}${v1}/employee-ticket/by_ticket_id/${payload}`),
  sendInformationToInsuranceCompany: (id, payload) =>
    client.post(`${apiTicket}${v1}/ticket/${id}/alta-to-insurance`, payload),

  insuranceCompanyUploadFormType: (payload) =>
    client.post(`${apiTicket}${v1}/filesInsuranceCompany`, payload),
  updateInsuranceCompanyUploadFormType: (payload, id) =>
    client.put(`${apiTicket}${v1}/filesInsuranceCompany/${id}`, payload),
  deleteInsuranceCompanyUploadFormType: (payload) =>
    client.delete(`${apiTicket}${v1}/filesInsuranceCompany/${payload}`),

  // Busqueda de stock
  employeeStockSearch: (type, payload) =>
    client.get(`${apiTicket}${v1}/employee_stock/${type}?${payload}`),

  caseTicketId: (payload) =>
    client.get(`${apiTicket}${v1}/case/ticket/${payload}`),
  caseAntecedentes: (payload) =>
    client.post(`${apiTicket}${v1}/case/antecedentes/`, payload),

  caseAntecedentesVerify: (id, token) =>
    client.get(`${apiTicket}${v1}/case/verify/${id}/${token}`),

  caseId: (payload) => client.get(`${apiTicket}${v1}/case/id/${payload}`),
  typeCases: () => client.get(`${apiTicket}${v1}/case/typecases`),
  fileByCase: (payload) =>
    client.get(`${apiTicket}${v1}/fileTicket/get_files_by_case/${payload}`),
  fileTicketRecord: (payload) =>
    client.get(`${apiTicket}${v1}/fileTicket/fileRecords/${payload}/ticket`),
  caseState: (payload) => client.get(`${apiTicket}${v1}/case/state/${payload}`),
  getFile: (payload) =>
    client.get(`${apiTicket}${v1}/fileTicket/getFile/${payload}`),
  fileInsuranceCompany: (payload) =>
    client.get(`${apiTicket}${v1}/filesInsuranceCompany/${payload}`),
  typeFormsInsuranceCompany: (payload) =>
    client.get(`${apiTicket}${v1}/insuranceCompany/${payload}/files`),
  filesByTicket: (payload) =>
    client.get(`${apiTicket}${v1}/ticket/${payload}/alta-file-upload`),
  reapplyFile: (id, payload) =>
    client.put(`${apiTicket}${v1}/fileTicket/reject/${id}`, payload),
  getInsuranceDocuments: (payload) =>
    client.get(`${apiTicket}${v1}/filesInsuranceCompany/ticket/${payload}`),
  postFileInsuranceCompany: (id, payload) =>
    client.post(
      `${apiTicket}${v1}/filesInsuranceCompany/ticket/${id}`,
      payload
    ),

  TicketTableQuery: (params, signal) =>
    client.get(`${apiTicket}${v1}/ticket?${params}`, { signal }),
  TicketTableResume: (payload, signal) =>
    client.get(`${apiTicket}${v1}/ticket/summary?type=${payload}`, { signal }),
  TicketTableQueryCases: (params, signal) =>
    client.get(`${apiTicket}${v1}/case?${params}`, { signal }),
  TicketTableQueryGroups: (params) =>
    client.get(`${apiTicket}${v1}/ticket-groups?${params}`),

  TicketResendFormIncorporation: (id, payload) =>
    client.post(
      `${apiTicket}${v1}/ticket/${id}/resend-form-incorporation`,
      payload
    ),

  TicketqueryParamsAlta: () => client.get(`${apiTicket}${v1}/ticket?type=Alta`), // arreglar despues el parametro como {Alta}
  TicketqueryParamsBaja: () => client.get(`${apiTicket}${v1}/ticket?type=Baja`), // arreglar despues el parametro como {Baja}
  TicketqueryParamsModificacion: () =>
    client.get(`${apiTicket}${v1}/ticket?type=Modificacion`), // arreglar despues el parametro como {Modificacion}

  postTicketBajaIndividual: (payload) =>
    client.post(`${apiTicket}${v1}/ticket/baja`, payload),
  ticketBajaMasiva: (payload) =>
    client.post(`${apiTicket}${v1}/ticket-groups/baja`, payload),
  ticketModificacionMasiva: (payload) =>
    client.post(`${apiTicket}${v1}/ticket-groups/modificacion`, payload),
  getFileUrl: () =>
    `${appConfig.baseURL}${apiTicket}${v1}/fileTicket/getFile/a8082d108a28c9ff60feb44308abc7f5`,

  dashboard: (payload) => client.get(`${apiTicket}${v1}/dashboard${payload}`),
  dashboardGraphicCompanyType: (payload = '') =>
    client.get(`${apiTicket}${v1}/dashboard/graphic${payload}`),
  dataMovmentCompany: () =>
    client.get(`${apiTicket}${v1}/dashboard/graphic?company=1&type=1`),
  companyTickets: (payload) =>
    client.get(`${apiTicket}${v1}/company/${payload}/tickets`), // verificar y cambiar 1 por payload

  createComment: (payload) =>
    client.post(`${apiTicket}${v1}/comment/`, payload),
  cancelTicketAlta: (payload) =>
    client.post(`${apiTicket}${v1}/ticket/alta/${payload}/cancel`),
  cancelTicketBaja: (payload) =>
    client.post(`${apiTicket}${v1}/ticket/baja/${payload}/cancel`),
  cancelTicketModificacionIndividual: (payload) =>
    client.post(`${apiTicket}${v1}/ticket/modification/${payload}/cancel`),
  cancelTicketModificacionMasiva: (payload) =>
    client.post(
      `${apiTicket}${v1}/ticket-groups/modificacion/${payload}/cancel`
    ),
  ejecutarTicketModificacion: (payload) =>
    client.post(`${apiTicket}${v1}/ticket/modification/${payload}/finish`),
  ejecutarTicketMOficacionMasiva: (payload) =>
    client.post(
      `${apiTicket}${v1}/ticket-groups/modificacion/${payload}/success`
    ),
  // Validar token para la resolicitud de documento
  verifyDocumentRequestToken: (id, token) =>
    client.get(`${apiTicket}${v1}/fileTicket/verify/${id}/${token}`),
  uploadDocumentRequested: (payload) =>
    client.post(`${apiTicket}${v1}/fileTicket/reuploadFile`, payload),
  getOldDocument: (payload) =>
    client.get(`${apiTicket}${v1}/fileTicket/${payload}`),
  getBlankDocument: (payload) =>
    client.get(`${apiTicket}${v1}/filesInsuranceCompany/${payload}/getFile`),

  // TODO: VER BIEN EL ENDPOINT CREATESTATE SI SE ESTA OCUPANDO EN ALGUN LADO
  createStateTicket: (payload) =>
    client.post(`${apiTicket}${v1}/state_ticket/create_state_ticket/`, payload),
  finishAltaIndividual: (payload) =>
    client.post(`${apiTicket}${v1}/ticket/alta/${payload}/finish`),
  createCaseId: (payload, token) =>
    client.post(`${apiTicket}${v1}/case/${payload}/verify/${token}`),
  createStateCase: (id, payload) =>
    client.post(`${apiTicket}${v1}/case/${id}/add-state`, payload),
  securedFileUpload: (payload) =>
    client.post(`${apiTicket}${v1}/fileTicket/asegurado/uploadFile/`, payload),

  caseFilesUpload: (id, payload) =>
    client.post(
      `${apiTicket}${v1}/case/antecedentes/${id}/upload-file-employee`,
      payload
    ),

  uploadFile: (payload) =>
    client.post(`${apiTicket}${v1}/fileTicket/uploadFile/`, payload),
  uploadFileMultiple: (payload) =>
    client.post(`${apiTicket}${v1}/fileTicket/uploadMultipleFile`, payload),
  postTicketModification: (payload) =>
    client.post(`${apiTicket}${v1}/ticket/modification`, payload),
  saveConsentDate: (id) =>
    client.patch(`${apiTicket}${v1}/ticket/employee-consent/${id}`),
  updateInsuredState: (id, payload) =>
    client.post(`${apiTicket}${v1}/ticket/${id}/add-state`, payload),
  createTransferTicket: (payload) =>
    client.post(`${apiTicket}${v1}/ticket/transfer`, payload),
  createEmployeeDependentsIncorporation: (payload) =>
    client.post(
      `${apiTicket}${v1}/ticket/employee-dependents-incorporations`,
      payload
    ),
  createDependentsExclusion: (payload) =>
    client.post(`${apiTicket}${v1}/ticket/dependents-exclusions`, payload),
  createInsuranceDependentsIncorporation: (payload) =>
    client.post(
      `${apiTicket}${v1}/ticket/insurance-dependents-incorporations`,
      payload
    ),
  getInsuranceCompaniesByEmployeeRut: ({ rut, companies, groupCompany }) =>
    client.get(
      `${apiTicket}${v1}/form/transfer/individual/insurance-companies`,
      { params: { rut, companies, groupCompany } }
    ),
  executeTransferTicket: (id, payload) =>
    client.post(`${apiTicket}${v1}/ticket/transfer/${id}/finish`, payload),
  cancelTransferTicket: (id, payload) =>
    client.post(`${apiTicket}${v1}/ticket/transfer/${id}/cancel`, payload),
  cancelDependentsIncorporation: (id) =>
    client.post(
      `${apiTicket}${v1}/ticket/dependents-incorporations/${id}/cancel`
    ),
  finishDependentsIncorporation: (id) =>
    client.post(
      `${apiTicket}${v1}/ticket/dependents-incorporations/${id}/finish`
    ),
  cancelDependentsExclusion: (id) =>
    client.post(`${apiTicket}${v1}/ticket/dependents-exclusions/${id}/cancel`),
  finishDependentsExclusion: (id) =>
    client.post(`${apiTicket}${v1}/ticket/dependents-exclusions/${id}/finish`),
  sendToInsuranceDependentsIncorporation: (id) =>
    client.post(
      `${apiTicket}${v1}/ticket/dependents-incorporations/${id}/send-to-insurance`
    ),
  getCaseData: (id) => client.get(`${apiTicket}${v1}/case/${id}`),
  getDependentsExclusionsOfTicket: (id) =>
    client.get(`${apiTicket}${v1}/ticket/${id}/dependents-exclusions`),
  // * Crear casos
  // Crear caso de rechazo
  caseRechazo: (payload) =>
    client.post(`${apiTicket}${v1}/case/rechazo`, payload),
  getAppealEmails: (id, type) =>
    client.get(`${apiTicket}${v1}/insuranceCompany/${id}/mailbox?type=${type}`),
  postRejectionAppeal: (id, payload) =>
    client.post(`${apiTicket}${v1}/case/rechazo/${id}/apelacion`, payload),
  aprroveOrDenyAppeal: (id, action, payload) =>
    client.post(
      `${apiTicket}${v1}/case/rechazo/${id}/finish/${action}`,
      payload
    ),
  getCaseFiles: (id) => client.get(`${apiTicket}${v1}/case/${id}/files`),
  // * FETCH CASES
  getOutOfTimeCases: () =>
    client.get(`${apiTicket}${v1}/case?type=Out of Time`),
  getRechazoCases: () => client.get(`${apiTicket}${v1}/case?type=Rechazo`),
  getAntecedentesCases: () =>
    client.get(`${apiTicket}${v1}/case?type=Antecedentes`),
  getAltaMasivaTickets: () =>
    client.get(`${apiTicket}${v1}/ticket-groups?type=Alta`),
  getBajaMasivaTickets: () =>
    client.get(`${apiTicket}${v1}/ticket-groups?type=Baja`),
  getModificacionMasivaTickets: () =>
    client.get(`${apiTicket}${v1}/ticket-groups?type=Modificacion`),
  postAltaMasivaFile: (payload) =>
    client.post(`${apiTicket}${v1}/ticket-groups/alta-file`, payload),
  postAltaMasivaForm: (payload) =>
    client.post(`${apiTicket}${v1}/ticket-groups/alta-form`, payload),
  getAltaMasivaDetail: (payload) =>
    client.get(`${apiTicket}${v1}/ticket-groups/${payload}`),
  getAltaMasivaFile: (payload) =>
    client.get(`${apiTicket}${v1}/ticket-groups/${payload}/file`),
  precancelAltaMasiva: (payload) =>
    client.post(`${apiTicket}${v1}/ticket-groups/${payload}/pre-cancel`),
  cancelAltaMasiva: (idAlta, payload) =>
    client.post(`${apiTicket}${v1}/ticket-groups/${idAlta}/cancel`, payload),
  cancelBajaMasiva: (idAlta, payload) =>
    client.post(
      `${apiTicket}${v1}/ticket-groups/baja/${idAlta}/cancel`,
      payload
    ),
  presuccessAltaMasiva: (payload) =>
    client.post(`${apiTicket}${v1}/ticket-groups/${payload}/pre-success`),
  successAltaMasiva: (idAlta, payload) =>
    client.post(`${apiTicket}${v1}/ticket-groups/${idAlta}/success`, payload),
  successBajaMasiva: (idAlta, payload) =>
    client.post(
      `${apiTicket}${v1}/ticket-groups/baja/${idAlta}/success`,
      payload
    ),
  getAltaMasivaForm: (companyId, insuranceCompanyId) => {
    return `${appConfig.baseURL}${apiTicket}${v1}/ticket-groups/download-format?company=${companyId}&insurance=${insuranceCompanyId}`;
  },
  getMassiveIncorporationToEmployeeFormat: (companyId, insuranceCompanyId) => {
    return `${appConfig.baseURL}${apiTicket}${v1}/ticket-groups/download-format-employee?company=${companyId}&insurance=${insuranceCompanyId}`;
  },
  getUploadStockTemplate: (companyId, insuranceCompanyId) => {
    return `${appConfig.baseURL}${apiTicket}${v1}/massiveRegistration/download-format?company=${companyId}&insurance=${insuranceCompanyId}`;
  },
  getBajaMasivaForm: () => {
    return `${appConfig.baseURL}${apiTicket}${v1}/ticket-groups/baja/download-format`;
  },
  covergarePolicy: (payload) =>
    client.get(
      `${appConfig.baseURL}${apiTicket}${v1}/ticket/${payload}/coverages-available`
    ),
  createAltaIndividual: (payload) =>
    client.post(
      `${appConfig.baseURL}${apiTicket}${v1}/policy/form-alta?company=${payload.company}&insurance=${payload.insurance}&rut=${payload.rut}`
    ),
  fileRequest: (payload) =>
    client.get(`https://bewell-s3.s3.us-west-2.amazonaws.com/${payload}`, {
      withCredentials: false
    }),

  getDependentsIncorporationForm: (token) =>
    client.get(`${apiTicket}${v1}/dependents-incorporation-form`, {
      headers: { Authorization: `Bearer ${token}` }
    }),
  getDependentsOfDependentsIncorporationForm: (token) =>
    client.get(`${apiTicket}${v1}/dependents-incorporation-form/dependents`, {
      headers: { Authorization: `Bearer ${token}` }
    }),
  putDependentsOfDependentsIncorporationForm: ({ token, dependents }) =>
    client.put(
      `${apiTicket}${v1}/dependents-incorporation-form/dependents`,
      { dependents },
      { headers: { Authorization: `Bearer ${token}` } }
    ),
  putDocumentOfDependentsIncorporationForm: ({ token, payload }) =>
    client.put(
      `${apiTicket}${v1}/dependents-incorporation-form/documents`,
      payload,
      { headers: { Authorization: `Bearer ${token}` } }
    ),
  resendDependentsIncorporationForm: (token) =>
    client.post(
      `${apiTicket}${v1}/dependents-incorporation-form/reset-token`,
      {},
      { headers: { Authorization: `Bearer ${token}` } }
    ),
  sendToBrokerDependentsIncorporationForm: (token) =>
    client.post(
      `${apiTicket}${v1}/dependents-incorporation-form/send-to-broker`,
      {},
      { headers: { Authorization: `Bearer ${token}` } }
    ),
  getPoliciesForDependentsIncorporation: ({
    rut,
    companyId,
    insuranceCompanyId,
    groupCompanyId
  }) =>
    client.get(`${apiTicket}${v1}/dependents-incorporation-form/policies`, {
      params: { rut, companyId, insuranceCompanyId, groupCompanyId }
    }),
  getCompaniesForDependentsIncorporation: (rut) =>
    client.get(`${apiTicket}${v1}/dependents-incorporation-form/companies`, {
      params: { rut }
    }),
  updateEndDateBeforeFinishExclusion: (id, endDate) =>
    client.post(
      `${apiTicket}${v1}/ticket/${id}/update-end-date-before-finish-exclusion`,
      { endDate }
    ),
  updateEndDateBeforeFinishMassiveExclusion: (id, endDate) =>
    client.post(
      `${apiTicket}${v1}/ticket-groups/${id}/update-end-date-before-finish-exclusion`,
      { endDate }
    )
});
export default network;
