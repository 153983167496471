import config from '../config';

const appConfig = config();

const apiInsurance = appConfig.insurance_prefix;
const { v1 } = appConfig.apiVersion;

const network = (client) => ({
  apiInsurance: () => client.get(`${apiInsurance}${v1}`),
  massiveRegistrationInitialLoad: (payload) =>
    client.post(
      `${apiInsurance}${v1}/massiveRegistration/initial-load`,
      payload
    ),
  getInsurances: ({ companyId, subHoldingId, holdingId, groupCompanyId }) =>
    client.get(`${apiInsurance}${v1}/insuranceCompany`, {
      params: { companyId, subHoldingId, holdingId, groupCompanyId }
    }),
  getAnInsurance: (id) =>
    client.get(`${apiInsurance}${v1}/insuranceCompany/${id}`),
  postInsurance: (payload) =>
    client.post(`${apiInsurance}${v1}/insuranceCompany`, payload),
  putInsurance: (insuranceCompanyId, payload) =>
    client.patch(
      `${apiInsurance}${v1}/insuranceCompany/${insuranceCompanyId}`,
      payload
    ),
  deleteInsurance: (payload) =>
    client.delete(`${apiInsurance}${v1}/insuranceCompany/${payload}`),
  getInsuranceCompanyExecutive: (payload) =>
    client.get(`${apiInsurance}${v1}/insuranceCompany/${payload}/executives`),
  postInsuranceCompanyExecutive: (idInsurance, payload) =>
    client.post(
      `${apiInsurance}${v1}/insuranceCompany/${idInsurance}/executives`,
      payload
    ),
  putInsuranceCompanyExecutive: (idInsurance, idExecutive, payload) =>
    client.put(
      `${apiInsurance}${v1}/insuranceCompany/${idInsurance}/executives/${idExecutive}`,
      payload
    ),
  deleteInsuranceCompanyExecutive: (payload) =>
    client.delete(
      `${apiInsurance}${v1}/insuranceCompany/${payload.insuranceId}/executives/${payload.employeeId}`
    )
});
export default network;
