<template>
  <page-header :breadcrum-routes="routes" title="Alta individual">
    <template #tags>
      <tags
        :stateId="
          files.some((f) => f.options.requestCounter > 0)
            ? 9
            : ticket.id_last_state
        "
        v-if="ticket.id_last_state !== ''"
      />
      <tags :stateId="1" v-else />
      <tags color="processing"> ID: {{ ticket.id_ticket }} </tags>
    </template>

    <template v-if="user_role != 'rrhh'" #buttons>
      <!-- Cancelar ticket -->
      <a-button
        v-if="
          ticket.id_last_state == 7 ||
          ticket.id_last_state == 8 ||
          ticket.id_last_state == 1
        "
        :loading="loading_state"
        @click="openCancelModal"
        >Cancelar</a-button
      >

      <!-- Revisar ticket -->
      <a-button
        type="primary"
        v-if="ticket.id_last_state == 7"
        @click="openReviewModal"
        :loading="loading_state"
        class="ml-2"
        >Revisado</a-button
      >

      <!-- Ejecutar -->
      <a-button
        type="primary"
        v-if="ticket.id_last_state == 8"
        @click="openExecuteodal"
        :loading="loading_state"
        class="ml-2"
        >Ejecutar</a-button
      >
    </template>
  </page-header>

  <stepper
    :state="final_state"
    ticketType="alta"
    :stateDates="parsed_dates"
    ref="stepper_ref"
  />

  <a-card>
    <a-row :gutter="16">
      <a-col :span="12">
        <datos-ticket
          title="Datos ticket"
          :data="ticket_data_table"
          :loadTicket="loadTicket"
        >
          <template
            v-if="
              user_role != 'rrhh' &&
              (final_state === 'revision_bewell' ||
                final_state === 'revision_aseguradora')
            "
            #action
          >
            <a-tooltip>
              <template #title>Editar</template>
              <a-button @click="openAltaEditModal">
                <template #icon><edit-outlined /></template>
              </a-button>
            </a-tooltip>

            <edit_modal
              :values="alta_edit_object"
              :ticketId="ticket.id_ticket"
              @editedAltaColaborator="() => getTicketData(ticket.id_ticket)"
              ref="edit_modal_ref"
              :rentRequired="employee_rent_required"
            />
          </template>
        </datos-ticket>

        <a-card
          v-if="user_role != 'rrhh'"
          title="Casos asociados"
          bodyStyle="padding: 24px 24px 24px 24px; border: 1px solid #f0f0f0; border-top-width: 0px;"
          :bordered="false"
        >
          <template #extra v-if="enable_create_cases">
            <a-dropdown>
              <template #overlay>
                <a-menu>
                  <a-menu-item key="1" @click="openModalAntecedentes"
                    >Antecedentes</a-menu-item
                  >
                  <a-menu-item key="2" @click="openModalRechazo"
                    >Rechazo
                  </a-menu-item>
                </a-menu>
              </template>
              <a-button>
                Crear caso
                <down-outlined />
              </a-button>
            </a-dropdown>
          </template>
          <div v-if="cases.length == 0">
            <a-typography-text>No existen casos</a-typography-text>
          </div>

          <a-row
            type="flex"
            justify="space-between"
            v-for="c in cases"
            :key="c.id"
          >
            <a-col :span="6">
              <a-typography-text>{{ c.state }}</a-typography-text>
            </a-col>
            <a-col>
              <tags :stateId="c.tag" />
            </a-col>
            <a-col>
              <a-button type="link" @click="redirectCase(c)"
                >Ir a caso</a-button
              >
            </a-col>
            <a-divider class="my-2" v-if="cases.length > 1" />
          </a-row>
        </a-card>

        <comments
          v-if="user_role != 'rrhh'"
          class="mt-3"
          :idToTicket="ticket.id_ticket"
          :comments="comments"
          @new_comment="getNewCommment"
        />
      </a-col>
      <a-col :span="12" :style="{ zIndex: 0 }">
        <a-card
          v-if="user_role === 'rrhh'"
          title="Casos asociados"
          bodyStyle="padding: 24px 24px 24px 24px; border: 1px solid #f0f0f0; border-top-width: 0px;"
          :bordered="false"
        >
          <template #extra v-if="enable_create_cases">
            <a-dropdown>
              <template #overlay>
                <a-menu>
                  <a-menu-item key="1" @click="openModalAntecedentes"
                    >Antecedentes</a-menu-item
                  >
                  <a-menu-item key="2" @click="openModalRechazo"
                    >Rechazo
                  </a-menu-item>
                </a-menu>
              </template>
              <a-button>
                Crear caso
                <down-outlined />
              </a-button>
            </a-dropdown>
          </template>
          <div v-if="cases.length == 0">
            <a-typography-text>No existen casos</a-typography-text>
          </div>

          <a-row
            type="flex"
            justify="space-between"
            v-for="c in cases"
            :key="c.id"
          >
            <a-col :span="6">
              <a-typography-text>{{ c.state }}</a-typography-text>
            </a-col>
            <a-col>
              <tags :stateId="c.tag"></tags>
            </a-col>
            <a-col>
              <a-button type="link" @click="redirectCase(c)"
                >Ir a caso</a-button
              >
            </a-col>
            <a-divider class="my-2" v-if="cases.length > 1" />
          </a-row>
        </a-card>

        <records
          title="Historial"
          :loadingHistory="loadingHistory"
          :data="ordered_records"
          v-if="user_role != 'rrhh'"
        />

        <a-table
          v-if="user_role != 'rrhh'"
          :row-selection="rowSelectionForm"
          :selections="true"
          :columns="columnsFiles"
          :data-source="files_total_data"
          :showHeader="false"
          :pagination="false"
          :key="mykey"
          :loading="loadingFiles"
        >
          <template #title>Formularios adjuntos</template>
          <template #options="{ text: options }">
            <span style="display: flex">
              <a-tooltip color="#05045D" title="Ver más">
                <a-button type="link" :disabled="options.requestCounter > 0">
                  <eye-outlined
                    @click="() => redirectToFile(options.seeMore)"
                  />
                </a-button>
              </a-tooltip>
              <a-tooltip color="#05045D" title="Resolicitar">
                <a-button
                  type="link"
                  @click="() => openReapplyModal(options.fileId)"
                  :disabled="!options.canRequest"
                >
                  <mail-outlined />
                </a-button>
              </a-tooltip>
              <a-tooltip
                color="#05045D"
                title="Historial"
                @click="() => openAttachedDocumentsHistoryModal(options.fileId)"
              >
                <a-button type="link">
                  <clock-circle-outlined />
                </a-button>
              </a-tooltip>
            </span>
          </template>
        </a-table>

        <a-table
          v-if="user_role != 'rrhh'"
          class="mt-3"
          :columns="columnsCoverage"
          :data-source="ticket.coverages"
          :showHeader="false"
          :pagination="false"
          :loading="loadingCoverages"
        >
          <template #title>Coberturas seleccionadas</template>
          <template #required="{ text: required }">
            <a-radio-group
              :options="radioCoverages"
              v-model:value="required.selected"
              :disabled="required.required"
            />
          </template>
        </a-table>

        <dependants-table class="mt-3" />
      </a-col>
    </a-row>
  </a-card>

  <!-- Historial de documentos adjuntos -->
  <attached-documents-history ref="attached_documents_history_ref" />

  <!-- Caso de rechazo modal -->
  <a-modal
    v-model:visible="visible_rechazo_modal"
    title="Crear caso de rechazo"
    :closable="!rechazo_loading"
    :maskClosable="!rechazo_loading"
  >
    <a-form layout="vertical">
      <a-form-item label="Mensaje para el cliente">
        <a-textarea
          v-model:value="rechazo_body"
          :auto-size="{ minRows: 2, maxRows: 5 }"
        />
      </a-form-item>
      <a-form-item label="Carta de rechazo">
        <a-upload
          :file-list="rechazo_file"
          :remove="handleRemoveRechazo"
          :before-upload="beforeUploadRechazo"
        >
          <a-button>
            <upload-outlined></upload-outlined>
            Subir archivo
          </a-button>
        </a-upload>
      </a-form-item>
    </a-form>
    <template #footer>
      <a-button
        key="back"
        @click="visible_rechazo_modal = false"
        :disabled="rechazo_loading"
        >Cancelar</a-button
      >
      <a-button
        key="submit"
        type="primary"
        :disabled="rechazo_file.length < 1 || rechazo_body.length == 0"
        :loading="rechazo_loading"
        @click="sendRechazoCase"
        >Enviar</a-button
      >
    </template>
  </a-modal>

  <!-- Caso por antecedentes modal -->
  <a-modal
    v-model:visible="visible_antecedentes_modal"
    title="Crear caso de antecedentes"
    :closable="!loading_antecedentes"
    :maskClosable="!loading_antecedentes"
  >
    <a-form layout="vertical">
      <a-form-item label="Mensaje para el colaborador">
        <a-textarea
          v-model:value="antecedentes_body"
          :auto-size="{ minRows: 2, maxRows: 5 }"
          placeholder="Escribe aquí el mensaje que quieres enviarle al colaborador, puede ser un resumen de la carta de solicitud de antecedentes..."
        />
      </a-form-item>
      <a-form-item label="Carta de antecedentes">
        <a-upload
          :file-list="antecedentes_file"
          :remove="handleRemoveAntecedentes"
          :before-upload="beforeUploadAntecedentes"
        >
          <a-button>
            <upload-outlined></upload-outlined>
            Subir archivo
          </a-button>
        </a-upload>
      </a-form-item>
    </a-form>
    <template #footer>
      <a-button
        key="back"
        @click="visible_antecedentes_modal = false"
        :disabled="loading_antecedentes"
        >Cancelar</a-button
      >
      <a-button
        key="submit"
        type="primary"
        :disabled="
          antecedentes_file.length < 1 || antecedentes_body.length == 0
        "
        @click="sendAntecedentesCase"
        :loading="loading_antecedentes"
        >Enviar</a-button
      >
    </template>
  </a-modal>

  <!-- Modal Success -->
  <a-modal
    v-model:visible="showSucess"
    centered
    footer=""
    :title="success_title"
  >
    <a-result status="success" :title="success_message"> </a-result>
  </a-modal>

  <!-- Modal Error -->
  <a-modal v-model:visible="showError" centered footer="" :title="error_title">
    <a-result status="error" :title="error_message"> </a-result>
  </a-modal>

  <!-- Resolicitud de documento -->
  <a-modal
    v-model:visible="showReapplyDoc"
    centered
    @cancel="closeReapplyModal"
    title="Resolicitud de documento"
    :closable="!loading_reapply"
    :maskClosable="!loading_reapply"
  >
    <h3><b>Escribe un mensaje para el colaborador</b></h3>
    <p>
      Recordatorio Nombre:
      {{ ticket_data_table.find((o) => o.key === 'Nombre completo').value }}
    </p>

    <a-textarea
      v-model:value="reapply_text"
      placeholder="Escribe aquí el mensaje para el colaborador"
      :autoSize="{ minRows: 5, maxRows: 5 }"
    />

    <template #footer>
      <a-button
        key="back"
        @click="closeReapplyModal"
        :disabled="loading_reapply"
      >
        Cancelar
      </a-button>
      <a-button
        key="submit"
        type="primary"
        :loading="loading_reapply"
        :disabled="reapplyButtonBlocked"
        @click="documentReapply(id_reapply_document)"
      >
        Enviar
      </a-button>
    </template>
  </a-modal>
</template>

<script>
import {
  DownOutlined,
  UploadOutlined,
  EditOutlined,
  EyeOutlined,
  MailOutlined,
  ClockCircleOutlined
} from '@ant-design/icons-vue';
import { useRoute, useRouter } from 'vue-router';
import { reactive, ref, computed, watchEffect, onMounted } from 'vue';
import { useStore } from 'vuex';
import { createVNode } from 'vue';
import { ExclamationCircleOutlined } from '@ant-design/icons-vue';
import { Modal } from 'ant-design-vue';
import moment from 'moment';
import DependantsTable from '@/new-components/ticket/incorporation/dependants-table.vue';
import AttachedDocumentsHistory from '@/components/AttachedDocumentsHistory.vue';
import {
  FETCH_TICKET,
  FETCH_TICKET_DEPENDANTS,
  FETCH_COVERAGE_POLICY,
  GET_USER_ROLE,
  FETCH_TICKET_ID,
  FETCH_EMPLOYEE_TICKET,
  FETCH_COMPANY_BY_USER_ID,
  FETCH_TICKET_COMMENTS_ID,
  FETCH_FILE_BY_TICKET,
  PUT_REAPPLY_FILE,
  FETCH_CASES,
  POST_CASE_RECHAZO,
  FETCH_INSURANCES_COMPANY_ID,
  POST_FINISH_ALTA_INDIVIDUAL,
  POST_FILE_ANTECEDENTES,
  POST_SEND_INFO_TO_INSURANCE_COMPANY,
  FETCH_USER_BY_ID,
  POST_CANCEL_TICKET_ALTA,
  FETCH_TICKET_INCOME,
  FETCH_EMPLOYEE_COMPANY_RENT
} from '@/store/types';
import rutHelper from '@/utils/Ruts';
import DatosTicket from '@/components/Tickets/TicketData.vue';
import Comments from '@/components/Tickets/Comments.vue';
import AltaEditModal from '@/components/Tickets/AltaEditModal.vue';
import Records from '@/components/Tickets/Records.vue';
import parseDate from '@/utils/dateFormatter';
import getTagColor from '@/utils/getTagColor';
import PageHeader from '@/components/PageHeader.vue';
import Tags from '@/components/Tags.vue';
import notification from '@/utils/notifications';
import Stepper from '@/components/Tickets/Stepper.vue';

export default {
  name: 'DetalleTicketAltaIndividual',
  components: {
    Stepper,
    'datos-ticket': DatosTicket,
    'down-outlined': DownOutlined,
    comments: Comments,
    records: Records,
    UploadOutlined,
    'page-header': PageHeader,
    tags: Tags,
    EditOutlined,
    edit_modal: AltaEditModal,
    'eye-outlined': EyeOutlined,
    'mail-outlined': MailOutlined,
    'clock-circle-outlined': ClockCircleOutlined,
    'attached-documents-history': AttachedDocumentsHistory,
    'dependants-table': DependantsTable
  },
  setup() {
    // * Router, Vuex, y otras variables de vue
    const route = useRoute();
    const store = useStore();
    const router = useRouter();
    const loadingFiles = ref(false);
    const loadingCoverages = ref(false);
    const loadingHistory = ref(false);
    const loadTicket = ref(false);
    const employee_rent_required = ref(false);
    const income_amount = ref();
    // rol de  usuario
    const user_role = store.getters[GET_USER_ROLE];

    // * Id del ticket
    const id_alta = route.params.id;
    const radioCoverages = reactive([
      {
        label: 'Sí',
        value: true
      },
      {
        label: 'No',
        value: false
      }
    ]);

    // * Page Header
    // Rutas
    const routes = ref([
      {
        path: '/altas',
        breadcrumbName: 'Altas'
      },
      {
        path: '#',
        breadcrumbName: 'Individual'
      },
      {
        breadcrumbName: `#${id_alta}`
      }
    ]);

    const getBroker = async (id) => {
      const response = await store.dispatch(FETCH_USER_BY_ID, id);
      return response.data.body.name;
    };

    // * Colores por estado
    const getColorByState = (value) => {
      return getTagColor(value);
    };

    // * Obtencion de data del ticket
    // Ticket data
    const employeeData = reactive({
      name: '',
      rut: ''
    });
    const ticket = reactive({
      id_ticket: '',
      employee_id: '',
      type_ticket: '',
      last_state: '',
      id_last_state: '',
      created_at: '',
      startDate: '',
      policies: '',
      employee: {
        id: '',
        employee_fullname: '',
        employee_name: '',
        employee_secondname: '',
        employee_surname: '',
        employee_secondsurname: '',
        employee_rut: '',
        employee_mail: '',
        employee_company: '',
        employee_birthdaydate: ''
      },
      company: {
        id: '',
        name: ''
      },
      broker: {
        id: '',
        name: ''
      },
      insuranceCompany: {
        id: '',
        name: ''
      },
      coverages: []
    });

    // Comentarios data
    const comments = ref([]);

    const getTicketData = async (id) => {
      loadTicket.value = true;
      try {
        // Data del ticket
        const response_ticket = await store
          .dispatch(FETCH_TICKET_ID, id)
          .catch(() => {
            router.push({ name: 'Missing' });
          });

        const ticket_data = response_ticket.data;
        if (ticket_data.type.id != 1) {
          return router.push({ name: 'Missing' });
        }
        ticket.id_ticket = ticket_data.id;
        ticket.employee_id = ticket_data.employee.id;

        const company_id = ticket_data.company.id;

        getEmployeeIncome(ticket.employee_id, company_id);

        ticket.type_ticket = ticket_data.type.tag;
        ticket.last_state = ticket_data.states.sort((a, b) => {
          return b.id - a.id;
        })[0].state.tag;

        ticket.id_last_state = ticket_data.states.sort((a, b) => {
          return b.id - a.id;
        })[0].state.id;

        ticket.created_at = ticket_data.createdAt;
        ticket.startDate = ticket_data.startDate;
        ticket.policies = ticket_data.policies
          .reduce((acc, item) => {
            return [...acc, item.numberPolicy];
          }, '')
          .toString();
        ticket.type_ticket = ticket_data.type.tag;
        ticket.broker.id = ticket_data.broker.id;
        ticket.broker.name = ticket_data.broker.name;

        // Crear data para el stepper
        changeStepperData(id);

        // Datos del emnpleado
        const response_details = await store
          .dispatch(FETCH_EMPLOYEE_TICKET, id)
          .catch((e) => e.response);
        const employee_data = response_details.data;

        employeeData.name = `${employee_data.employee.firstName} ${employee_data.employee.firstSurname}`;
        employeeData.rut = employee_data.employee.rut;
        ticket.employee.id = employee_data.employee.id;
        ticket.employee.employee_fullname = employee_data.employee.fullname;
        ticket.employee.employee_name = employee_data.employee.firstName;
        ticket.employee.employee_secondname = employee_data.employee.secondName;
        ticket.employee.employee_surname = employee_data.employee.firstSurname;
        ticket.employee.employee_secondsurname =
          employee_data.employee.secondSurname;
        ticket.employee.employee_birthdaydate =
          employee_data.employee.birthdayDate;
        ticket.employee.employee_rut = employee_data.employee.rut;
        ticket.employee.employee_mail = employee_data.employee.email;
        ticket.employee.employee_company = employee_data.company.businessName;

        // Grupo compania
        const response_group = await store
          .dispatch(FETCH_COMPANY_BY_USER_ID, ticket.employee.id)
          .catch((e) => e.response);
        const group_comp_data = response_group.data;

        ticket.company.id = group_comp_data.groupCompanies.id;
        ticket.company.name = group_comp_data.groupCompanies.name;

        // Aseguradora que pertenece el ticket
        const insurance_response = await store.dispatch(
          FETCH_INSURANCES_COMPANY_ID,
          ticket_data.insuranceCompanyId
        );
        loadTicket.value = false;

        ticket.insuranceCompany.id = insurance_response.data.id;
        ticket.insuranceCompany.name = insurance_response.data.businessName;

        // Documentos
        await getDocumentsByTicketId(id);
        // Coberturas
        await getCoversByCompanyId(id);

        // Casos
        await getAllCasesByTicketId(id);

        // Comentarios
        await getCommentsByTicketId(id);
      } catch (err) {
        loadTicket.value = false;
        openNotificationWithIcon(
          'error',
          'Error',
          err.response.data ? err.response.data.message : 'error'
        );
        console.error('Ocurrio un error, vuelve a intentarlo mas tarde', err);
      }
    };

    /* const coverageStateSelection = () => {
      // Este metodo es para poner si se puede seleccionar la cobertura si es que el estado es "Revision bewell"
      if (ticket.last_state.toLowerCase() != 'en revision bewell') {
        return true;
      }
      return false;
    }; */

    const columnsCoverage = [
      {
        dataIndex: 'name_coverage'
      },
      {
        dataIndex: 'name_insurance_company'
      },
      {
        title: 'required',
        key: 'required',
        slots: { customRender: 'required' }
      }
    ];

    /*


    const coverages = ref([]);




    const valueTextArea = ref('');




    */

    /* const createComment = async () => {
      try {
        if (valueTextArea.value != '' || valueTextArea.value == undefined) {
          // Añado una funcion de timeout para hacer parecer que esta cargando
          // Debido a que como estamos trabajando en localhost, la data obviamente carga demasiado rapido
          // Esto da una sensacion de carga
          const comment_to_post = {
            ticket_id: id_alta,
            comment_data: valueTextArea.value
          };
          route.dispatch(POST_COMMENTS, comment_to_post).then(() => {
            valueTextArea.value = '';
            route.dispatch(FETCH_COMMENTS);
          });
        }
      } catch (error) {

      }
    }; */

    /*
      // TODO: ARREGLAR VARIABLES SIN USO
      // eslint-disable-next-line no-unused-vars
      const state_ticket = await finishAltaIndividual({
        ticket_id: id_alta,
        state_id: 3
      });

      location.reload();
    }; */

    // Llamada de metodos para obtener la data
    /* getTicketData(id_alta); */

    // * Data para la tabla de detalle del ticker

    const ticket_data_table = computed(() => {
      const values = [
        {
          key: 'Fecha de inicio de vigencia',
          value: moment(ticket.startDate, 'YYYY-MM-DD').format('DD/MM/YYYY')
        },
        {
          key: 'Nombre completo',
          value: ticket.employee.employee_fullname
        },
        {
          key: 'RUT',
          value: rutHelper.formatRut(ticket.employee.employee_rut)
        }
      ];

      if (employee_rent_required.value === true) {
        values.push({
          key: 'Renta',
          value:
            income_amount.value !== undefined
              ? `$ ${income_amount.value}`.replace(/\B(?=(\d{3})+(?!\d))/g, '.')
              : income_amount.value
        });
      }

      values.push(
        {
          key: 'Fecha de nacimiento',
          value: ticket.employee.employee_birthdaydate
            ? moment(
                ticket.employee.employee_birthdaydate,
                'YYYY-MM-DD'
              ).format('DD/MM/YYYY')
            : '-'
        },
        {
          key: 'Correo',
          value: ticket.employee.employee_mail
        },
        {
          key: 'Empresa',
          value: ticket.employee.employee_company
        },
        {
          key: 'Grupo',
          value: ticket.company.name
        },
        {
          key: 'Aseguradora',
          value: ticket.insuranceCompany.name
        },
        {
          key: 'Póliza',
          value: ticket.policies
        },
        {
          key: 'Broker',
          value: ticket.broker.name
        }
      );

      return values;
    });

    const alta_edit_object = computed(() => {
      return {
        startDate: ticket.startDate,
        firstName: ticket.employee.employee_name,
        secondName: ticket.employee.employee_secondname,
        firstSurname: ticket.employee.employee_surname,
        secondSurname: ticket.employee.employee_secondsurname,
        birthdayDate: ticket.employee.employee_birthdaydate,
        rut: ticket.employee.employee_rut,
        email: ticket.employee.employee_mail,
        incomeAmount: income_amount.value
      };
    });

    // * Stepper
    const final_state = ref('');
    const final_dates = ref({});
    const stepper_ref = ref('null');
    const reviewAlta = ref(false);
    const finishAlta = ref(false);
    const executedAlta = ref(false);
    const showOnlyCancel = ref(false);
    const enable_create_cases = ref(false);
    const parsed_dates = computed(() => {
      // Metodo para parsear las fechas a una manera mas leible para la persona

      const keys = Object.keys(final_dates.value);
      const obj = {};
      for (let i = 0; i < keys.length; i++) {
        const element = keys[i];
        obj[element] = parseDate(final_dates.value[element]); // formatDate(new Date(final_dates.value[element]));
      }

      return obj;
    });

    const changeStepperData = async (id) => {
      loadingHistory.value = true;
      try {
        // Este sirve de manera reactica para cambiar cada data del stepper, ya que tiene que ser reactivo
        // A cualquier cmabio que quiera hacer el usuario con los estados del ticket

        const ticket_response = await store
          .dispatch(FETCH_TICKET_ID, id)
          .catch((e) => e.response);

        const ticket_data = ticket_response.data;

        ticket.id_last_state =
          ticket_data.states[ticket_data.states.length - 1].state.id;

        // Paso los estados que estubo el ticket al historial
        records_steps.length = 0;
        for (let i = 0; i < ticket_data.states.length; i++) {
          const element = ticket_data.states[i];
          records_steps.push({
            title: element.state.tag,
            description: element.createdAt
          });
        }

        // 1.- Primero obtengo el ultimo estado del cual esta el ticket, obviamente este puede cambiar
        let last_ticket_state = '';
        last_ticket_state = ticket_data.states.sort((a, b) => {
          return b.id - a.id;
        })[0].state.tag;

        // Transformo el ultimo estado en un texto que pueda interpretar el stepper
        let step = getStateType(last_ticket_state);

        // En caso de que el paso quede en cancelado, tengo que obtener el penultimo estado
        // que estubo correcto para pasarle a esta propiedad el cancelado para que se muestre
        // con una X en el paso, asi es como lo toma Ant Desing
        if (step === 'cancelado') {
          // Obtengo entonces el penultimo estado

          const last_before = ticket_data.states.sort((a, b) => {
            return b.id - a.id;
          })[1].state.tag;
          step = getStateType(last_before);
        }

        final_state.value = step;

        // 2.- Tomo los estados ordenados por fecha, despues eliminare los siguientes si es que un estado
        // se devolvio al anterior y hay mas estados delante del ultimo estado donde quedo
        /*
              (X)            (((X)))                       (X)               ()
                        [este es el ultimo
                          estado ya que
                          se devolvio
                          por alguna razon]
                                                [Este deberia de
                                                eliminarse de la
                                                lista de estados
                                                para no mostrarlo
                                                en el stepper]
            CREADO   EN REVISION BEWELL   EN REVISION ASEGURADORA   EJECUTADO
        */

        const sorted_dates = ticket_data.states.sort(function (a, b) {
          return a.id - b.id;
        });

        const final_obj = {};
        for (let i = 0; i < sorted_dates.length; i++) {
          const element = sorted_dates[i];
          const state_name = getStateType(element.state.tag);
          final_obj[state_name] = element.createdAt;
        }

        // Elimino estados que quedaron por delante del ultimo estado como explique arriba
        // Con el ultimo estado del ticket, valido si hay estados mas adelantes los cuales deba eliminar

        switch (final_state.value) {
          case 'creado':
            delete final_obj['revision_bewell'];
            delete final_obj['revision_aseguradora'];
            delete final_obj['ejecutado'];
            break;
          case 'revision_bewell':
            delete final_obj['revision_aseguradora'];
            delete final_obj['ejecutado'];
            break;
          case 'revision_aseguradora':
            delete final_obj['ejecutado'];
            break;
        }

        // En caso de que el ultimo estado este en cancelado, voy a dejar el penultimo estado en marcado
        // Para que se marque la x ahi
        // Voy a preguntar cual es el ultimo estado nuevamente
        const last = ticket_data.states.sort((a, b) => {
          return b.id - a.id;
        })[0].state.tag;

        // Si el ultimo estado esta en 'En revision bewell' osea 'revision_bewell', podemos darle al boton de revisado
        reviewAlta.value = final_state.value == 'revision_bewell';
        finishAlta.value = final_state.value == 'revision_aseguradora';

        // Si el ultimo estado del ticket fue cancelado, la fecha de este cancelado, se la paso al penultimo estado
        // asi para que se muestre en el penultimo estado que se cancelo con su fecha correspondiente
        const keys_states = Object.keys(final_obj);

        // Escondo el boton de cancelar que se encuentra solo

        // Oculto el dropdown de los casos
        enable_create_cases.value = false;

        if (last === 'Cancelado') {
          // Llamo al metodo que cancela el estado
          cancelStep();
          final_obj[keys_states[keys_states.length - 2]] =
            final_obj[keys_states[keys_states.length - 1]];
          delete final_obj['cancelado'];

          // Para que no se muestren los botones
          reviewAlta.value = false;
          finishAlta.value = false;

          // Muestre el tag del estado con el color si o si
          executedAlta.value = true;
          showOnlyCancel.value = false;
          // Texto de cancelado
          ticket.last_state = 'Cancelado';
        } else if (last === 'Ejecutado') {
          finishStep();
          executedAlta.value = true;
          ticket.last_state = 'Ejecutado';
        } else if (last === 'Creado') {
          // Si el estado esta en cancelado, muestro solo el cancelar

          showOnlyCancel.value = true;
          // Paso el nombre del estado
          ticket.last_state = 'Creado';
        } else {
          ticket.last_state = last;
          // Si el ultimo estado es 'revision_aseguradora' Voy a poder crear casos por antecedentes
          if (last === 'En revision Aseguradora') {
            enable_create_cases.value = true && user_role != 'rrhh';
          }
        }
        final_dates.value = final_obj;
        loadingHistory.value = false;
      } catch (error) {
        loadingHistory.value = false;
        openNotificationWithIcon(
          'error',
          'Error',
          error.response.data ? error.response.data.message : 'error'
        );
        console.error('error', error);
      }
    };

    const getStateType = (state) => {
      // Lo transformo en un texto que el stepper lo sepa interpretar, ya que los nombres de los estados
      // Estan en cambio aun, los estados en el stepper siempre van a estar igual, pero no aca
      // la regla es la siguiente

      switch (state) {
        case 'Creado': // Asi viene desde el backend
          return 'creado'; // Asi es como lo toma el stepper y lo marca en su lugar
        case 'En revision Bewell':
          return 'revision_bewell';
        case 'En revision Aseguradora':
          return 'revision_aseguradora';
        case 'Ejecutado':
          return 'ejecutado';
        case 'Cancelado':
          return 'cancelado';
      }
    };

    // * Formatear fecha

    const cancelStep = async () => {
      // Metodo para cancelar el stepper en un cierto paso
      stepper_ref.value?.cancelStep();
    };
    const classFinish = ref('');
    const finishStep = () => {
      stepper_ref.value?.finishStep();
    };
    // * Comentarios
    const getCommentsByTicketId = async (id) => {
      const response_comments = await store
        .dispatch(FETCH_TICKET_COMMENTS_ID, id)
        .catch((e) => e.response);

      for (let i = 0; i < response_comments.data.length; i++) {
        const element = response_comments.data[i];
        const brokerName = await getBroker(response_comments.data[i].brokerId);
        comments.value.push({
          description: parseDate(element.createdAt),
          title: element.message,
          author: brokerName
        });

        records_comments.push({
          description: element.createdAt,
          title: 'Nuevo comentario'
        });
      }
    };

    const getNewCommment = async (value) => {
      const { description } = value;
      const { brokerId } = value;
      const brokerName = await getBroker(brokerId);

      comments.value.push({
        title: value.title,
        description: parseDate(description),
        author: brokerName
      });

      records_comments.push({
        description,
        title: 'Nuevo comentario'
      });
    };

    // * Historial

    const records_steps = reactive([]);
    const records_comments = reactive([]);
    const records_files = reactive([]);

    const ordered_records = computed(() => {
      const rows = [];
      const records = [records_steps, records_comments, records_files];

      records.forEach((element) => {
        for (let i = 0; i < element.length; i++) {
          const el = element[i];
          if (el.title == 'Ejecutado') {
            rows.push({
              description: new Date(el.description),
              title: el.title,
              status: 'finish'
            });
          } else if (el.title == 'Cancelado') {
            rows.push({
              description: new Date(el.description),
              title: el.title,
              status: 'error'
            });
          } else {
            rows.push({
              description: new Date(el.description),
              title: el.title
            });
          }
        }
      });

      rows.sort(function (a, b) {
        return a.description - b.description;
      });

      for (let i = 0; i < rows.length; i++) {
        const element = rows[i];
        const fecha = parseDate(element.description); // formatDate(element.description);
        rows[i].description = fecha;
      }
      return rows;
    });

    // * ### CASOS ###
    // * Obtener todos los casos

    const cases = ref([]);

    const getAllCasesByTicketId = async (id) => {
      cases.value = [];

      const response_cases = await store
        .dispatch(FETCH_CASES, id)
        .catch((e) => e.response);

      for (let i = 0; i < response_cases.data.length; i++) {
        const obj = {
          id: '',
          tag: '',
          state: '',
          typeCaseId: 0
        };

        const caso = response_cases.data[i];

        obj.id = caso.id;
        obj.tag = caso.states
          .sort((a, b) => {
            return b.id - a.id;
          })[0]
          .state.id.toString();
        obj.state = caso.typeCase.tag;
        obj.typeCaseId = caso.typeCaseId;

        cases.value.push(obj);
      }
    };

    const cases_sorted = computed(() => {
      return cases.value.reduce((acc, item) => {
        return [...acc, item.state];
      }, []);
    });

    // * Caso de rechazo
    const visible_rechazo_modal = ref(false); // Abrir y cerrar el modal para crear el caso
    const rechazo_body = ref('');
    const rechazo_file = ref([]);
    const rechazo_loading = ref(false);
    const showSucess = ref(false);
    const success_title = ref('');
    const success_message = ref('');
    const showError = ref(false);
    const error_title = ref('');
    const error_message = ref('');

    const openModalRechazo = () => {
      // Este metodo abre le modal de rechazo
      visible_rechazo_modal.value = true;
      rechazo_body.value = `Estimado cliente adjuntamos resolución de la compañía aseguradora, de la solicitud de incorporación del colaborador ${employeeData.name} ${employeeData.rut}, informando su rechazo según normativa vigente. Ante cualquier duda, por favor contactar con su ejecutivo comercial.`;
    };

    const sendRechazoCase = async () => {
      // Hago el efecto de carga
      rechazo_loading.value = true;

      // Enviar el caso de rechazo
      showSucess.value = false;
      const formData = new FormData();
      formData.append('ticketId', ticket.id_ticket);
      formData.append('brokerId', store.state.user.auth.id);
      formData.append('message', rechazo_body.value);
      formData.append('file', rechazo_file.value[0]);
      formData.append('insuranceCompanyId', ticket.insuranceCompany.id);
      formData.append('name', 'Carta de rechazo');

      try {
        await store.dispatch(POST_CASE_RECHAZO, formData);

        rechazo_body.value = '';
        rechazo_file.value = [];

        getAllCasesByTicketId(id_alta);
        changeStepperData(id_alta);

        // Enviar un mensaje de correcto
        openNotificationWithIcon(
          'success',
          'Correcto',
          'Caso de rechazo enviado correctamente'
        );

        showSucess.value = true;
        success_title.value = 'Creación de caso de rechazo';
        success_message.value = 'Mensaje enviado';
      } catch (error) {
        const { message } = error.response.data
          ? error.response.data.message
          : 'error';

        // Enviar mensaje de error
        openNotificationWithIcon('error', 'Error', message);

        showError.value = true;
        error_title.value = 'Creación de caso de rechazo';
        error_message.value = 'No se ha podido generar el caso';
      }

      // Parar el efecto de carga
      rechazo_loading.value = false;

      // Cerrar el modal
      visible_rechazo_modal.value = false;
    };

    const handleRemoveRechazo = (file) => {
      // Eliminar el archivo subido al modal
      const index = rechazo_file.value.indexOf(file);
      const newFileList = rechazo_file.value.slice();
      newFileList.splice(index, 1);
      rechazo_file.value = newFileList;
    };

    const beforeUploadRechazo = (file) => {
      // Antes de que se suba, dejarlo en false para que no se suba automaticamente.
      // Tener en cuenta que se puede subir solo un archivo, asi que si se sube uno nuevo, reemplazarlo por el que ya existe
      rechazo_file.value = [file];
      return false;
    };

    // * Casos por antecedentes
    const visible_antecedentes_modal = ref(false); // Abrir y cerrar el modal para crear el caso
    const antecedentes_body = ref('');
    const antecedentes_file = ref([]);
    const loading_antecedentes = ref(false);

    const openModalAntecedentes = () => {
      // Este metodo abre le modal de rechazo
      visible_antecedentes_modal.value = true;
      antecedentes_body.value = '';
    };

    const sendAntecedentesCase = async () => {
      // Mostrar el boton cargando
      loading_antecedentes.value = true;

      try {
        const formData = new FormData();
        formData.append('ticketId', id_alta);
        formData.append('brokerId', store.state.user.auth.id);
        formData.append('message', antecedentes_body.value);
        formData.append('insuranceCompanyId', ticket.insuranceCompany.id);
        formData.append('file', antecedentes_file.value[0]);
        formData.append('name', 'Caso por antecedentes');

        // await store.dispatch(POST_FILE_ANTECEDENTES);
        await store.dispatch(POST_FILE_ANTECEDENTES, formData);

        antecedentes_body.value = '';
        antecedentes_file.value = [];

        getAllCasesByTicketId(id_alta);
        changeStepperData(id_alta);

        // Mensaje de correcto
        openNotificationWithIcon(
          'success',
          'Correcto',
          'Caso de antecedentes enviado correctamente'
        );
      } catch (error) {
        openNotificationWithIcon(
          'error',
          'Error',
          error.response.data ? error.response.data.message : 'error'
        );
      }

      // Paro el modal cargando
      loading_antecedentes.value = false;

      // Cerrar el modal
      visible_antecedentes_modal.value = false;
    };

    const handleRemoveAntecedentes = (file) => {
      // Eliminar el archivo subido al modal
      const index = antecedentes_file.value.indexOf(file);
      const newFileList = antecedentes_file.value.slice();
      newFileList.splice(index, 1);
      antecedentes_file.value = newFileList;
    };

    const beforeUploadAntecedentes = (file) => {
      // Antes de que se suba, dejarlo en false para que no se suba automaticamente.
      // Tener en cuenta que se puede subir solo un archivo, asi que si se sube uno nuevo, reemplazarlo por el que ya existe

      antecedentes_file.value = [file];
      const fileSize = file.size / 1024 / 1024;
      if (antecedentes_file.value.length == 1) {
        if (fileSize > 5) {
          openNotificationWithIcon(
            'error',
            'Error',
            'Excede el tamaño maximo de archivo (5mb)'
          );
          handleRemoveAntecedentes(file);
        }
      }

      return false;
    };

    // * Documentos
    const files = ref([]);
    const selected_files = ref([]);

    const getDocumentsByTicketId = async (id) => {
      loadingFiles.value = true;
      try {
        // Lo inicializo en 0, ya que cuando cambie de estado el ticket tengo que obtener los archivos otra vez
        // asi que en caso de llamar denuevo a este metodo, obtengo la lista de archivos nuevamente para mostrarlo

        files.value.length = 0;
        records_files.length = 0;

        const response_files = await store.dispatch(FETCH_FILE_BY_TICKET, id);

        // Obtengo la data
        for (let i = 0; i < response_files.data.length; i++) {
          const element = response_files.data[i];

          const obj = {
            key: i + 1,
            id: element.id,
            name: element.name,
            company: ticket.insuranceCompany.name,
            status: true,
            options: {
              seeMore: element.ticketFileUrl,
              fileId: element.id,
              // canRequest: element.filePath.includes('dform/signed-pdf') || element.fileInsuranceCompanyId != null,
              canRequest: element.fileInsuranceCompanyId != null,
              requestCounter: element.requestCounter
            }
          };
          files.value.push(obj);
          // Lo guardo tambien en el historial
          records_files.push({
            description: element.createdAt,
            title: 'Documento enviado'
          });
        }
        loadingFiles.value = false;
      } catch (error) {
        loadingFiles.value = false;
        console.error('error', error);
      }
    };

    const files_total_data = computed(() => {
      if (final_state.value === 'creado') {
        return [];
      }
      return files.value;
    });

    // Resolicitud de documentos
    const showReapplyDoc = ref(false);
    const id_reapply_document = ref();
    const reapply_text = ref(
      'Estimado/a te informamos que recepcionamos tu formulario de incorporación y hemos detectado que se encuentra incompleto en la sección:'
    );
    const loading_reapply = ref(false);

    const openReapplyModal = (id_document) => {
      id_reapply_document.value = id_document;
      showReapplyDoc.value = true;
    };

    const closeReapplyModal = () => {
      id_reapply_document.value = 0;
      showReapplyDoc.value = false;
      reapply_text.value =
        'Estimado/a te informamos que recepcionamos tu formulario de incorporación y hemos detectado que se encuentra incompleto en la sección:';
    };

    const documentReapply = async (id_document) => {
      loading_reapply.value = true;
      try {
        // ! Aca debo mandar el mensaje de este => !!!!reapply_text!!!!
        await store.dispatch(PUT_REAPPLY_FILE, {
          id: id_document,
          payload: {
            message: reapply_text.value
          }
        });

        showSucess.value = true;
        success_title.value = 'Mensaje enviado';
        success_message.value =
          'Su solicitud de documento a sido enviada con exito';
        reapply_text.value = '';
      } catch (error) {
        showError.value = true;
        error_title.value = 'Error';
        error_message.value = openNotificationWithIcon(
          'error',
          'Error',
          error.response.data
        );
      }
      loading_reapply.value = false;
      closeReapplyModal();
      getDocumentsByTicketId(id_alta);
    };

    const reapplyButtonBlocked = computed(() => {
      const backup_text =
        'Estimado/a te informamos que recepcionamos tu formulario de incorporación y hemos detectado que se encuentra incompleto en la sección:';

      if (reapply_text.value != backup_text && reapply_text.value != '') {
        return false;
      }

      return true;
    });

    const columnsFiles = [
      {
        dataIndex: 'name'
      },
      {
        dataIndex: 'company'
      },
      {
        dataIndex: 'options',
        slots: { customRender: 'options' }
      }
    ];

    // * Terminar la seleccion
    const loading_state = ref(false);

    const openReviewModal = () => {
      Modal.confirm({
        title: () => '¿Deseas enviar documentos a compañía aseguradora?',
        icon: () => createVNode(ExclamationCircleOutlined),
        okText: () => 'Sí',
        cancelText: () => 'No',
        onOk() {
          finishSelection();
        },
        onCancel() {}
      });
    };
    const changeCheckbox = (selectedRowKeys, selectedRows) => {
      selected_files.value.length = 0;
      for (let i = 0; i < selectedRows?.length; i++) {
        const element = selectedRows[i];
        selected_files.value.push(element);
      }
    };
    const idCheckbox = ref([]);
    const getCheckbox = (record) => {
      if (ticket.id_last_state == 8 || ticket.id_last_state == 3) {
        idCheckbox.value.push(record.key);
        return {
          disabled: false
        };
      }
      if (
        final_state.value == 'revision_aseguradora' &&
        ticket.id_last_state == 4
      ) {
        idCheckbox.value.push(record.key);
        return {
          disabled: false
        };
      }
    };

    const rowSelectionForm = computed(() => {
      if (
        ticket.id_last_state == 8 ||
        ticket.id_last_state == 3 ||
        ticket.id_last_state == 4
      ) {
        return {
          onChange: changeCheckbox,
          getCheckboxProps: getCheckbox,
          selectedRowKeys: idCheckbox.value
        };
      }
      return {
        onChange: changeCheckbox,
        getCheckboxProps: getCheckbox
        // selectedRowKeys:0,
      };
    });

    // const getCheckboxProps = () => {
    //   if(final_state.value=='revision_aseguradora'||final_state.value=='ejecutado'){
    //     return ({
    //       disabled:true,
    //     });
    //   }
    // };
    const checkDisabled = ref(true);
    const mykey = ref(0);
    watchEffect(() => {
      if (ticket.id_last_state == 8 || ticket.id_last_state == 3) {
        mykey.value++;
      }
    });

    const finishSelection = async () => {
      // Dejo cargando el boton
      loading_state.value = true;

      let cover_selected = true;
      // let files_selected = true;

      for (const i in ticket.coverages) {
        if (ticket.coverages[i].selected === '') {
          cover_selected = false;
        }
      }

      if (!cover_selected) {
        openNotificationWithIcon(
          'error',
          'Coberturas sin seleccionar',
          'Para avanzar debes seleccionar coberturas'
        );
      }

      // Comparo que los archivos que se muestran en la seccion "Formularios adjuntos" esten seleccionados
      if (selected_files.value.length != files.value.length) {
        // files_selected = true;

        // Hago que se demore 100 milisegundos solo para que aparesca uno despues del otro
        setTimeout(function () {
          openNotificationWithIcon(
            'error',
            'Error',
            'Revise y seleccione los formularios adjuntos'
          );
        }, 100);
        // loading_state.value=false;
      }

      if (cover_selected && selected_files.value.length) {
        try {
          // Envio a la data de insurance company
          // for (let i = 0; i < ticket.coverages.length; i++) {
          //   const element = ticket.coverages[i];
          //   if (element.selected) {
          //     coverage.value = element.id_coverage;
          //
          //     // const obj = {
          //     //   employeeId: ticket.employee.id,
          //     //   coverageId: [element.id_coverage],
          //     //   startDate: new Date().toISOString(), // TODO: Seria una fecha desde el 1 de cada mes
          //     // };
          //   }
          // }
          const coverage = ticket.coverages
            .filter((coverage) => coverage.selected === true)
            .map((e) => e.id_coverage);
          await store.dispatch(POST_SEND_INFO_TO_INSURANCE_COMPANY, {
            id: id_alta,
            payload: { coverages: coverage }
          });

          // const respCoveragePolicy = await ticketId(route.params.id);
          // const PolicyforCoverage = [].concat.apply([],respCoveragePolicy.data.policies.map(e=>e).concat().map(e=>e.coverages)).map(f=>({
          //   ...f,
          //   insuranceCompany:respCoveragePolicy.data.insuranceCompany
          // }));
          // Aca paso todas las coberturas tanto seleccionadas como obligatorias hacia el asegurado en cuestion

          // Cambiar el estado de ticket a "En revision aseguradora", que el id seria el 8

          // await finishAltaIndividual(id_alta); TODO: VER ENDPOINT Y FLUJO
          // await createStateTicket({'ticketId':id_alta,'stateId':8});

          await changeStepperData(id_alta);

          await getCoversByCompanyId(id_alta);

          await getDocumentsByTicketId(id_alta);
          openNotificationWithIcon(
            'success',
            'Correcto',
            'Información enviada satisfactoriamente'
          );
        } catch (error) {
          if (error.response.data.message == 'La relacion ya existe') {
            openNotificationWithIcon(
              'error',
              'Error',
              'Ya existen estas coberturas asociadas a este asegurado'
            );
          } else {
            openNotificationWithIcon(
              'error',
              'Error',
              error.response.data ? error.response.data.message : 'error'
            );
          }
        }
      }
      loading_state.value = false;
    };

    const openNotificationWithIcon = (type, title, message) => {
      notification(type, title, message);
    };

    const redirectToFile = (url) => {
      window.open(url, '_blank');
    };

    // * Historial de documentos adjuntos
    const attached_documents_history_ref = ref();
    const openAttachedDocumentsHistoryModal = (id) => {
      attached_documents_history_ref.value.openModal(id);
    };

    // * Editar datos de alta
    const edit_modal_ref = ref();
    const openAltaEditModal = () => {
      edit_modal_ref.value.openModal();
    };

    // * Cancelar ticket
    const openCancelModal = () => {
      Modal.confirm({
        title: () => '¿Estás seguro que deseas cancelar el ticket de alta?',
        icon: () => createVNode(ExclamationCircleOutlined),
        okText: () => 'Sí',
        cancelText: () => 'No',
        onOk() {
          cancelTicket();
        },
        onCancel() {}
      });
    };

    const cancelTicket = async () => {
      loading_state.value = true;
      try {
        // Hago la request hacia la base de datos
        await store.dispatch(POST_CANCEL_TICKET_ALTA, id_alta);

        // Actualizo nuevamente el stepper para que se coloque automaticamente
        await changeStepperData(id_alta);
        await getCoversByCompanyId(route.params.id);

        loading_state.value = false;
      } catch (error) {
        loading_state.value = false;
        openNotificationWithIcon(
          'error',
          'Error',
          error.response.data ? error.response.data.message : 'error'
        );
        console.error('error', error);
      }
    };

    // * Ejecutar ticket
    const openExecuteodal = () => {
      Modal.confirm({
        title: () => '¿Estás seguro que deseas ejecutar el ticket?',
        icon: () => createVNode(ExclamationCircleOutlined),
        okText: () => 'Sí',
        cancelText: () => 'No',
        onOk() {
          ejecutar();
        },
        onCancel() {}
      });
    };

    // TODO: Cambiar en un futuro al endpoint que me va a mandar Lepe
    const ejecutar = async () => {
      loading_state.value = true;
      try {
        // TODO: VER ENDPOINT Y FLUJO
        await store.dispatch(POST_FINISH_ALTA_INDIVIDUAL, id_alta);
        // await createStateTicket({
        //   ticketId: id_alta,
        //   stateId: 3
        // });

        // Actualizo nuevamente el stepper para que se coloque automaticamente
        await changeStepperData(id_alta);

        // TODO: Cambiar el estado del empleado a asegurado
        loading_state.value = false;
      } catch (error) {
        loading_state.value = false;
        openNotificationWithIcon(
          'error',
          'Error',
          error.response.data ? error.response.data.message : 'error'
        );
        console.error('error', error);
      }
    };

    // * Coberturas
    const getCoversByCompanyId = async (id) => {
      loadingCoverages.value = true;
      ticket.coverages.length = 0;

      // En caso de que el estado del ticket no sea "Revision BeWell" obtengo las coberturas que tiene el empleado
      const respCoveragePolicy = await store.dispatch(
        FETCH_COVERAGE_POLICY,
        id
      );
      const PolicyforCoverage = [].concat
        .apply(
          [],
          respCoveragePolicy.data.map((e) =>
            e.coverages.map((f) => ({
              ...f,
              insuranceCompany: respCoveragePolicy.data.reduce((acc, item) => {
                return [...acc, item.insuranceCompany];
              }, [])[0]
            }))
          )
        )
        .sort((f, b) => f.required - b.required);

      if (
        final_state.value == 'revision_aseguradora' ||
        final_state.value == 'ejecutado'
      ) {
        const respCoveragePolicy = await store.dispatch(
          FETCH_EMPLOYEE_TICKET,
          id
        );
        const PolicyforCoverage =
          respCoveragePolicy.data.employee.coverages.map((e) => e);

        for (let j = 0; j < PolicyforCoverage.length; j++) {
          const element = PolicyforCoverage[j];
          ticket.coverages.push({
            key: j + 1,
            id_coverage: element.coverageId,
            name_coverage: element.coverages.name,
            required: PolicyforCoverage[j].required ? true : '',
            selected: true
          });
        }
      } else if (
        final_state.value == 'revision_bewell' ||
        ticket.last_state == 'Cancelado'
      ) {
        let d = PolicyforCoverage.filter((e) => e.required == true);

        if (ticket.last_state == 'Cancelado') {
          d = [];
          for (let j = 0; j < d.length; j++) {
            const cover = [];
            // Si la cobertura es obligatoria o no

            ticket.coverages.push({
              key: j,
              id_coverage: cover.id,
              name_coverage: cover.name,
              id_insurance_company: cover.insuranceCompany.id,
              name_insurance_company: cover.insuranceCompany.businessName,
              required: true,
              selected: true //
            });
          }
        } else {
          for (let j = 0; j < PolicyforCoverage.length; j++) {
            const cover = PolicyforCoverage[j];
            const selection = cover.required;

            ticket.coverages.push({
              key: j,
              id_coverage: cover.id,
              name_coverage: cover.name,
              id_insurance_company: cover.insuranceCompany.id,
              name_insurance_company: cover.insuranceCompany.businessName,
              required: selection,
              selected: PolicyforCoverage[j].required ? true : '' //
            });
          }
        }
      }
      loadingCoverages.value = false;
    };
    const redirectCase = (value) => {
      const ticketId = value.id;

      const ticketType =
        value.state === 'Out of Time'
          ? 'fuera_de_tiempo'
          : value.state.toLowerCase();

      switch (ticketType) {
        case 'antecedentes':
          router.push({ name: 'DetalleCaso', params: { id: ticketId } });
          break;
        case 'rechazo':
          router.push({ name: 'DetalleRechazo', params: { id: ticketId } });
          break;
        case 'fuera de tiempo: formulario de incorporación':
          router.push({ name: 'DetalleFueraTiempo', params: { id: ticketId } });
          break;
        case 'fuera de tiempo: falta de antecedentes':
          router.push({ name: 'DetalleFueraTiempo', params: { id: ticketId } });
          break;
        case 'fuera de tiempo: alta individual':
          router.push({ name: 'DetalleFueraTiempo', params: { id: ticketId } });
          break;
        case 'fuera de tiempo: alta individual (broker)':
          router.push({ name: 'DetalleFueraTiempo', params: { id: ticketId } });
          break;
        case 'fuera de tiempo: formulario de modificación':
          router.push({ name: 'DetalleFueraTiempo', params: { id: ticketId } });
          break;
      }
    };

    const getRentRequirement = () => {
      store
        .dispatch(FETCH_TICKET_INCOME, id_alta)
        .then((resp) => {
          employee_rent_required.value = resp.data.isIncomeRequired;
        })
        .catch((error) => {
          notification('error', 'Error', error.response.data.messsage);
        });
    };

    const getEmployeeIncome = (employeeId, companyId) => {
      store
        .dispatch(FETCH_EMPLOYEE_COMPANY_RENT, {
          employeeId: employeeId,
          companyId: companyId
        })
        .then((resp) => {
          if (resp.data != null) {
            income_amount.value = resp.data.incomeAmount;
          } else {
            income_amount.value = 0;
          }
        })
        .catch((error) => {
          notification('error', 'Error', error.response.data.messsage);
        });
    };

    onMounted(() => {
      store.dispatch(FETCH_TICKET, route.params.id);
      getRentRequirement();
      getTicketData(id_alta);
      store.dispatch(FETCH_TICKET_DEPENDANTS, route.params.id);
    });

    return {
      // test,
      idCheckbox,
      mykey,
      classFinish,
      finishStep,
      routes,
      checkDisabled,
      changeCheckbox,
      getCheckbox,
      employeeData,
      id_alta,
      finishSelection,
      getColorByState,
      stepper_ref,
      parsed_dates,
      final_state,
      ticket,
      ticket_data_table,
      columnsCoverage,
      cases,
      cases_sorted,
      comments,
      getNewCommment,
      rowSelectionForm,
      columnsFiles,
      files,
      visible_rechazo_modal,
      rechazo_body,
      openModalRechazo,
      rechazo_file,
      sendRechazoCase,
      handleRemoveRechazo,
      beforeUploadRechazo,
      reviewAlta,
      visible_antecedentes_modal,
      antecedentes_body,
      openModalAntecedentes,
      sendAntecedentesCase,
      handleRemoveAntecedentes,
      beforeUploadAntecedentes,
      antecedentes_file,
      showSucess,
      showError,
      files_total_data,
      cancelTicket,
      ejecutar,
      enable_create_cases,
      showOnlyCancel,
      executedAlta,
      finishAlta,
      loading_antecedentes,
      rechazo_loading,
      ordered_records,
      openCancelModal,
      loading_state,
      openReviewModal,
      openExecuteodal,
      redirectCase,
      documentReapply,
      user_role,
      showReapplyDoc,
      id_reapply_document,
      reapply_text,
      openReapplyModal,
      closeReapplyModal,
      loading_reapply,
      reapplyButtonBlocked,
      success_title,
      success_message,
      error_title,
      error_message,
      loadingFiles,
      loadingCoverages,
      loadingHistory,
      loadTicket,
      radioCoverages,
      edit_modal_ref,
      openAltaEditModal,
      alta_edit_object,
      getTicketData,
      records_files,
      redirectToFile,
      attached_documents_history_ref,
      openAttachedDocumentsHistoryModal,
      employee_rent_required
    };
  }
};
</script>

<style>
.ant-upload-list-item-card-actions {
  top: 0;
}
</style>
