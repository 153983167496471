<template>
  <div v-if="$route.name === 'CobranzaFacturacion'">
    <div>
      <page-header
        :breadcrumRoutes="[
          {
            breadcrumbName: 'Cobranza y facturación'
          }
        ]"
        title="Cobranza y facturación"
      >
        <template #buttons>
          <a-button type="primary" @click="openNewCollectionModal">
            Iniciar cobranza
          </a-button>
        </template>
      </page-header>

      <resume-header
        title="Resumen total"
        :category-values="summaryData"
        :total-progress="generalProgress"
      />

      <a-card class="mt-2">
        <div class="mt-2">
          <a-typography-title :level="4" class="text-gray-8 mb-0 left-aligned">
            Stock cobranza y facturación
          </a-typography-title>

          <a-tooltip color="#05045D">
            <template #title>
              Un ticket puede contener más de una factura por póliza
            </template>
            <exclamation-circle-outlined style="font-size: 20px" class="ml-2" />
          </a-tooltip>

          <a-radio-group :value="tab" @change="onChangeTabs" class="ml-4">
            <a-radio-button value="#all">Todas</a-radio-button>
            <a-radio-button value="#forms">Formularios</a-radio-button>
          </a-radio-group>

          <div class="right-aligned">
            <a-button
              size="large"
              class="button_with_icon"
              @click="stockDownload"
            >
              <template #icon>
                <download-outlined />
              </template>
              Descargar stock
            </a-button>
          </div>

          <div style="clear: both"></div>
        </div>

        <div class="mt-3">
          <tickets v-if="tab === '#all'" />
          <forms v-else />
        </div>
      </a-card>

      <a-modal
        v-model:visible="showNewCollectionModal"
        title="Inicio de cobranza"
        @cancel="newCollectionFormCancel"
      >
        <a-typography-title :level="5">
          Selecciona un periodo para iniciar el proceso de cobranza
        </a-typography-title>
        <br />
        <div>
          <a-form
            name="form"
            ref="newCollectionFormRef"
            :model="newCollectionFormData"
            :rules="newCollectionFormRules"
            layout="vertical"
          >
            <a-form-item required name="date" label="Selecciona periodo">
              <a-month-picker
                v-model:value="newCollectionFormData.date"
                placeholder="AAAA-MM"
                style="width: 100% !important"
              />
            </a-form-item>
          </a-form>
        </div>
        <template #footer>
          <a-button
            key="submit"
            type="primary"
            @click="newCollectionFormOnSubmit"
          >
            Iniciar
          </a-button>
        </template>
      </a-modal>
    </div>
  </div>

  <router-view :key="$route.path"></router-view>
</template>

<script>
import {
  DownloadOutlined,
  ExclamationCircleOutlined
} from '@ant-design/icons-vue';
import { useStore } from 'vuex';
import { reactive, ref, defineAsyncComponent, onMounted, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import PageHeader from '@/components/PageHeader.vue';

const CollectionBillingForms = defineAsyncComponent(() =>
  import('@/components/Tables/CollectionBillingForms.vue')
);
const CollectionBillingTickets = defineAsyncComponent(() =>
  import('@/components/Tables/CollectionBillingTickets.vue')
);

import {
  FETCH_ALL_COLLECTION_FORM_SUMMARY,
  GET_ALL_COLLECTION_FORM_SUMMARY,
  FETCH_ALL_COLLECTION_SUMMARY,
  GET_ALL_COLLECTION_SUMMARY,
  FETCH_COLLECTION_STOCK_DOWNLOAD,
  FETCH_COLLECTION_FORM_DOWNLOAD
} from '@/store/types';
import notification from '@/utils/notifications';
import ResumeHeader from '@/components/resume header/ResumeHeader.vue';

export default {
  name: 'CollectionBilling',
  components: {
    'page-header': PageHeader,
    tickets: CollectionBillingTickets,
    forms: CollectionBillingForms,
    'download-outlined': DownloadOutlined,
    'exclamation-circle-outlined': ExclamationCircleOutlined,
    'resume-header': ResumeHeader
  },
  setup() {
    const store = useStore();
    const route = useRoute();
    const router = useRouter();

    const tab = ref('#all');
    if (route.hash) {
      tab.value = route.hash;
    }

    watch(tab, (newTab, oldTab) => {
      if (newTab !== oldTab) {
        getSummary();
      }
    });

    const onChangeTabs = async (value) => {
      tab.value = value.target.value;
      router.replace({
        name: 'CobranzaFacturacion',
        query: route.query,
        hash: tab.value
      });
    };

    const summaryData = ref({});
    const generalProgress = ref(0);

    const getSummary = () => {
      if (tab.value === '#all') {
        getCollectionsSummary();
      } else {
        getCollectionGroupsSummary();
      }
    };

    const getPercentage = (total, number) => {
      return total > 0 ? Math.round((number * 100) / total) : 0;
    };

    const getCollectionGroupsSummary = async () => {
      try {
        await store.dispatch(FETCH_ALL_COLLECTION_FORM_SUMMARY);
        const { total, proccesing, loaded, cancelled, created, progress } =
          store.getters[GET_ALL_COLLECTION_FORM_SUMMARY];

        generalProgress.value = progress;

        summaryData.value = [
          {
            name: 'Solicitudes',
            first_display: total,
            second_display: total
          },
          {
            name: 'En proceso',
            first_display: proccesing,
            second_display: `${getPercentage(total, proccesing)}%`
          },
          {
            name: 'Creadas',
            first_display: created,
            second_display: `${getPercentage(total, created)}%`
          },
          {
            name: 'Cargadas',
            first_display: loaded,
            second_display: `${getPercentage(total, loaded)}%`
          },
          {
            name: 'Canceladas',
            first_display: cancelled,
            second_display: `${getPercentage(total, cancelled)}%`
          }
        ];
      } catch (error) {
        notification('error', 'Error', error.response.data.message);
      }
    };

    const getCollectionsSummary = async () => {
      try {
        await store.dispatch(FETCH_ALL_COLLECTION_SUMMARY);
        const {
          total,
          created,
          approved,
          bewellValidation,
          hrValidation,
          progress
        } = store.getters[GET_ALL_COLLECTION_SUMMARY];
        generalProgress.value = progress;

        const obj = [
          {
            name: 'Total',
            first_display: total,
            second_display: total
          },
          {
            name: 'Creadas',
            first_display: created,
            second_display: `${getPercentage(total, created)}%`
          },
          {
            name: 'Aprobadas',
            first_display: approved,
            second_display: `${getPercentage(total, approved)}%`
          },
          {
            name: 'Validación corredora',
            first_display: bewellValidation,
            second_display: `${getPercentage(total, bewellValidation)}%`
          },
          {
            name: 'Validación RRHH',
            first_display: hrValidation,
            second_display: `${getPercentage(total, hrValidation)}%`
          }
        ];

        summaryData.value = obj;
      } catch (error) {
        notification('error', 'Error', error.response.data.message);
      }
    };

    const newCollectionFormRef = ref();
    const newCollectionFormData = reactive({
      date: ''
    });
    const newCollectionFormRules = {
      date: [
        {
          required: true,
          message: 'Seleccione periodo',
          trigger: 'change',
          type: 'object'
        }
      ]
    };
    const newCollectionFormOnSubmit = async () => {
      try {
        await newCollectionFormRef.value.validate();
        router.push({
          name: 'BillingFormCration',
          query: { fecha: newCollectionFormData.date.format('MM-YYYY') }
        });
      } catch (error) {
        notification('error', 'Error', error.response.data.message);
      }
    };

    const showNewCollectionModal = ref(false);
    const openNewCollectionModal = () => {
      showNewCollectionModal.value = true;
    };
    const newCollectionFormCancel = () => {
      newCollectionFormRef.value.resetFields();
    };

    const stockDownload = async () => {
      const route_query = route.query;
      delete route_query.page;
      let final_query = '';
      Object.keys(route_query).forEach((key, index) => {
        if (index === 0) {
          final_query = `${key}=${route_query[key]}`;
        } else {
          final_query += `&${key}=${route_query[key]}`;
        }
      });

      try {
        const download_link_type =
          tab.value === '#all'
            ? FETCH_COLLECTION_STOCK_DOWNLOAD
            : FETCH_COLLECTION_FORM_DOWNLOAD;
        const url = await store.dispatch(download_link_type, `?${final_query}`);
        const link = document.createElement('a');
        link.href = url;
        link.download = 'stock.xlsx';
        link.click();
        link.remove();
      } catch (error) {
        notification('error', 'Error', error.response.data.message);
      }
    };

    onMounted(() => {
      getSummary();
    });

    return {
      tab,
      summaryData,
      generalProgress,
      onChangeTabs,
      stockDownload,
      showNewCollectionModal,
      newCollectionFormRules,
      newCollectionFormData,
      newCollectionFormRef,
      newCollectionFormCancel,
      openNewCollectionModal,
      newCollectionFormOnSubmit,
      getCollectionGroupsSummary
    };
  }
};
</script>
